import queryKeys from "@/features/admin/hooks/queryKeys";
import { useTheme } from "@emotion/react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FormApi } from "@tanstack/react-form";
import { useQueryClient } from "@tanstack/react-query";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import { AzureIntegrationEntity } from "@ternary/api-lib/core/types/AzureIntegration";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { get, isEqual, reduce } from "lodash";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { UpdateAzureIntegrationParams } from "../../../../../api/core/types";
import paths from "../../../../../constants/paths";
import { useActivityTracker } from "../../../../../context/ActivityTrackerProvider";
import useAuthenticatedUser from "../../../../../hooks/useAuthenticatedUser";
import { useNavigateWithSearchParams } from "../../../../../lib/react-router";
import { VerticalStepperForm } from "../../../../../ui-lib/components/Stepper";
import { AlertType, postAlert } from "../../../../../utils/alerts";
import useCreateAzureIntegration from "../../../hooks/useCreateAzureIntegration";
import useUpdateAzureIntegration from "../../../hooks/useUpdateAzureIntegration";
import useValidateDataIntegration from "../../../hooks/useValidateDataIntegration";
import copyText from "../../copyText";
import { useGetAzureIntegrationByID } from "../../hooks/useGetAzureIntegrationByID";
import AzureIntegrationFormBillingExports from "./AzureIntegrationFormBillingExports";
import { AzureIntegrationFormGeneral } from "./AzureIntegrationFormGeneral";
import { defaultValues as _defaultValues } from "./constants";
import { FormData } from "./types";

export function AzureIntegrationFormContainer() {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const location = useLocation();
  const navigate = useNavigateWithSearchParams();
  const queryClient = useQueryClient();
  const theme = useTheme();

  const { integrationID } = useParams();

  //
  // State
  //

  const azureIntegrationLocation: AzureIntegrationEntity | undefined = get(
    location.state,
    "integration"
  );

  //
  // Queries
  //

  // If coming from a fresh session we need to fetch the integration from the server,
  // because it won't exist on location state.
  const { data: azureIntegrationServer, isLoading: isLoadingAzureIntegration } =
    useGetAzureIntegrationByID(integrationID ?? "", {
      enabled: !!integrationID && !azureIntegrationLocation,
    });

  //
  // Mutations
  //

  const {
    isPending: isCreatingAzureIntegration,
    mutate: createAzureIntegration,
  } = useCreateAzureIntegration({
    onError: () => {
      postAlert({
        message: copyText.errorCreatingAzureIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (integrationID) => {
      validateDataIntegration({ integrationID });

      postAlert({
        message: copyText.successCreatingAzureIntegrationMessage,
        type: AlertType.SUCCESS,
      });

      navigate(paths._admin, { searchParams: { tab: "clouds" } });
    },
  });

  const {
    isPending: isUpdatingAzureIntegration,
    mutate: updateAzureIntegration,
  } = useUpdateAzureIntegration({
    onError: () => {
      postAlert({
        message: copyText.errorUpdatingAzureIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (integrationID) => {
      validateDataIntegration({ integrationID });

      postAlert({
        message: copyText.successUpdatingAzureIntegrationMessage,
        type: AlertType.SUCCESS,
      });

      navigate(paths._admin, { searchParams: { tab: "clouds" } });
    },
  });

  const { mutate: validateDataIntegration } = useValidateDataIntegration({
    onError: () => {
      postAlert({
        message: copyText.errorValidatingDataIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSettled: () => {
      return queryClient.invalidateQueries({
        queryKey: [queryKeys.dataIntegrations, authenticatedUser.tenant.id],
      });
    },
  });

  //
  // Computed Values
  //

  // If use integration on location state first, if we have it.
  const azureIntegration = azureIntegrationLocation ?? azureIntegrationServer;

  //
  // Interaction Handlers
  //

  const defaultValues = azureIntegration
    ? getValuesFromIntegration(azureIntegration)
    : _defaultValues;

  async function handleSubmit({
    formApi,
    value,
  }: {
    value: FormData;
    formApi: FormApi<FormData>;
  }) {
    if (azureIntegration) {
      const defaultValues = formApi.options.defaultValues;
      if (!defaultValues) return;

      handleUpdateAzureIntegration(azureIntegration.id, value, defaultValues);
    } else {
      handleCreateAzureIntegration(authenticatedUser.tenant.id, value);
    }
  }

  function handleCreateAzureIntegration(tenantID: string, value: FormData) {
    activityTracker.captureAction(actions.CLICK_ADMIN_CREATE_INTEGRATION, {
      type: CloudProviderType.AZURE,
    });

    if (!value.type) {
      return;
    }
    createAzureIntegration({
      tenantID,
      appID: value.appID,
      billingExports: value.billingExports,
      directoryID: value.directoryID,
      name: value.name,
      type: value.type,
    });
  }

  function handleUpdateAzureIntegration(
    integrationID: string,
    values: FormData,
    defaultValues: FormData
  ) {
    const changes = getChanges(values, defaultValues);

    activityTracker.captureAction(actions.CLICK_ADMIN_UPDATE_INTEGRATION, {
      type: CloudProviderType.AZURE,
    });

    updateAzureIntegration({
      integrationID,
      ...changes,
    });
  }

  //
  // Render
  //

  const steps = [
    {
      component: (form) => (
        <AzureIntegrationFormGeneral
          form={form}
          showCertificate={!azureIntegration}
        />
      ),
      label: copyText.azureFormTabLabelGeneral,
      requiredInputs: ["name", "appID", "directoryID", "type"],
    },
    {
      component: (form) => <AzureIntegrationFormBillingExports form={form} />,
      label: copyText.azureFormTabLabelBillingExports,
      requiredInputs: ["billingExports"],
    },
    {
      component: () => (
        <Box width="50%">
          <Text appearance="h4">{copyText.createIntegrationHeader}</Text>
          <Text marginBottom={theme.space_lg}>
            {copyText.integrationLastInstructions}
          </Text>
        </Box>
      ),
      label: copyText.submitButtonLabel,
    },
  ];

  return (
    <Flex direction="column" height="80vh">
      <Button
        iconStart={<Icon icon={faArrowLeft} />}
        secondary
        width={200}
        onClick={() =>
          navigate(paths._admin, { searchParams: { tab: "clouds" } })
        }
      >
        {copyText.backToIntegrationsButtonLabel}
      </Button>
      <Text appearance="h3" marginVertical={theme.space_md}>
        {azureIntegration
          ? azureIntegration.name
          : copyText.azureFormNewAzureIntegrationHeader}
      </Text>
      <Flex height="100%" width="100%">
        {isLoadingAzureIntegration ? (
          <EmptyPlaceholder loading width="50%" />
        ) : (
          <VerticalStepperForm
            defaultValues={defaultValues}
            isProcessing={
              isCreatingAzureIntegration || isUpdatingAzureIntegration
            }
            steps={steps}
            onSubmit={handleSubmit}
          />
        )}
      </Flex>
    </Flex>
  );
}

function getValuesFromIntegration(
  integration: AzureIntegrationEntity
): FormData {
  return {
    // General
    name: integration.name ?? "",
    directoryID: integration.directoryID ?? "",
    appID: integration.appID ?? "",
    type: integration.type ?? _defaultValues.type,

    // Billing Exports
    billingExports: integration.billingExports || [],
    billingConfig: _defaultValues.billingConfig,
  };
}

function getChanges(values: FormData, defaultValues: FormData) {
  const changes: UpdateAzureIntegrationParams = reduce(
    defaultValues,
    (result, value, key) => {
      const currValue = get(values, key);
      if (key === "billingConfig") {
        return result;
      }

      if (!isEqual(value, currValue)) {
        result[key] = currValue;
      }

      return result;
    },
    {}
  );

  return changes;
}
