import { useTheme } from "@emotion/react";
import { Row } from "@tanstack/react-table";
import { ReactNode } from "react";
import Flex from "../../components/Flex";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import { faAngleRight, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import React from "react";

export function ExpandableCell({
  children,
  row,
}: {
  children: ReactNode;
  row: Row<unknown>;
}) {
  const theme = useTheme();

  const isFirstDepth = row.depth === 0;
  // Shift the content to the right to align non expandable rows
  const marginLeft = isFirstDepth && !row.getCanExpand() ? theme.space_lg : 0;

  return (
    <Flex
      alignItems="center"
      gap={theme.space_xs}
      marginLeft={marginLeft}
      paddingLeft={`${row.depth * 3}rem`}
    >
      {row.getCanExpand() ? (
        <Button
          iconStart={
            row.getIsExpanded() ? (
              <Icon icon={faAngleUp} />
            ) : (
              <Icon icon={faAngleRight} />
            )
          }
          onClick={row.getToggleExpandedHandler()}
          size="tiny"
        />
      ) : (
        <div />
      )}
      {children}
    </Flex>
  );
}
