export const FeatureFlag = {
  ALLOW_TLOS_SLOW_PATH: "ALLOW_TLOS_SLOW_PATH",
  BYPASS_TLOS_SLOW_PATH: "BYPASS_TLOS_SLOW_PATH",
  COST_COMPARE: "COST_COMPARE",
  CUSTOM_PRICING: "CUSTOM_PRICING",
  FOCUS_DATASETS: "FOCUS_DATASETS",
  FOCUS_TRE: "FOCUS_TRE",
  MSP_INVOICING: "MSP_INVOICING",
} as const;

export type FeatureFlag = (typeof FeatureFlag)[keyof typeof FeatureFlag];
