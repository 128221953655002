import { CostAlertEventType } from "../../constants/enums";

export const CostAlertStatus = {
  ACTIVE: "ACTIVE",
  INVESTIGATING: "INVESTIGATING",
  RESOLVED: "RESOLVED",
  UNRESOLVED: "UNRESOLVED",
} as const;

export type CostAlertStatus =
  (typeof CostAlertStatus)[keyof typeof CostAlertStatus];

export type CostAlertEntity = {
  id: string;
  tenantDocID: string;
  alertRuleID: string;
  createdAt: string;
  dimensions: { key: string; value: string | null }[];
  eventTime: string;
  eventType: CostAlertEventType;
  eventValue: number;
  expectedValue: {
    lowerBound: number;
    upperBound: number;
  };
  status: CostAlertStatus;
};
