import { useTheme } from "@emotion/react";
import { createColumnHelper } from "@tanstack/react-table";
import {
  CloudProviderType,
  ServiceType,
} from "@ternary/api-lib/constants/enums";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Link from "@ternary/api-lib/ui-lib/components/Link";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React, { useMemo } from "react";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import { getInsightsUrl } from "../../committed-use/gcp/utils";
import copyText from "../copyText";
import { MeasureCell } from "@ternary/api-lib/ui-lib/charts/Table/MeasureCell";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import { groupBy, sumBy } from "lodash";
import { getSortedUniqueString, isServiceType } from "../utils";
import { ExpandableCell } from "@ternary/api-lib/ui-lib/charts/Table/ExpandableCell";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { AVG_PIXEL_WIDTH_OF_CHARACTER } from "@ternary/api-lib/ui-lib/charts/constants";

type RecommendationData = {
  id: string;
  estimateCurrency: string;
  estimateValue: number;
  number: string;
  tenantDocID: string;
  tenantName: string;
};

export type Recommendation = RecommendationData & {
  cloudProviderType: CloudProviderType;
  serviceType: ServiceType;
};

export type AggregatedRecommendation = RecommendationData & {
  cloudProviderType: string[];
  serviceType: string[];
};

type RowData = (Recommendation | AggregatedRecommendation) & {
  hasTenantAccess: boolean;
};
type TableData = RowData & {
  subRows?: RowData[];
};

const columnHelper = createColumnHelper<TableData>();

interface Props {
  isLoading: boolean;
  recommendations: Recommendation[];
  onInteraction: (interaction: MspUsageRecommendationTable.Interaction) => void;
}

export function MspUsageRecommendationTable(props: Props) {
  const authenticatedUser = useAuthenticatedUser();
  const theme = useTheme();

  const columns = useMemo(
    () => [
      columnHelper.accessor("tenantName", {
        cell: (cell) => {
          const tenantDocID = cell.row.original.tenantDocID;
          const number = cell.row.original.number;
          const hasAccess = cell.row.original.hasTenantAccess;

          const serviceType = cell.row.original.serviceType;

          const pathname = isServiceType(serviceType)
            ? getInsightsUrl(serviceType)
            : "";

          return (
            <ExpandableCell row={cell.row}>
              {hasAccess ? (
                <Link
                  target="_blank"
                  to={{
                    pathname,
                    search: `?tenant_id=${tenantDocID}&tab=optimizations&id=${number}`,
                  }}
                >
                  {cell.getValue()}
                </Link>
              ) : (
                <Text
                  appearance="link"
                  color={theme.link_color}
                  onClick={() =>
                    props.onInteraction({
                      type: MspUsageRecommendationTable.INTERACTION_LINK_CLICKED,
                      tenantDocID,
                    })
                  }
                >
                  {cell.getValue()}
                </Text>
              )}
            </ExpandableCell>
          );
        },
        header: copyText.tableHeaderTenantName,
        size: 250,
      }),
      columnHelper.accessor("cloudProviderType", {
        cell: (cell) => {
          const value = cell.getValue();
          if (Array.isArray(value)) {
            return (
              <Text truncate>
                {value
                  .map((v) => copyText[`providerTypeLabel_${v}`])
                  .join(", ")}
              </Text>
            );
          }

          return copyText[`providerTypeLabel_${value}`];
        },
        header: copyText.tableHeaderProviderType,
        size: 100,
      }),
      columnHelper.accessor("serviceType", {
        cell: (cell) => {
          const value = cell.getValue();
          if (Array.isArray(value)) {
            const content = value
              .map((v) => copyText[`serviceTypeLabel_${v}`])
              .join(", ");

            // Show the tooltip when content is truncated
            const maxCharacters = 120 / AVG_PIXEL_WIDTH_OF_CHARACTER;
            if (content.length > maxCharacters) {
              return (
                <Tooltip content={content} width="225px">
                  <Text truncate style={{ maxWidth: "120px" }}>
                    {content}
                  </Text>
                </Tooltip>
              );
            }

            return <Text truncate>{content}</Text>;
          }

          return copyText[`serviceTypeLabel_${value}`];
        },
        header: copyText.tableHeaderServiceType,
        size: 120,
      }),
      columnHelper.accessor("estimateValue", {
        cell: ({ column, getValue, row }) => {
          const value = getValue();

          return (
            <MeasureCell
              applyMaxCharacters
              columnID={column?.id}
              currencyCode={row.original.estimateCurrency}
              unit={UnitType.CURRENCY}
              value={value}
            />
          );
        },
        header: copyText.tableHeaderPotentialSavingsMonthly,
        meta: { align: "right" },
        size: 120,
      }),
    ],
    [props.recommendations]
  );

  const data = useMemo(() => {
    return props.recommendations.map((rec) => {
      const grant = authenticatedUser.grants.find(
        (grant) => grant.tenantDocID === rec.tenantDocID
      );

      return { ...rec, hasTenantAccess: !!grant, subRows: [] };
    });
  }, [props.recommendations]);

  const aggregatedByTenant: TableData[] = useMemo(() => {
    const groupedByTenant = groupBy(data, "tenantDocID");
    return Object.keys(groupedByTenant).map((tenantDocID) => {
      const recommendations = groupedByTenant[tenantDocID];
      if (recommendations.length === 1) {
        return recommendations[0];
      }

      const estimateValue = sumBy(recommendations, "estimateValue");
      const cloudProviderType = getSortedUniqueString(
        recommendations,
        "cloudProviderType"
      );

      const serviceType = getSortedUniqueString(recommendations, "serviceType");
      const rowData: TableData = {
        id: recommendations[0].id,
        cloudProviderType: cloudProviderType,
        estimateCurrency: recommendations[0].estimateCurrency,
        estimateValue,
        hasTenantAccess: recommendations[0].hasTenantAccess,
        number: recommendations[0].number,
        serviceType: serviceType,
        subRows: recommendations,
        tenantDocID,
        tenantName: recommendations[0].tenantName,
      };

      return rowData;
    });
  }, [data]);

  return (
    <Table
      columns={columns}
      compact
      data={aggregatedByTenant}
      getSubRows={(row) => row?.subRows ?? []}
      expandable
      initialState={{ sorting: [{ id: "estimateValue", desc: true }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

MspUsageRecommendationTable.INTERACTION_LINK_CLICKED =
  "MspChildUsageRecommendationTable.INTERACTION_LINK_CLICKED" as const;

interface InteractionLinkClicked {
  type: typeof MspUsageRecommendationTable.INTERACTION_LINK_CLICKED;
  tenantDocID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MspUsageRecommendationTable {
  export type Interaction = InteractionLinkClicked;
}

export default MspUsageRecommendationTable;
