import { useTheme } from "@emotion/react";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { isEqual } from "lodash";
import React, { useMemo } from "react";
import copyText from "../copyText";
import { MspSharedIntegrations } from "../types";
import {
  CREATE_INTEGRATIONS,
  UPDATE_INTEGRATIONS,
} from "./MspChildTenantIntegrationManagementContainer";
import MspSharedIntegrationsTable from "./MspSharedIntegrationsTable";
import { SelectedSubAccounts } from "./MspSubaccountsFormContainer";

interface IProps {
  action: string;
  initialSubaccounts: SelectedSubAccounts;
  isProcessing: boolean;
  selectedSubAccounts: SelectedSubAccounts;
  sharedIntegrations: MspSharedIntegrations[];
  onNextStep: (value: string) => void;
  onInteraction: (
    interaction:
      | MspReviewSubaccountsSection.Interaction
      | MspSharedIntegrationsTable.Interaction
  ) => void;
}

function MspReviewSubaccountsSection(props: IProps): JSX.Element {
  const theme = useTheme();

  //
  // State
  //

  const filteredSharedIntegrations = useMemo(() => {
    return getFilteredSharedIntegrations({
      action: props.action,
      initialSubaccounts: props.initialSubaccounts,
      sharedIntegrations: props.sharedIntegrations ?? [],
      selectedSubAccounts: props.selectedSubAccounts,
    });
  }, [props.selectedSubAccounts, props.sharedIntegrations]);

  //
  // Render
  //

  const mspSharedIntegrationControls = () => {
    return (
      <Flex direction="column">
        <Flex
          alignItems="center"
          marginBottom={theme.space_md}
          justifyContent="flex-end"
          flex={1}
        >
          <Box marginLeft={theme.space_md}>
            <Button
              primary
              onClick={() =>
                props.onInteraction({
                  type:
                    props.action === CREATE_INTEGRATIONS
                      ? MspReviewSubaccountsSection.INTERACTION_CREATE_SUBMIT_CLICKED
                      : MspReviewSubaccountsSection.INTERACTION_UPDATE_SUBMIT_CLICKED,
                })
              }
            >
              {copyText.submitSubaccountButtonLabel}
            </Button>
          </Box>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex direction="column">
      <Flex marginBottom={theme.space_sm} alignItems="center">
        <Text>
          {props.action === UPDATE_INTEGRATIONS
            ? copyText.mspChildReviewUpdateSubaccountCaption
            : copyText.mspChildReviewAddSubaccountCaption}
        </Text>
      </Flex>
      {mspSharedIntegrationControls()}
      <MspSharedIntegrationsTable
        showSwitch
        isExpanded
        isProcessing={props.isProcessing}
        sharedIntegrations={filteredSharedIntegrations ?? []}
        onInteraction={props.onInteraction}
      />
    </Flex>
  );
}

type GetFilteredSharedIntegrationsParams = {
  action: string;
  sharedIntegrations: MspSharedIntegrations[];
  selectedSubAccounts: SelectedSubAccounts;
  initialSubaccounts: SelectedSubAccounts;
};

function getFilteredSharedIntegrations(
  params: GetFilteredSharedIntegrationsParams
): MspSharedIntegrations[] {
  return params.sharedIntegrations
    .filter((sharedIntegrations) => {
      const selectedChildSubaccounts =
        params.selectedSubAccounts[sharedIntegrations.id]?.subaccounts;

      if (params.action === UPDATE_INTEGRATIONS) {
        const initialSubaccount =
          params.initialSubaccounts[sharedIntegrations.id].subaccounts;
        return !isEqual(initialSubaccount, selectedChildSubaccounts);
      }
      if (!selectedChildSubaccounts) {
        return false;
      }

      return true;
    })
    .map((filteredIntegrations) => {
      return {
        ...filteredIntegrations,
        subAccounts: filteredIntegrations.subAccounts.filter((account) => {
          return account.isSelected;
        }),
      };
    });
}

MspReviewSubaccountsSection.INTERACTION_CREATE_SUBMIT_CLICKED =
  "MspChildCostAlertTable.INTERACTION_CREATE_SUBMIT_CLICKED" as const;

MspReviewSubaccountsSection.INTERACTION_UPDATE_SUBMIT_CLICKED =
  "MspChildCostAlertTable.INTERACTION_UPDATE_SUBMIT_CLICKED" as const;

interface InteractionSubmitClicked {
  type: typeof MspReviewSubaccountsSection.INTERACTION_CREATE_SUBMIT_CLICKED;
}
interface InteractionUpdateSubmitClicked {
  type: typeof MspReviewSubaccountsSection.INTERACTION_UPDATE_SUBMIT_CLICKED;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace MspReviewSubaccountsSection {
  export type Interaction =
    | InteractionSubmitClicked
    | InteractionUpdateSubmitClicked;
}

export default MspReviewSubaccountsSection;
