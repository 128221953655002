import { add, endOfMonth, format, startOfMonth, sub } from "date-fns";
import { intersection, keyBy, uniq } from "lodash";
import UError from "unilib-error";
import {
  ChartType,
  DataSource,
  DurationType,
  MetricAggregate,
  Operator,
  TimeGranularity,
} from "../constants/enums";
import { DEFAULT_X_AXIS_KEY } from "../ui-lib/charts/utils";
import copyText from "../ui-lib/copyText";
import {
  mergeRawDataIntoOther,
  MergeType,
} from "../ui-lib/utils/ChartDataManager";
import {
  DataSourceFilter,
  executeFormula,
  getComparisonData,
  getComparisonDateRangeFromReport,
  getCumulativeData,
  getDataSourceFilters,
  getDateRangeFromReport,
  getMeasureFromMetricAggregate,
  removeOtherData,
} from "../utils/ReportUtils";
import DataQuery, {
  DatalligatorOrder,
  ISO_TIMESTAMP_FORMAT,
} from "./DataQuery";
import {
  createFiscalDates,
  fiscalGranularities,
  FiscalPeriodMap,
  getCurrentFiscalPeriod,
  getDateRangeFromFiscalPeriod,
  getFiltersForFiscalPeriod,
} from "./fiscalDateUtils";
import { AnalyticsApiClient } from "./getTopNRawData";
import {
  OrderDirection,
  QueryFilter,
  RawData,
  ReportDataConfig,
} from "./types";

const DEFAULT_LIMIT = 50_000;
const QUERY_GROUPING_COUNT = 50;
const DEFAULT_CHART_GROUPING_COUNT = 30;
const OTHER_NOT_SHOWN = "Other (not shown)";

type IntegrationMetricMap = { [key: string]: { name: string } };

export type Dependencies = {
  client: Omit<AnalyticsApiClient, "load">;
  fiscalPeriodMap: { [key: string]: string };
  globalFilters: DataSourceFilter[];
  integrationMetricMap?: IntegrationMetricMap;
  isFiscalMode: boolean;
  tenantID: string;
  topN?: {
    main: GetReportDataResult;
    dailyTotals: GetReportDataResult;
    groupingTotals: GetReportDataResult;
  };
};

type MaybePromise<T> = T | Promise<T>;

export type DataResult<TData = RawData> = {
  data: TData[];
  isLargeDataSet: boolean;
};

type CancelQueryMetaData = {
  type: "cancelQuery";
};

type ComparisonMetaData = {
  type: "comparison";
  isActive: boolean;
  durationType: DurationType;
};

type ExternalMetricsStageMetaData = {
  type: "externalMetrics";
  selectedMetricName: string | null;
  isActive: boolean; // true if any query from this report is external
  isExternalQuery: boolean; // true if THIS query is external
  measures: string[];
};

type FiscalDatesStageMetaData = {
  type: "fiscalDates";
  areFiltersActive: boolean;
  areGranularitiesActive: boolean;
};

type GranularityStageMetaData = {
  type: "granularity";
  value: null | TimeGranularity;
};

type GranularityOrderStageMetaData = {
  type: "granularityOrder";
  granularityType: null | "timestamp" | "invoiceMonth" | "fiscal";
};

type HasMoreMetaData = {
  type: "hasMore";
  hasMore: boolean;
};

type InvoiceMonthStageMetaData = {
  type: "invoiceMonth";
  areFiltersActive: boolean;
  areGranularitiesActive: boolean;
};

type TotalsMetaData = {
  type: "totals";
  mode: null | "dailyTotals" | "groupingTotals";
};

type StageMetaData =
  | ComparisonMetaData
  | CancelQueryMetaData
  | ExternalMetricsStageMetaData
  | FiscalDatesStageMetaData
  | GranularityStageMetaData
  | GranularityOrderStageMetaData
  | HasMoreMetaData
  | InvoiceMonthStageMetaData
  | TotalsMetaData;

type PreQueryStageParams = {
  metaData: StageMetaData[];
  query: DataQuery;
  report: ReportDataConfig;
};

type PostQueryStageParams = {
  metaData: StageMetaData[];
  query: DataQuery;
  report: ReportDataConfig;
  result: DataResult;
};

export type GetReportDataResult = PostQueryStageParams;

const ReportConfigurationError = {
  INVALID_EXTERNAL_GRANULARITIES: "INVALID_EXTERNAL_GRANULARITIES",
  INVALID_INVOICE_DATE_RANGE: "INVALID_INVOICE_DATE_RANGE",
  MISSING_METADATA_DEPENDENCY: "MISSING_METADATA_DEPENDENCY",
} as const;
type ReportConfigurationError =
  (typeof ReportConfigurationError)[keyof typeof ReportConfigurationError];

// Change the fields on the query & add metadata
type PreQueryStage = (
  params: Readonly<PreQueryStageParams>
) => MaybePromise<void>;

// Make the query
type QueryStage = (
  params: Readonly<PreQueryStageParams>
) => MaybePromise<PostQueryStageParams["result"]>;

// Change the report result
type PostQueryStage = (
  params: Readonly<PostQueryStageParams>
) => MaybePromise<void>;

// Executes stages in order
async function executeQueryStages(params: {
  report: ReportDataConfig;
  init?: PreQueryStageParams;
  preQueryStages: PreQueryStage[];
  queryStage: QueryStage;
  postQueryStages: PostQueryStage[];
}): Promise<PostQueryStageParams> {
  const preQueryStageParams: PreQueryStageParams = params.init ?? {
    metaData: [],
    query: new DataQuery({
      dataSource: params.report.dataSource,
      dateRange: [new Date(), new Date()],
    }),
    report: structuredClone(params.report),
  };

  for (const preQueryStage of params.preQueryStages) {
    await preQueryStage(preQueryStageParams);
  }

  const queryStageResult = await params.queryStage(preQueryStageParams);

  const postQueryStageParams = {
    ...preQueryStageParams,
    result: queryStageResult,
  };

  for (const postQueryStage of params.postQueryStages) {
    await postQueryStage(postQueryStageParams);
  }

  return postQueryStageParams;
}

type StageMetaDataFor<StageType extends StageMetaData["type"]> = Simplify<
  StageMetaData & { type: StageType }
>;
type Simplify<T> = T extends () => unknown ? T : { [Key in keyof T]: T[Key] };

// Gets metaData by type. Throws error if not found
function assertMetaData<StageType extends StageMetaData["type"]>(
  metaData: StageMetaData[],
  type: StageType
): StageMetaDataFor<StageType> {
  const found = metaData.find((data) => data.type === type);

  if (!found) {
    throw new UError(
      `assertMetaData/${ReportConfigurationError.MISSING_METADATA_DEPENDENCY}`,
      { metaData }
    );
  }

  return found as StageMetaDataFor<StageType>;
}

// Gets metaData by type. Returns null if not found
function findMetaData<StageType extends StageMetaData["type"]>(
  metaData: StageMetaData[],
  type: StageType
): StageMetaDataFor<StageType> | null {
  const found = metaData.find((data) => data.type === type) ?? null;

  return found as StageMetaDataFor<StageType> | null;
}

//
//
// Pre Query Stages
//
//

//
// Basic Params Stage
//
const basicParams: PreQueryStage = ({ report, query }) => {
  const dateRange = getDateRangeFromReport(report);
  query.dimensions = [...report.dimensions];
  query.limit = DEFAULT_LIMIT;
  query.measures = [...report.measures];
  query.setDataSource(report.dataSource);
  query.setDateRange(dateRange);
};

//
// Comparison Stage
//
// Changes the report to match the "Compared" date.
// Cancels the query if no compareDateRange
const comparison: PreQueryStage = ({ metaData, report }) => {
  const compareDateRange = getComparisonDateRangeFromReport(report);

  const comparisonMetaData: ComparisonMetaData = {
    type: "comparison",
    isActive: false,
    durationType: report.durationType,
  };

  if (compareDateRange.length > 0) {
    report.durationType = DurationType.CUSTOM;
    report.startDate = format(compareDateRange[0], ISO_TIMESTAMP_FORMAT);
    report.endDate = format(compareDateRange[1], ISO_TIMESTAMP_FORMAT);
    comparisonMetaData.isActive = true;
    metaData.push(comparisonMetaData);
  } else {
    metaData.push(comparisonMetaData, { type: "cancelQuery" });
  }
};

//
// Add Credit Types
//
// Adds credit type measures to use in "updateCostsWithExcludedCreditTypes"
const addExcludedCreditTypes: PreQueryStage = ({ query, report }) => {
  if (report.dataSource !== DataSource.BILLING) {
    return;
  }

  query.measures ??= [];

  const hasEditableCosts = query.measures.find(
    (measure) => measure === "netCost" || measure === "absoluteCredits"
  );

  if (hasEditableCosts && report.excludedCreditTypes.length > 0) {
    query.measures.push(...report.excludedCreditTypes);
  }
};

//
// Exclude Negatives
//
const excludeNegatives: PreQueryStage = ({ metaData, query, report }) => {
  const externalMetricsMetaData = findMetaData(metaData, "externalMetrics");

  if (!report.excludeNegativeNumbers || externalMetricsMetaData?.isActive) {
    return;
  }

  query.pre_agg_filters ??= [];
  query.pre_agg_filters.push(
    ...report.measures
      .map((measure) => ({
        name: measure,
        operator: Operator.GTE,
        values: ["0"],
      }))
      .map(DataQuery.transformFilter)
  );
};

//
// External Metric Stage
//

//
// Adds meta data only. Used by Non-external queries
const addExternalMetricsMetaData: PreQueryStage = ({ report, metaData }) => {
  if (!report.metric || report.dimensions.length > 0) {
    metaData.push({
      type: "externalMetrics",
      isActive: false,
      isExternalQuery: false,
      measures: [],
      selectedMetricName: null,
    });
    return;
  }

  metaData.push({
    type: "externalMetrics",
    isActive: true,
    isExternalQuery: false,
    measures: [],
    selectedMetricName: null,
  });
};

//
// Changes data source and adds relevant filters & measures
const createExternalMetricsStage: (integrationMetricMap?: {
  [key: string]: { name: string };
}) => PreQueryStage =
  (integrationMetricMap) =>
  ({ report, query, metaData }) => {
    const externalMetricMetadata = findMetaData(
      metaData,
      "externalMetrics"
    ) ?? {
      type: "externalMetrics",
      isActive: false,
      isExternalQuery: false,
      measures: [],
      selectedMetricName: null,
    };

    if (
      !integrationMetricMap ||
      !externalMetricMetadata.isActive ||
      !report.metric
    ) {
      metaData.push({ type: "cancelQuery" });
      return;
    }

    query.setDataSource(DataSource.EXTERNAL_METRICS);
    report.dataSource = DataSource.EXTERNAL_METRICS;
    query.measures = [
      getMeasureFromMetricAggregate(
        report.metricAggregate ?? MetricAggregate.SUM
      ),
    ];

    const qualifiedMetricFilters = report.metricFilters
      .filter(
        (metricFilter) =>
          !metricFilter.values ||
          (metricFilter.values && metricFilter.values.length > 0)
      )
      .map(DataQuery.transformFilter);

    query.pre_agg_filters = [
      ...qualifiedMetricFilters,
      {
        schema_field_name: "metric",
        operator: "equals",
        values: [report.metric],
      },
    ];

    const selectedMetricName = report.metric
      ? integrationMetricMap[report.metric]?.name
      : null;

    externalMetricMetadata.isExternalQuery = true;
    externalMetricMetadata.measures = [...query.measures];
    externalMetricMetadata.selectedMetricName = selectedMetricName;
  };

//
// Filters Stage
//
const filters: PreQueryStage = ({ metaData, report, query }) => {
  const measureSet = new Set(report.measures);
  const externalMetricsMetaData = findMetaData(metaData, "externalMetrics");

  if (externalMetricsMetaData?.isExternalQuery) {
    // only remove report-filters from the external query
    return;
  }

  const preAggFilters = report.filters
    .filter((queryFilter) => !measureSet.has(queryFilter.name))
    .map(DataQuery.transformFilter);
  const postAggFilters = report.filters
    .filter((queryFilter) => measureSet.has(queryFilter.name))
    .map(DataQuery.transformFilter);

  query.pre_agg_filters ??= [];
  query.post_agg_filters ??= [];

  query.pre_agg_filters.push(...preAggFilters);
  query.post_agg_filters.push(...postAggFilters);
};

//
// Fiscal Dates Stage
//
// Adds fiscal filters & granularities (dimensions)
const createFiscalDatesStage: (
  fiscalPeriodMap?: FiscalPeriodMap | null
) => PreQueryStage =
  (fiscalPeriodMap) =>
  ({ report, query, metaData }) => {
    const comparisonMetaData = findMetaData(metaData, "comparison");
    const externalMetricsMetaData = findMetaData(metaData, "externalMetrics");

    const fiscalDatesMetaData: FiscalDatesStageMetaData = {
      type: "fiscalDates",
      areFiltersActive: false,
      areGranularitiesActive: false,
    };

    const durationType =
      comparisonMetaData?.durationType ?? report.durationType;

    if (
      !fiscalPeriodMap ||
      durationType === DurationType.CUSTOM ||
      report.dataSource !== DataSource.BILLING ||
      externalMetricsMetaData?.isActive
    ) {
      metaData.push(fiscalDatesMetaData);

      return;
    }

    fiscalDatesMetaData.areFiltersActive = true;

    const currentPeriod = getCurrentFiscalPeriod(
      durationType,
      fiscalPeriodMap,
      !!comparisonMetaData?.isActive
    );

    const additionalFilters = getFiltersForFiscalPeriod(
      currentPeriod,
      fiscalPeriodMap,
      durationType
    );

    query.pre_agg_filters ??= [];
    query.pre_agg_filters.push(
      ...additionalFilters.map(DataQuery.transformFilter)
    );

    const dateRange = getDateRangeFromFiscalPeriod(
      currentPeriod,
      durationType,
      fiscalPeriodMap,
      !!comparisonMetaData?.isActive
    );

    if (dateRange) query.setDateRange(dateRange);

    const granularityMetaData = assertMetaData(metaData, "granularity");
    const invoiceMonthMetaData = findMetaData(metaData, "invoiceMonth");

    if (
      !granularityMetaData.value ||
      //
      // Note: invoiceMonth granularity has higher precedence than fiscal month granularity.
      // If invoiceMonth granularity is active, fiscal granularity should be ignored
      invoiceMonthMetaData?.areGranularitiesActive
    ) {
      metaData.push(fiscalDatesMetaData);

      return;
    }

    query.dimensions ??= [];

    // Only Some Granularities are Fiscal Dimensions
    if (fiscalGranularities.includes(granularityMetaData.value)) {
      switch (granularityMetaData.value) {
        case TimeGranularity.MONTH:
          fiscalDatesMetaData.areGranularitiesActive = true;
          query.dimensions.push("fiscalMonth");
          query.dimensions.push("fiscalYear");
          break;
        case TimeGranularity.QUARTER:
          fiscalDatesMetaData.areGranularitiesActive = true;
          query.dimensions.push("fiscalQuarter");
          query.dimensions.push("fiscalYear");
          break;
        case TimeGranularity.WEEK:
          fiscalDatesMetaData.areGranularitiesActive = true;
          query.dimensions.push("fiscalWeek");
          query.dimensions.push("fiscalYear");
          break;
        case TimeGranularity.YEAR:
          fiscalDatesMetaData.areGranularitiesActive = true;
          query.dimensions.push("fiscalYear");
          break;
      }

      query.precision = undefined;
    }

    query.dimensions = uniq(query.dimensions);

    metaData.push(fiscalDatesMetaData);
  };

//
// Global Filters
//
type CreateGlobalFiltersStage = (
  globalFilters: DataSourceFilter[]
) => PreQueryStage;
const createGlobalFiltersStage: CreateGlobalFiltersStage =
  (globalFilters) =>
  ({ report, query }) => {
    const dataSourceFilters = getDataSourceFilters(
      globalFilters,
      report.dataSource
    );

    query.pre_agg_filters ??= [];
    query.pre_agg_filters.push(
      ...dataSourceFilters.map(DataQuery.transformFilter)
    );
  };

//
// Granularity Stage
//
const granularity: PreQueryStage = ({ report, query, metaData }) => {
  if (
    report.chartType === ChartType.KPI ||
    report.chartType === ChartType.PIE ||
    report.chartType === ChartType.TABLE ||
    report.xAxisKey !== DEFAULT_X_AXIS_KEY ||
    !report.timeGranularity
  ) {
    metaData.push({ type: "granularity", value: null });

    return;
  }

  metaData.push({ type: "granularity", value: report.timeGranularity });

  query.precision = report.timeGranularity;
};

//
// Granularity Order Stage
//
// adds "order_by"s on the correct dimensions to make the result sorted by "date"
// "date" could be timestamp, invoiceMonth, or a combo of fiscal granularities
const granularityOrder: PreQueryStage = ({ report, query, metaData }) => {
  const granularityOrderMetaData: GranularityOrderStageMetaData = {
    type: "granularityOrder",
    granularityType: null,
  };

  const granularityMetaData = assertMetaData(metaData, "granularity");
  if (!granularityMetaData.value) {
    // no granularities active
    metaData.push(granularityOrderMetaData);
    return;
  }

  const direction = report.reverse ? OrderDirection.desc : OrderDirection.asc;

  const fiscalDatesMetaData = findMetaData(metaData, "fiscalDates");
  const invoiceMonthMetaData = findMetaData(metaData, "invoiceMonth");

  const granularityOrders: DatalligatorOrder[] = [];

  if (invoiceMonthMetaData?.areGranularitiesActive) {
    granularityOrderMetaData.granularityType = "invoiceMonth";
    granularityOrders.push({ direction, field_name: "invoiceMonth" });
  } else if (fiscalDatesMetaData?.areGranularitiesActive) {
    const fiscalOrders: DatalligatorOrder[] = [
      { direction, field_name: "fiscalYear" },
      { direction, field_name: "fiscalQuarter" },
      { direction, field_name: "fiscalMonth" },
      { direction, field_name: "fiscalWeek" },
    ].filter((order) => query.dimensions?.includes(order.field_name));

    granularityOrderMetaData.granularityType = "fiscal";
    granularityOrders.push(...fiscalOrders);
  } else if (granularityMetaData.value) {
    granularityOrderMetaData.granularityType = "timestamp";
    granularityOrders.push({ direction, field_name: "timestamp" });
  }

  metaData.push(granularityOrderMetaData);
  query.order_by ??= [];
  query.order_by = [...granularityOrders, ...query.order_by];
};

//
// Invoice Month Stage
//
// Adds filters for "invoiceMonth" dimension. Adds padding around date range
// to account for invoice and calendar month misalignment
const invoiceMonth: PreQueryStage = ({ report, query, metaData }) => {
  const getDateFromInvoiceString = (string: string) => {
    const [month, year] = string.split("/");
    const dateString = [year, month, "1"].join("-");

    return new Date(dateString);
  };

  const getInvoiceMonthFilterValue = (string: string) => {
    const [month, year] = string.split("/");
    return year + month;
  };

  const externalMetricsMetaData = findMetaData(metaData, "externalMetrics");
  const { invoiceMonthStart, invoiceMonthEnd } = report;

  const shouldApplyInvoiceMonthFilters =
    !externalMetricsMetaData?.isActive &&
    report.durationType === DurationType.INVOICE &&
    report.dataSource === DataSource.BILLING;

  if (!shouldApplyInvoiceMonthFilters) {
    metaData.push({
      type: "invoiceMonth",
      areFiltersActive: false,
      areGranularitiesActive: false,
    });

    return;
  }

  if (!invoiceMonthStart || !invoiceMonthEnd) {
    throw new UError(
      `invoiceMonth.pre/${ReportConfigurationError.INVALID_INVOICE_DATE_RANGE}`,
      { report }
    );
  }

  query.pre_agg_filters ??= [];
  query.pre_agg_filters.push(
    {
      schema_field_name: "invoiceMonth",
      operator: "gte",
      values: [getInvoiceMonthFilterValue(invoiceMonthStart)],
    },
    {
      schema_field_name: "invoiceMonth",
      operator: "lte",
      values: [getInvoiceMonthFilterValue(invoiceMonthEnd)],
    }
  );

  const startDate = startOfMonth(getDateFromInvoiceString(invoiceMonthStart));
  const endDate = endOfMonth(getDateFromInvoiceString(invoiceMonthEnd));

  query.setDateRange([
    sub(startDate, { days: 10 }),
    add(endDate, { days: 10 }),
  ]);

  const granularityMetaData = assertMetaData(metaData, "granularity");
  if (granularityMetaData.value !== TimeGranularity.MONTH) {
    metaData.push({
      type: "invoiceMonth",
      areFiltersActive: true,
      areGranularitiesActive: false,
    });

    return;
  }

  query.dimensions ??= [];
  query.dimensions.push("invoiceMonth");
  query.precision = undefined;

  metaData.push({
    type: "invoiceMonth",
    areFiltersActive: true,
    areGranularitiesActive: true,
  });
};

//
// Measure Order Stage
//
// Data should be ordered by measure1-desc, then measure2-desc, ...
const measureOrder: PreQueryStage = ({ report, query, metaData }) => {
  let measures = report.measures;

  const externalMetricsMetaData = findMetaData(metaData, "externalMetrics");
  if (externalMetricsMetaData?.isActive) {
    measures = externalMetricsMetaData.measures;
  }

  const measureOrders = measures.map<DatalligatorOrder>((measure) => ({
    direction: OrderDirection.desc,
    field_name: measure,
  }));

  query.order_by ??= [];
  query.order_by.push(...measureOrders);
};

//
// Report Order Stage
//
// Apply an order that is on the report
const reportOrder: PreQueryStage = ({ report, query }) => {
  if (
    !report.sortRule ||
    !(
      report.measures.includes(report.sortRule.id) ||
      report.dimensions.includes(report.sortRule.id)
    ) ||
    report.chartType !== ChartType.TABLE
  ) {
    return;
  }

  query.order_by ??= [];
  query.order_by.push({
    direction: report.sortRule.desc ? OrderDirection.desc : OrderDirection.asc,
    field_name: report.sortRule.id,
  });
};

//
// Top N
//

type TopNParams = {
  dailyTotals: GetReportDataResult;
  groupingTotals: GetReportDataResult;
  main: GetReportDataResult;
};

//
// Adds filter to get data from only the top ${QUERY_GROUPING_COUNT} groupings
const createAddTopNFilters: (
  params: Pick<TopNParams, "groupingTotals" | "main">
) => PreQueryStage =
  (params) =>
  ({ metaData, query }) => {
    if (!assertMetaData(params.main.metaData, "hasMore").hasMore) {
      // Don't execute query since all data is contained in main
      metaData.push({ type: "cancelQuery" });
      return;
    }

    query.pre_agg_filters ??= [];
    query.pre_agg_filters.push(
      ...getTopNFilters(params).map(DataQuery.transformFilter)
    );
  };

//
// Generate that filter
function getTopNFilters({
  groupingTotals,
}: Pick<TopNParams, "groupingTotals">): QueryFilter[] {
  const dimensionFilter = (datum: RawData, dimension: string): QueryFilter => {
    const value = datum[dimension];

    if (
      typeof value === "string" ||
      typeof value === "number" ||
      typeof value === "boolean"
    ) {
      return {
        name: dimension,
        operator: Operator.EQUALS,
        values: [String(value)],
      };
    }

    return {
      name: dimension,
      operator: Operator.NOT_SET,
    };
  };

  return [
    {
      // or: can match any of the datum
      or: groupingTotals.result.data
        .slice(0, QUERY_GROUPING_COUNT)
        .map<QueryFilter>((datum) => ({
          // and: must match every dimension value in the datum
          and: groupingTotals.report.dimensions.map((dimension) =>
            dimensionFilter(datum, dimension)
          ),
        })),
    },
  ];
}

const removeInvalidFilters: PreQueryStage = ({ query }) => {
  query.pre_agg_filters ??= [];
  query.pre_agg_filters = DataQuery.removeInvalidFilters(query.pre_agg_filters);

  query.post_agg_filters ??= [];
  query.post_agg_filters = DataQuery.removeInvalidFilters(
    query.post_agg_filters
  );
};

//
// Totals Stages
//
// one datum per "day" (day could be any granularity)
const dailyTotals: PreQueryStage = ({ metaData, report }) => {
  report.dimensions = [];
  metaData.push({ type: "totals", mode: "dailyTotals" });
};

//
// one datum per "grouping" (all days merged together)
const groupingTotals: PreQueryStage = ({ metaData, report }) => {
  report.timeGranularity = null;
  metaData.push({ type: "totals", mode: "groupingTotals" });
};

//
// Adds metaData to be used elsewhere
const noTotals: PreQueryStage = ({ metaData }) => {
  metaData.push({ type: "totals", mode: null });
};

//
//
// Query Stage
//
//

const createQueryStage: (dependencies: {
  client: Omit<AnalyticsApiClient, "load">;
  tenantID: string;
}) => QueryStage =
  (dependencies) =>
  async ({ metaData, query }) => {
    const cancelQueryMetaData = findMetaData(metaData, "cancelQuery");

    if (cancelQueryMetaData || !query.measures || query.measures.length === 0) {
      return {
        data: [],
        isLargeDataSet: false,
        query,
      };
    }

    const result = await dependencies.client.loadData(
      dependencies.tenantID,
      query
    );

    const isLargeDataSet = result.response.length >= DEFAULT_LIMIT;

    return {
      data: result.response,
      isLargeDataSet,
      query,
    };
  };

//
//
// Post Query Stages
//
//

//
// Update Costs With Excluded Credit Types
const updateCostsWithExcludedCreditTypes: PostQueryStage = ({
  report,
  result,
}) => {
  const editedData = result.data.map<RawData>((datum) => {
    const updatedDatum = { ...datum };

    // Add excluded credits to net cost
    if (report.measures.includes("netCost")) {
      updatedDatum._originalNetCost = updatedDatum.netCost;

      updatedDatum.netCost = report.excludedCreditTypes.reduce(
        (netCost, creditType) => {
          const creditValue = safeParseNumber(updatedDatum[creditType]);

          return netCost + creditValue;
        },
        safeParseNumber(updatedDatum.netCost)
      );
    }

    // Remove excluded credits from absolute credits
    if (report.measures.includes("absoluteCredits")) {
      updatedDatum._originalAbsoluteCredits = updatedDatum.absoluteCredits;

      updatedDatum.absoluteCredits = report.excludedCreditTypes.reduce(
        (absoluteCredits, creditType) => {
          const creditValue = safeParseNumber(updatedDatum[creditType]);

          return absoluteCredits - creditValue;
        },
        safeParseNumber(updatedDatum.absoluteCredits)
      );
    }

    return updatedDatum;
  });

  result.data = editedData;
};

//
// Cumulative
//
const cumulative: PostQueryStage = ({ report, result }) => {
  if (report.isCumulative) {
    result.data = getCumulativeData({
      data: result.data,
      dimensions: report.dimensions,
      measures: report.measures,
    });
  }
};

//
// Fiscal Dates
//
// Replace timestamp with "FY2024-W52"
const applyFiscalDatesToTimestamp: PostQueryStage = ({ metaData, result }) => {
  const granularityMetaData = assertMetaData(metaData, "granularity");
  const fiscalDatesMetaData = assertMetaData(metaData, "fiscalDates");

  if (granularityMetaData.value && fiscalDatesMetaData.areGranularitiesActive) {
    result.data = createFiscalDates(result.data, granularityMetaData.value);
  }
};

//
// Has More
//
const hasMore: PostQueryStage = ({ metaData, result }) => {
  metaData.push({
    type: "hasMore",
    hasMore: result.data.length >= DEFAULT_LIMIT,
  });
};

//
// Merge After Limit Into Other
//
// TODO: Only apply this in the topN query.
// Merges anything past "limit" into Other (not shown)
const mergeAfterLimitIntoOther: PostQueryStage = ({
  metaData,
  report,
  result,
}) => {
  const granularityMetaData = assertMetaData(metaData, "granularity");
  const granularityOrderMetaData = assertMetaData(metaData, "granularityOrder");

  const xAxisKey =
    granularityOrderMetaData.granularityType === "invoiceMonth"
      ? "invoiceMonth"
      : report.xAxisKey
        ? report.xAxisKey
        : granularityMetaData.value
          ? DEFAULT_X_AXIS_KEY
          : undefined;

  if (report.limit) {
    result.data = mergeRawDataIntoOther({
      data: result.data,
      nonOtherCount: report.limit ?? DEFAULT_CHART_GROUPING_COUNT,
      dimensions: report.dimensions,
      measures: report.measures,
      mergeType:
        granularityMetaData.type !== null
          ? MergeType.TOP_GROUPINGS
          : report.xAxisKey !== DEFAULT_X_AXIS_KEY
            ? MergeType.EACH_XAXIS_KEY
            : MergeType.TOP_ITEMS,
      xAxisKey: xAxisKey,
    });
  }
};

//
// TopN
//
// subtracts top-grouping-data from daily totals to create 1 "Other" for
// Each day. Then adds daily "Other" data back to top-grouping-data.
const createAddTopN: (
  params: Pick<TopNParams, "dailyTotals" | "main">
) => PostQueryStage =
  ({ dailyTotals }) =>
  ({ metaData, report, result }) => {
    const isCanceled = !!findMetaData(metaData, "cancelQuery");

    if (isCanceled) {
      return;
    }

    const subtractGroupingFromDayTotal = (day: RawData, totals: RawData) => {
      report.measures.map((measure) => {
        totals[measure] =
          safeParseNumber(totals[measure]) - safeParseNumber(day[measure]);
      });
    };

    const workingDailyTotals = structuredClone(dailyTotals.result.data);
    const workingDailyTotalsKeyedByTimestamp = keyBy(
      workingDailyTotals,
      "timestamp"
    );

    for (const datum of result.data) {
      const dayTotal =
        workingDailyTotalsKeyedByTimestamp[String(datum.timestamp)];

      if (!dayTotal) continue;

      subtractGroupingFromDayTotal(datum, dayTotal);
    }

    for (const other of workingDailyTotals) {
      for (const dimension of report.dimensions) {
        other[dimension] = OTHER_NOT_SHOWN;
      }
    }

    result.data = result.data.concat(workingDailyTotals);
  };

//
// Remove Other
//
const removeOther: PostQueryStage = ({ report, result }) => {
  if (report.excludeOther) {
    result.data = removeOtherData({
      data: result.data,
      dimensions: report.dimensions,
    });
  }
};

//
//
// Get Data Function
//
//

export const GetReportDataFeature = {
  COMPARISON: "COMPARISON",
  DAILY_TOTALS: "DAILY_TOTALS",
  EXTERNAL_METRICS: "EXTERNAL_METRICS",
  GROUPING_TOTALS: "GROUPING_TOTALS",
  APPLY_TOP_N: "APPLY_TOP_N",
} as const;

export type GetReportDataFeature =
  (typeof GetReportDataFeature)[keyof typeof GetReportDataFeature];

type GetReportDataParams = {
  dependencies: Dependencies;
  features?: GetReportDataFeature[];
  report: ReportDataConfig;
};

//
// Merge External Data Into Main
//

type MergeExternalDataParams = {
  main: PostQueryStageParams;
  external: PostQueryStageParams;
};
//
// Matches external data to internal data by timestamp
// Query results must have matching granularities
//   and overlapping timestamps
export function mergeExternalDataIntoMain(params: MergeExternalDataParams) {
  const externalMetrics = assertMetaData(
    params.external.metaData,
    "externalMetrics"
  );

  if (!externalMetrics.isActive) {
    return;
  }

  const externalGranularityOrder = assertMetaData(
    params.external.metaData,
    "granularityOrder"
  );

  const mainGranularityOrder = assertMetaData(
    params.main.metaData,
    "granularityOrder"
  );

  const isMatchingGranularityType =
    externalGranularityOrder.granularityType ===
    mainGranularityOrder.granularityType;

  const isValidGranularityType =
    mainGranularityOrder.granularityType === "timestamp" ||
    mainGranularityOrder.granularityType === null;

  const dateIntersection = new Set(
    intersection(
      params.main.result.data.map((datum) => datum.timestamp),
      params.external.result.data.map((datum) => datum.timestamp)
    )
  );

  const hasSharedDates = dateIntersection.size > 0;

  if (
    !isMatchingGranularityType ||
    !isValidGranularityType ||
    !hasSharedDates
  ) {
    throw new UError(
      `mergeExternalData/${ReportConfigurationError.INVALID_EXTERNAL_GRANULARITIES}`,
      { params }
    );
  }

  const mainData = params.main.result.data.filter((datum) =>
    dateIntersection.has(datum.timestamp)
  );

  const externalData = params.external.result.data.filter((datum) =>
    dateIntersection.has(datum.timestamp)
  );

  const mainMeasures = params.main.query.measures ?? [];
  const metricAggregate = params.external.report.metricAggregate ?? "";
  const formula = params.external.report.formula ?? "";
  const formulaAlias =
    params.external.report.formulaAlias ??
    copyText.unitEconomicsFormulaPlaceHolder;
  const selectedMetricName = externalMetrics.selectedMetricName;

  if (!selectedMetricName || metricAggregate === "") return mainData;

  const mergedData = mainData.map((mainDatum, index) => {
    const externalDatum = externalData[index];

    const mergedDatum: RawData = { ...mainDatum };

    mergedDatum[selectedMetricName] =
      externalDatum[getMeasureFromMetricAggregate(metricAggregate)];

    if (formula) {
      mergedDatum[formulaAlias] = executeFormula({
        datum: mainDatum,
        formula,
        measures: mainMeasures,
        externalDatum,
      });
    }

    return mergedDatum;
  });

  const addedMeasures: string[] = [];

  if (selectedMetricName) addedMeasures.push(formulaAlias);
  if (formulaAlias) addedMeasures.push(formulaAlias);

  params.main.report.measures.push(...addedMeasures);
  params.main.result.data = mergedData;
}

type MergeComparisonDataParams = {
  comparison: PostQueryStageParams;
  main: PostQueryStageParams;
};

//
// Merge Comparison Data Into Main
//
// TODO: remove need for merging comparison data. Have charts &
// tables support a seperate "comparisonData" prop
export function mergeComparisonDataIntoMain(params: MergeComparisonDataParams) {
  const comparisonMetaData = findMetaData(
    params.comparison.metaData,
    "comparison"
  );

  if (!comparisonMetaData?.isActive) {
    return;
  }

  const mergedComparisonData = getComparisonData({
    compareData: params.comparison.result.data,
    compareDurationType: params.comparison.report.compareDurationType,
    data: params.main.result.data,
    dimensions: params.main.report.dimensions,
    measures: params.main.report.measures,
  });

  params.main.result.data = mergedComparisonData;
}

//
// Get Report Data Single Query
//
// hits the analytics endpoint at most one time per invocation
export async function getReportDataSingleQuery({
  dependencies,
  features: _features,
  report,
}: GetReportDataParams): Promise<PostQueryStageParams> {
  const features = _features ?? [];

  const externalMetrics = createExternalMetricsStage(
    dependencies.integrationMetricMap
  );

  const fiscalDates = createFiscalDatesStage(
    dependencies.isFiscalMode && dependencies.fiscalPeriodMap
      ? dependencies.fiscalPeriodMap
      : undefined
  );

  const globalFilters = createGlobalFiltersStage(dependencies.globalFilters);

  const comparisonIfIncluded = features.includes(
    GetReportDataFeature.COMPARISON
  )
    ? [comparison]
    : [];

  const dailyTotalsIfIncluded = features.includes(
    GetReportDataFeature.DAILY_TOTALS
  )
    ? [dailyTotals]
    : [];

  const externalMetricsIfIncluded = features.includes(
    GetReportDataFeature.EXTERNAL_METRICS
  )
    ? [externalMetrics]
    : [];

  const groupingTotalsIfIncluded = features.includes(
    GetReportDataFeature.GROUPING_TOTALS
  )
    ? [groupingTotals]
    : [];

  const metadataTotals =
    !features.includes(GetReportDataFeature.GROUPING_TOTALS) &&
    !features.includes(GetReportDataFeature.DAILY_TOTALS)
      ? [noTotals]
      : [];

  const topN =
    features.includes(GetReportDataFeature.APPLY_TOP_N) && dependencies.topN
      ? {
          pre: [createAddTopNFilters(dependencies.topN)],
          post: [createAddTopN(dependencies.topN)],
        }
      : { pre: [], post: [] };

  const result = await executeQueryStages({
    report,
    preQueryStages: [
      addExternalMetricsMetaData,
      ...comparisonIfIncluded,
      ...groupingTotalsIfIncluded,
      ...dailyTotalsIfIncluded,
      ...metadataTotals,
      basicParams,
      addExcludedCreditTypes,
      excludeNegatives,
      ...topN.pre,
      ...externalMetricsIfIncluded,
      granularity,
      filters,
      globalFilters,
      invoiceMonth,
      fiscalDates,
      removeInvalidFilters,
      measureOrder,
      reportOrder,
      granularityOrder,
    ],
    queryStage: createQueryStage({
      client: dependencies.client,
      tenantID: dependencies.tenantID,
    }),
    postQueryStages: [
      hasMore,
      updateCostsWithExcludedCreditTypes,
      cumulative,
      applyFiscalDatesToTimestamp,
      ...topN.post,
      mergeAfterLimitIntoOther,
      removeOther,
    ],
  });

  return result;
}

//
// Get Report Data Multi Query
//
// Makes all the queries necessary to render a report
export async function getReportData({
  dependencies,
  report,
}: Omit<GetReportDataParams, "features">) {
  //
  // Main Queries
  const mainPromise = getReportDataSingleQuery({
    dependencies,
    report,
  });
  // for table + topN
  const mainGroupingTotalsPromise = getReportDataSingleQuery({
    dependencies,
    features: [GetReportDataFeature.GROUPING_TOTALS],
    report,
  });
  // for topN
  const mainDailyTotalsPromise = getReportDataSingleQuery({
    dependencies,
    features: [GetReportDataFeature.DAILY_TOTALS],
    report,
  });

  //
  // Comparison Queries
  const comparisonPromise = getReportDataSingleQuery({
    dependencies,
    features: [GetReportDataFeature.COMPARISON],
    report,
  });
  // for table + topN
  const comparisonGroupingTotalsPromise = getReportDataSingleQuery({
    dependencies,
    features: [
      GetReportDataFeature.COMPARISON,
      GetReportDataFeature.GROUPING_TOTALS,
    ],
    report,
  });
  // for topN
  const comparisonDailyTotalsPromise = getReportDataSingleQuery({
    dependencies,
    features: [
      GetReportDataFeature.COMPARISON,
      GetReportDataFeature.DAILY_TOTALS,
    ],
    report,
  });

  //
  // External Queries
  const externalPromise = getReportDataSingleQuery({
    dependencies,
    features: [GetReportDataFeature.EXTERNAL_METRICS],
    report,
  });
  // for table
  const externalGroupingTotalsPromise = getReportDataSingleQuery({
    dependencies,
    features: [
      GetReportDataFeature.EXTERNAL_METRICS,
      GetReportDataFeature.GROUPING_TOTALS,
    ],
    report,
  });

  const [
    main,
    mainGroupingTotals,
    mainDailyTotals,
    comparison,
    comparisonGroupingTotals,
    comparisonDailyTotals,
    external,
    externalGroupingTotals,
  ] = await Promise.all([
    mainPromise,
    mainGroupingTotalsPromise,
    mainDailyTotalsPromise,
    comparisonPromise,
    comparisonGroupingTotalsPromise,
    comparisonDailyTotalsPromise,
    externalPromise,
    externalGroupingTotalsPromise,
  ]);

  const mainTopNPromise = getReportDataSingleQuery({
    dependencies: {
      ...dependencies,
      topN: {
        dailyTotals: mainDailyTotals,
        groupingTotals: mainGroupingTotals,
        main: main,
      },
    },
    features: [GetReportDataFeature.APPLY_TOP_N],
    report,
  });

  const comparisonTopNPromise = getReportDataSingleQuery({
    dependencies: {
      ...dependencies,
      topN: {
        dailyTotals: comparisonDailyTotals,
        groupingTotals: comparisonGroupingTotals,
        main: comparison,
      },
    },
    features: [
      GetReportDataFeature.COMPARISON,
      GetReportDataFeature.APPLY_TOP_N,
    ],
    report,
  });

  let [mainTopN, comparisonTopN] = await Promise.all([
    mainTopNPromise,
    comparisonTopNPromise,
  ]);

  if (isCanceled(mainTopN)) mainTopN = main;
  if (isCanceled(comparisonTopN)) comparisonTopN = comparison;

  mergeExternalDataIntoMain({
    external,
    main: mainTopN,
  });

  mergeExternalDataIntoMain({
    external: externalGroupingTotals,
    main: mainGroupingTotals,
  });

  mergeComparisonDataIntoMain({
    comparison: comparisonTopN,
    main: mainTopN,
  });

  mergeComparisonDataIntoMain({
    comparison: comparisonGroupingTotals,
    main: mainGroupingTotals,
  });

  return {
    main: mainTopN,
    mainGroupingTotals,
    comparison: comparisonTopN,
    comparisonGroupingTotals,
  };
}

//
// Helpers
//

function safeParseNumber(n: unknown) {
  const parsed = Number(n);

  return isNaN(parsed) ? 0 : parsed;
}

function isCanceled(result: GetReportDataResult) {
  return !!findMetaData(result.metaData, "cancelQuery");
}
