import { FeatureFlag } from "@ternary/api-lib/constants/feature-flags";
import Permission from "@ternary/api-lib/constants/permissions";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type ReportGates = ReturnType<typeof reportGates>;

export default function reportGates(params: GateGetterParameters) {
  const canAccessFocusTRE = params._featureFlagsIncludeAllOf([
    FeatureFlag.FOCUS_TRE,
  ]);

  const canUpdateReports = (createdByID: string) =>
    params._permissionsIncludeAllOf([Permission.UPDATE_REPORT]) ||
    createdByID === params.authenticatedUser.id;

  const canCreateSystemReport = params._permissionsIncludeAllOf([
    Permission.CREATE_REPORT_SYSTEM,
  ]);

  const canUpdateSystemReport = params._permissionsIncludeAllOf([
    Permission.UPDATE_REPORT_SYSTEM,
  ]);

  return {
    canAccessFocusTRE,
    canCreateReports: params._permissionsIncludeAllOf([
      Permission.CREATE_REPORT,
    ]),
    canCreateSystemReport,
    canListReports: params._permissionsIncludeAllOf([Permission.READ_REPORTS]),
    canListSystemReports: params._permissionsIncludeAllOf([
      Permission.READ_REPORTS_SYSTEM,
    ]),
    canUpdateReports,
    canUpdateSystemReport,
    getCanDeleteSpecificReport: (createdByID: string) =>
      params._permissionsIncludeAllOf([Permission.DELETE_REPORT]) ||
      Boolean(createdByID === params.authenticatedUser.id),
  };
}
