import { useTheme } from "@emotion/react";
import { MongoDbAuthType } from "@ternary/api-lib/constants/enums";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React from "react";
import { FormField } from "../../../../../ui-lib/components/Form";
import Select from "../../../../../ui-lib/components/Select";
import TextInput from "../../../../../ui-lib/components/TextInput";
import copyText from "../../copyText";
import { FormProps } from "./types";

const validators = {
  organizationID: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
  name: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
  publicKey: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
  privateKey: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
};

const authTypeOptions = [
  {
    label: copyText.mongoDbFormGeneralAuthorizationTypeApiKey,
    value: MongoDbAuthType.API_KEY,
  },
  {
    label: copyText.mongoDbFormGeneralAuthorizationTypeServiceAccount,
    value: MongoDbAuthType.SERVICE_ACCOUNT,
  },
];

export function MongoDbIntegrationFormGeneral(props: FormProps) {
  const theme = useTheme();

  const { form } = props;

  return (
    <Box padding={theme.space_xxs}>
      <form.Field
        name="name"
        validators={{ onChange: validators.name, onMount: validators.name }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              input={TextInput}
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              label={copyText.gcpFormGeneralNameInputLabel}
              required
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <form.Field
        name="organizationID"
        validators={{
          onChange: validators.organizationID,
          onMount: validators.organizationID,
        }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              width={400}
              input={TextInput}
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              label={copyText.mongoDbFormGeneralOrganizationIDInputLabel}
              placeholder={
                copyText.mongoDbFormGeneralOrganizationIDInputPlaceholder
              }
              required
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <form.Field
        name={"authType"}
        children={(field) => {
          return (
            <Flex
              alignItems="center"
              justifyContent="space-between"
              marginBottom={theme.space_sm}
            >
              <Text>{copyText.mongoDbFormGeneralAuthorizationTypeLabel}</Text>
              <Box width={160}>
                <Select
                  options={authTypeOptions}
                  value={authTypeOptions.find(
                    (option) => option.value === field.state.value
                  )}
                  onChange={(option) =>
                    option && field.handleChange(option.value)
                  }
                />
              </Box>
            </Flex>
          );
        }}
      />
      <form.Subscribe
        children={(state) => {
          return (
            <form.Field
              name={"publicKey"}
              validators={{
                onChange: validators.publicKey,
                onMount: validators.publicKey,
              }}
              children={(field) => {
                const shouldShowError =
                  field.state.meta.isTouched &&
                  field.state.meta.errors.length > 0;

                return (
                  <FormField
                    input={TextInput}
                    errorCaption={
                      shouldShowError
                        ? field.state.meta.errors.join(", ")
                        : undefined
                    }
                    label={
                      state.values.authType === MongoDbAuthType.API_KEY
                        ? copyText.mongoDbFormGeneralPublicKeyInputLabel
                        : copyText.mongoDbFormGeneralClientIDInputLabel
                    }
                    required
                    value={field.state.value}
                    variant={shouldShowError ? "danger" : undefined}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                  />
                );
              }}
            />
          );
        }}
      />
      <form.Subscribe
        children={(state) => {
          // If preview is longer than 0 we know it exists and we are editing and not creating
          // So we should show the preview.
          const value =
            state.values.privateKeyPreview.length > 0
              ? state.values.privateKeyPreview
              : state.values.privateKey;

          return (
            <form.Field
              name="privateKey"
              validators={{
                onChange: validators.privateKey,
                onMount: validators.privateKey,
              }}
              children={(field) => {
                return (
                  <FormField
                    input={TextInput}
                    label={
                      state.values.authType === MongoDbAuthType.API_KEY
                        ? copyText.mongoDbFormGeneralSecretKeyInputLabel
                        : copyText.mongoDbFormGeneralClientSecretInputLabel
                    }
                    required
                    value={value}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                  />
                );
              }}
            />
          );
        }}
      />
    </Box>
  );
}
