import Modal from "@/ui-lib/components/Modal";
import { useTheme } from "@emotion/react";
import {
  faArrowUpRightFromSquare,
  faList,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { RawData } from "@ternary/api-lib/analytics/types";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import {
  CostAlertEventType,
  DataSource,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import { LabelMapsEntity } from "@ternary/api-lib/core/types";
import DataTable from "@ternary/api-lib/ui-lib/charts/Table/DataTable";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { formatDate } from "@ternary/api-lib/ui-lib/utils/dates";
import { formatCurrency } from "@ternary/api-lib/ui-lib/utils/formatNumber";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import {
  detailedBillingDimensions,
  detailedBillingMeasures,
} from "../../../api/analytics/detailedBillingSchema";
import {
  AnomalyDetection,
  Forecasting,
  Threshold,
} from "../../../api/core/types";
import copyText from "../copyText";
import { LEGACY_BIG_QUERY_RULE_ID } from "../defaultAlertRules";
import { PredictionBoundData } from "../hooks/useGetBoundsData";
import { CostAlertDimension } from "../types";
import { getStringifiedDelta, getStringifiedRange } from "../utils";
import AnomalyChart, { AnomalyChartData } from "./AnomalyChart";

export const SIDE_DRAWER_WIDTH = "55rem";
const MAX_CHAR_HEADER = 31;
const MAX_CHAR_DETAILS = 55;

type CostAlert = {
  createdAt: string;
  dimensions: CostAlertDimension[];
  eventTime: string;
  eventType: CostAlertEventType;
  eventValue: number;
  expectedValue: { lowerBound: number; upperBound: number } | null;
  id: string;
  sourceAlertRule: {
    id: string;
    condition: AnomalyDetection | Forecasting | Threshold;
    dataSource: DataSource;
    timeGranularity: TimeGranularity;
    dimensions: string[];
    filters: {
      name: string;
      operator: Operator;
      values: string[] | null;
    }[];
    measure: string;
  };
};

interface Props {
  boundsData?: PredictionBoundData[];
  chartData: AnomalyChartData[];
  tableData: RawData[];
  alert: CostAlert;
  isLoadingChartData: boolean;
  isLoadingTableData: boolean;
  isOpen: boolean;
  labelMaps?: LabelMapsEntity;
  onClose: () => void;
  onInteraction: (interaction: AlertDetailModal.Interaction) => void;
}

export function AlertDetailModal(props: Props) {
  const theme = useTheme();

  const eventTimeDate = new Date(props.alert.eventTime);

  const labelMap = props.labelMaps?.BILLING ?? {};

  const reversedLabelMap = Object.entries(labelMap).reduce(
    (accum: { [key: string]: string }, [key, value]) => ({
      ...accum,
      [String(value)]: key,
    }),
    {}
  );

  const headerInfo = [
    ...(props.alert.dimensions[0]
      ? [
          {
            label:
              reversedLabelMap[props.alert.dimensions[0].key] ??
              props.alert.dimensions[0]?.key ??
              undefined,
            value: props.alert.dimensions[0].value ?? copyText.naText,
          },
        ]
      : []),
    ...(props.alert.dimensions[1]
      ? [
          {
            label:
              reversedLabelMap[props.alert.dimensions[0].key] ??
              props.alert.dimensions[1]?.key ??
              undefined,
            value: props.alert.dimensions[1].value ?? copyText.naText,
          },
        ]
      : []),
    {
      label: copyText.alertDetailModalDetectedAt,
      value: `${formatDate(eventTimeDate, "MM/dd/yyyy")} @ ${formatDate(eventTimeDate, "pp")}`,
    },
  ];

  return (
    <Modal isOpen={props.isOpen} width={1200} onClose={props.onClose}>
      <Modal.Header>
        <Flex
          alignItems="center"
          paddingBottom={theme.space_md}
          borderBottom={`1px solid ${theme.border_color}`}
          justifyContent="space-between"
          width="100%"
        >
          <Box marginRight={theme.space_xxl}>
            {headerInfo.map((info, index) => {
              const stringLength =
                index === 0 ? MAX_CHAR_HEADER : MAX_CHAR_DETAILS;

              return (
                <Flex alignItems="center" key={info.label}>
                  <Text
                    appearance={index === 0 ? "h4" : undefined}
                    bold
                    color={
                      index === 0
                        ? theme.text_color
                        : theme.text_color_secondary
                    }
                    marginRight={theme.space_xxs}
                  >
                    {`${info.label}:`}
                  </Text>
                  <Tooltip content={`${info.label}: ${info.value}`}>
                    <Text
                      appearance={index === 0 ? "h4" : undefined}
                      color={
                        index === 0
                          ? theme.text_color
                          : theme.text_color_secondary
                      }
                    >
                      {`${info.value.slice(
                        0,
                        stringLength
                      )}${info.value.length > stringLength ? "..." : ""}`}
                    </Text>
                  </Tooltip>
                </Flex>
              );
            })}
          </Box>
          <Flex alignItems="center">
            <Flex>
              <Box>
                <Flex justifyContent="flex-end">
                  <Text bold marginTop={theme.space_sm}>
                    {copyText.eventStreamStringifiedAlertActualValue}
                  </Text>
                </Flex>
                <Flex justifyContent="flex-end">
                  <Text>
                    {formatCurrency({
                      number: props.alert.eventValue,
                    })}
                  </Text>
                </Flex>
              </Box>
              <Box marginHorizontal={theme.space_lg}>
                <Flex justifyContent="flex-end">
                  <Text bold marginTop={theme.space_sm}>
                    {props.alert.eventType ===
                    CostAlertEventType.ANOMALY_DETECTED
                      ? copyText.eventStreamStringifiedAlertExpectedValue
                      : copyText.eventStreamStringifiedAlertThreshold}
                  </Text>
                </Flex>
                <Flex justifyContent="flex-end">
                  <Text>
                    {getStringifiedRange(
                      props.alert.expectedValue
                        ? props.alert.expectedValue.lowerBound
                        : 0,
                      props.alert.expectedValue
                        ? props.alert.expectedValue.upperBound
                        : 0
                    )}
                  </Text>
                </Flex>
              </Box>
              <Box>
                <Flex justifyContent="flex-end">
                  <Text bold marginTop={theme.space_sm}>
                    {copyText.eventStreamAlertDelta}
                  </Text>
                </Flex>
                <Flex justifyContent="flex-end">
                  <Text color={theme.feedback_negative}>
                    {getStringifiedDelta(
                      props.alert.eventValue,
                      props.alert.expectedValue
                        ? props.alert.expectedValue
                        : { upperBound: 0, lowerBound: 0 }
                    )}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Button
              iconStart={<Icon icon={faArrowUpRightFromSquare} />}
              marginRight={theme.space_xs}
              marginLeft={theme.space_lg}
              secondary
              size="small"
              onClick={() => {
                props.onInteraction({
                  type: AlertDetailModal.INTERACTION_INVESTIGATE_CLICKED,
                });
              }}
            >
              {copyText.goToReportButtonLabel}
            </Button>
            <Button
              iconStart={<Icon icon={faPlus} />}
              marginRight={theme.space_xs}
              secondary
              size="small"
              onClick={() => {
                props.onInteraction({
                  type: AlertDetailModal.INTERACTION_CREATE_CASE_CLICKED,
                  costAlertID: props.alert.id,
                  costAlertEventType: props.alert.eventType,
                });
              }}
            >
              {copyText.createCaseButtonLabel}
            </Button>
          </Flex>
        </Flex>
      </Modal.Header>
      <Box>
        <Box height="250px">
          {props.isLoadingChartData ? (
            <EmptyPlaceholder
              height="100%"
              icon={faList}
              loading={props.isLoadingChartData}
              skeletonVariant="cartesian"
            />
          ) : (
            <AnomalyChart
              boundsData={props.boundsData}
              data={props.chartData}
              eventTime={props.alert.eventTime}
              granularity={props.alert.sourceAlertRule.timeGranularity}
              lowerBound={
                props.alert.expectedValue
                  ? props.alert.expectedValue.lowerBound
                  : null
              }
              upperBound={
                props.alert.expectedValue
                  ? props.alert.expectedValue.upperBound
                  : null
              }
              value={props.alert.eventValue}
            />
          )}
        </Box>
        <Box width="100%" height="280px" overflow="hidden">
          {props.alert.sourceAlertRule.id === LEGACY_BIG_QUERY_RULE_ID ? (
            <EmptyPlaceholder
              height="100%"
              icon={faList}
              loading={false}
              skeletonVariant="cartesian"
              text={copyText.alertDetailModalTableUnavailableText}
            />
          ) : (
            <DataTable
              data={props.tableData}
              dimensions={[
                { name: detailedBillingDimensions.resourceId },
                { name: detailedBillingDimensions.category },
                { name: detailedBillingDimensions.skuDescription },
              ]}
              footer
              isLoading={props.isLoadingTableData}
              measures={[
                {
                  name: detailedBillingMeasures.usageAmount,
                  unit: UnitType.STANDARD,
                },
                { name: detailedBillingMeasures.cost, unit: UnitType.CURRENCY },
              ]}
            />
          )}
        </Box>
      </Box>
    </Modal>
  );
}

AlertDetailModal.INTERACTION_CREATE_CASE_CLICKED =
  `AlertDetailModal.INTERACTION_CREATE_CASE_CLICKED` as const;
AlertDetailModal.INTERACTION_INVESTIGATE_CLICKED =
  `AlertDetailModal.INTERACTION_INVESTIGATE_CLICKED` as const;

interface InteractionCreateCaseClicked {
  type: typeof AlertDetailModal.INTERACTION_CREATE_CASE_CLICKED;
  costAlertID: string;
  costAlertEventType: CostAlertEventType;
}
interface InteractionInvestigateClicked {
  type: typeof AlertDetailModal.INTERACTION_INVESTIGATE_CLICKED;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AlertDetailModal {
  export type Interaction =
    | InteractionCreateCaseClicked
    | InteractionInvestigateClicked;
}

export default AlertDetailModal;
