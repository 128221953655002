const queryKeys = {
  alibabaIntegration: (integrationID: string) =>
    ["integrations", "alibaba", integrationID] as const,
  awsIntegration: (integrationID: string) =>
    ["integrations", "aws", integrationID] as const,
  gcpIntegration: (integrationID: string) =>
    ["integrations", "gcp", integrationID] as const,
  mongoDbIntegration: (integrationID: string) =>
    ["integrations", "mongodb", integrationID] as const,
  oracleIntegration: (integrationID: string) =>
    ["integrations", "oracle", integrationID] as const,
  snowflakeIntegration: (integrationID: string) =>
    ["integrations", "snowflake", integrationID] as const,
};

export default queryKeys;
