import { DownloadOCIFormField } from "@/features/admin/components/DownloadOCIFormField";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import { FormField } from "@/ui-lib/components/Form";
import Select from "@/ui-lib/components/Select";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import copyText from "../../copyText";
import { OCID_REGEX, ociRegions } from "./constants";
import { FormProps } from "./types";

const validators = {
  name: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
  tenancyOCID: ({ value }: { value: string }) => {
    if (value.length === 0) {
      return copyText.errorInputFieldRequired;
    }

    return !OCID_REGEX.test(value)
      ? copyText.oracleFormGeneralOCIDInputError
      : undefined;
  },
  userOCID: ({ value }: { value: string }) => {
    if (value.length === 0) {
      return copyText.errorInputFieldRequired;
    }

    return !OCID_REGEX.test(value)
      ? copyText.oracleFormGeneralOCIDInputError
      : undefined;
  },
  region: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
};

const regions = ociRegions.map((region) => ({
  label: region,
  value: region,
}));

export function OracleIntegrationFormGeneral(
  props: FormProps & {
    showCertificate?: boolean;
  }
) {
  const theme = useTheme();
  const user = useAuthenticatedUser();

  const { form, showCertificate } = props;

  return (
    <Box padding={theme.space_xxs}>
      <form.Field
        name="name"
        validators={{ onChange: validators.name, onMount: validators.name }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              input={TextInput}
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              label={copyText.oracleFormGeneralNameLabel}
              required
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <form.Field
        name="tenancyOCID"
        validators={{
          onChange: validators.tenancyOCID,
          onMount: validators.tenancyOCID,
        }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(",") : undefined
              }
              input={TextInput}
              label={copyText.oracleFormGeneralTenancyOCIDLabel}
              required
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
              onBlur={field.handleBlur}
            />
          );
        }}
      />
      <form.Field
        name="userOCID"
        validators={{
          onChange: validators.userOCID,
          onMount: validators.userOCID,
        }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              input={TextInput}
              label={copyText.oracleFormGeneralUserOCIDLabel}
              required
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
              onBlur={field.handleBlur}
            />
          );
        }}
      />

      <form.Field
        name="region"
        validators={{ onChange: validators.region, onMount: validators.region }}
        children={(field) => {
          return (
            <FormField label={copyText.oracleFormGeneralRegionLabel} required>
              <Select
                options={regions}
                value={regions.find(
                  (option) => option.value === field.state.value
                )}
                onChange={(option) =>
                  option && field.handleChange(option.value)
                }
              />
            </FormField>
          );
        }}
      />

      {showCertificate && (
        <Box>
          <Text>{copyText.oracleFormGeneralOCICertificateLabel}</Text>
          <DownloadOCIFormField
            text={copyText.oracleFormGeneralOCICertificateAction}
            user={user}
          />
        </Box>
      )}
    </Box>
  );
}
