export const adminActions = {
  ALERT_ADMIN_ENV_NOT_AVAILABLE: "ALERT_ADMIN_ENV_NOT_AVAILABLE",
  ALERT_ADMIN_UNVERIFIED_EMAIL: "ALERT_ADMIN_UNVERIFIED_EMAIL",
  CLICK_ADMIN_CREATE_INTEGRATION: "CLICK_ADMIN_CREATE_INTEGRATION",
  CLICK_ADMIN_UPDATE_INTEGRATION: "CLICK_ADMIN_UPDATE_INTEGRATION",
  CLICK_USER_PREFERENCES_CHANGE_FISCAL_MODE:
    "CLICK_USER_PREFERENCES_CHANGE_FISCAL_MODE",
  CLICK_USER_PREFERENCES_CREATE_LABEL_PREFERENCES:
    "CLICK_USER_PREFERENCES_CREATE_LABEL_PREFERENCES",
  CLICK_USER_PREFERENCES_CREATE_MEASURE_PREFERENCES:
    "CLICK_USER_PREFERENCES_CREATE_MEASURE_PREFERENCES",
  CLICK_USER_PREFERENCES_UPDATE_LABEL_PREFERENCES:
    "CLICK_USER_PREFERENCES_UPDATE_LABEL_PREFERENCES",
  CLICK_USER_PREFERENCES_UPDATE_MEASURE_PREFERENCES:
    "CLICK_USER_PREFERENCES_UPDATE_MEASURE_PREFERENCES",
  CLICK_USER_PREFERENCES_UPDATE_NOTIFICATION_PREFERENCES:
    "CLICK_USER_PREFERENCES_UPDATE_NOTIFICATION_PREFERENCES",
  CLICK_USER_PREFERENCES_UPDATE_SETTINGS_NOTIFICATION_PREFERENCES:
    "CLICK_USER_PREFERENCES_UPDATE_SETTINGS_NOTIFICATION_PREFERENCES",
  TOGGLE_SETTINGS_CHANGE_THEME: "TOGGLE_SETTINGS_CHANGE_THEME",
  TOGGLE_SETTINGS_CHANGE_UBERGRAPH_DATE_RANGE:
    "TOGGLE_SETTINGS_CHANGE_UBERGRAPH_DATE_RANGE",
} as const;
