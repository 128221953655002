/* eslint-disable @typescript-eslint/no-empty-object-type */
import styled from "@emotion/styled";
import * as CSS from "csstype";
import React, {
  ComponentProps,
  DetailedHTMLProps,
  ElementType,
  HTMLAttributes,
} from "react";
import { Theme } from "../theme/default";
import Link from "./Link";

export interface BaseProps<T>
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  align?: CSS.Property.TextAlign;
  appearance?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "link";
  as?: T;
  bold?: boolean;
  color?: CSS.Property.Color;
  cursor?: CSS.Property.Cursor;
  float?: CSS.Property.Float;
  fontSize?: CSS.Property.FontSize;
  fontStyle?: CSS.Property.FontStyle;
  fontWeight?: CSS.Property.FontWeight;
  lineHeight?: CSS.Property.LineHeight;
  margin?: CSS.Property.Margin;
  marginBottom?: CSS.Property.MarginBottom;
  marginHorizontal?: CSS.Property.MarginLeft | CSS.Property.MarginRight;
  marginLeft?: CSS.Property.MarginLeft;
  marginRight?: CSS.Property.MarginRight;
  marginTop?: CSS.Property.MarginTop;
  marginVertical?: CSS.Property.MarginBottom | CSS.Property.MarginTop;
  overflowWrap?: CSS.Property.OverflowWrap;
  textDecoration?: CSS.Property.TextDecoration;
  theme?: Theme;
  truncate?: boolean | number | string;
  whiteSpace?: CSS.Property.WhiteSpace;
}

export type Props<T> = BaseProps<T> & GetPolyComponentProps<T>;

const _Text = styled("p")<BaseProps<{}>>(
  ({
    align,
    appearance,
    bold,
    color,
    cursor,
    float,
    fontSize,
    fontStyle,
    fontWeight,
    lineHeight,
    margin,
    marginBottom,
    marginHorizontal,
    marginLeft,
    marginRight,
    marginTop,
    marginVertical,
    overflowWrap,
    textDecoration,
    theme,
    truncate,
    whiteSpace,
  }) => ({
    ...(align && { textAlign: align }),
    color: color ?? theme.text_color,
    cursor: cursor ?? "",
    ...(float && { float }),
    fontSize: (() => {
      if (appearance && appearance !== "link") {
        return theme[`${appearance}_fontSize`];
      }

      if (fontSize) return fontSize;
      return theme.fontSize_ui;
    })(),
    ...(fontStyle && { fontStyle }),
    fontWeight: (() => {
      if (appearance && appearance !== "link") {
        return theme[`${appearance}_fontWeight`];
      }

      if (fontWeight) return fontWeight;
      return theme.fontWeight_regular;
    })(),
    ...(bold && { fontWeight: theme.fontWeight_bold }),
    lineHeight: lineHeight ?? theme.lineHeight_ui,
    margin: margin ?? 0,
    ...(marginBottom && { marginBottom }),
    ...(marginHorizontal && {
      marginLeft: marginHorizontal,
      marginRight: marginHorizontal,
    }),
    ...(marginLeft && { marginLeft }),
    ...(marginRight && { marginRight }),
    ...(marginTop && { marginTop }),
    ...(marginVertical && {
      marginBottom: marginVertical,
      marginTop: marginVertical,
    }),
    ...(overflowWrap && { overflowWrap }),
    ...(textDecoration && { textDecoration }),
    ...(truncate
      ? {
          maxWidth: truncate === true ? "100%" : truncate,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          wordWrap: "normal",
        }
      : {}),
    ...(whiteSpace && { whiteSpace }),

    "&:hover": {
      ...(appearance === "link"
        ? {
            color: theme.link_color_hover,
            cursor: "pointer",
            textDecoration: "underline",
          }
        : {}),
    },

    "&:focus": {
      ...(appearance === "link" ? { color: theme.link_color } : {}),
    },

    "> *": {
      ...(truncate
        ? {
            maxWidth: truncate === true ? "100%" : truncate,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            wordWrap: "normal",
          }
        : {}),
    },
  })
);

type GetPolyComponentProps<T> = T extends ElementType | typeof Link
  ? ComponentProps<T>
  : {};

export default function Text<T>(props: Props<T>): JSX.Element {
  return <_Text {...props} />;
}
