import { FormField } from "@/ui-lib/components/Form";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import React from "react";
import copyText from "../../copyText";
import { OCID_BUCKET_REGEX, OCID_NAMESPACE_REGEX } from "./constants";
import { FormProps } from "./types";

const validators = {
  storageBucket: ({ value }: { value: string }) => {
    if (value) {
      return !OCID_BUCKET_REGEX.test(value) || value.length >= 256
        ? copyText.oracleFormCustomStorageBucketInputError
        : undefined;
    }

    return undefined;
  },
  storageNamespace: ({ value }: { value: string }) => {
    if (value) {
      return !OCID_NAMESPACE_REGEX.test(value) || value.length >= 20
        ? copyText.oracleFormCustomStorageNamespaceInputError
        : undefined;
    }

    return undefined;
  },
};

export function OracleIntegrationFormCustom(props: FormProps) {
  const theme = useTheme();

  const { form } = props;

  return (
    <Box padding={theme.space_xxs}>
      <form.Field
        name="storageBucket"
        validators={{
          onChange: validators.storageBucket,
          onMount: validators.storageBucket,
        }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              input={TextInput}
              label={copyText.oracleFormGeneralStorageBucketLabel}
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
              onBlur={field.handleBlur}
            />
          );
        }}
      />

      <form.Field
        name="storageNamespace"
        validators={{
          onChange: validators.storageNamespace,
          onMount: validators.storageNamespace,
        }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              input={TextInput}
              label={copyText.oracleFormGeneralStorageNamespaceLabel}
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
              onBlur={field.handleBlur}
            />
          );
        }}
      />
    </Box>
  );
}
