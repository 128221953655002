import { MongoDbAuthType } from "@ternary/api-lib/constants/enums";
import { FormData } from "./types";

export const defaultValues: FormData = {
  authType: MongoDbAuthType.API_KEY,
  name: "",
  organizationID: "",
  privateKey: "",
  privateKeyPreview: "",
  publicKey: "",
};
