import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../../api/core/CoreAPIClient";
import CoreAPIClientError from "../../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../../api/core/CoreAPIClientProvider";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import queryKeys from "./queryKeys";

type OracleIntegrationEntity = Awaited<
  ReturnType<CoreAPIClient["getOracleIntegrationByID"]>
>;

type Options = UseQueryOptions<OracleIntegrationEntity, CoreAPIClientError>;
type Result = UseQueryResult<OracleIntegrationEntity, CoreAPIClientError>;

export function useGetOracleIntegrationByID(
  integrationID: string,
  options?: Options
): Result {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: queryKeys.oracleIntegration(integrationID),
    queryFn: () => client.getOracleIntegrationByID(integrationID),
    ...options,
  });
}
