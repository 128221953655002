import { useTheme } from "@emotion/react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { CostAlertStatus } from "@ternary/api-lib/core/types/CostAlert";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button, {
  Props as ButtonProps,
} from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import React from "react";
import DropdownButton from "../../../ui-lib/components/DropdownButton";
import copyText from "../copyText";

type Props = {
  status: CostAlertStatus;
  costAlertID: string;
  onInteraction: (interaction: CostAlertStatusButton.Interaction) => void;
};

export function CostAlertStatusButton(props: Props) {
  const copyTextKey: keyof typeof copyText = `costAlertStatus_${props.status}`;
  const buttonCopy = copyText[copyTextKey];
  const theme = useTheme();

  return (
    <DropdownButton
      button={
        <Button
          iconEnd={<Icon icon={faChevronDown} />}
          size="small"
          width={144}
          {...getButtonStyles(props.status)}
        >
          {buttonCopy}
        </Button>
      }
      placement="bottom"
    >
      <Box paddingHorizontal={theme.space_sm} paddingVertical={theme.space_xs}>
        {props.status !== CostAlertStatus.ACTIVE && (
          <Button
            marginBottom={theme.space_sm}
            size="small"
            width={144}
            {...getButtonStyles(CostAlertStatus.ACTIVE)}
            onClick={() =>
              props.onInteraction({
                type: CostAlertStatusButton.INTERACTION_UPDATE_STATUS_CLICKED,
                costAlertID: props.costAlertID,
                status: CostAlertStatus.ACTIVE,
              })
            }
          >
            {copyText.costAlertStatus_ACTIVE}
          </Button>
        )}

        {props.status !== CostAlertStatus.INVESTIGATING && (
          <Button
            marginBottom={theme.space_sm}
            size="small"
            width={144}
            {...getButtonStyles(CostAlertStatus.INVESTIGATING)}
            onClick={() =>
              props.onInteraction({
                type: CostAlertStatusButton.INTERACTION_UPDATE_STATUS_CLICKED,
                costAlertID: props.costAlertID,
                status: CostAlertStatus.INVESTIGATING,
              })
            }
          >
            {copyText.costAlertStatus_INVESTIGATING}
          </Button>
        )}

        {props.status !== CostAlertStatus.RESOLVED && (
          <Button
            marginBottom={theme.space_sm}
            size="small"
            width={144}
            {...getButtonStyles(CostAlertStatus.RESOLVED)}
            onClick={() =>
              props.onInteraction({
                type: CostAlertStatusButton.INTERACTION_UPDATE_STATUS_CLICKED,
                costAlertID: props.costAlertID,
                status: CostAlertStatus.RESOLVED,
              })
            }
          >
            {copyText.costAlertStatus_RESOLVED}
          </Button>
        )}

        {props.status !== CostAlertStatus.UNRESOLVED && (
          <Button
            size="small"
            width={144}
            {...getButtonStyles(CostAlertStatus.UNRESOLVED)}
            onClick={() =>
              props.onInteraction({
                type: CostAlertStatusButton.INTERACTION_UPDATE_STATUS_CLICKED,
                costAlertID: props.costAlertID,
                status: CostAlertStatus.UNRESOLVED,
              })
            }
          >
            {copyText.costAlertStatus_UNRESOLVED}
          </Button>
        )}
      </Box>
    </DropdownButton>
  );
}

function getButtonStyles(status: CostAlertStatus) {
  const buttonStyleProps: ButtonProps = {};

  switch (status) {
    case CostAlertStatus.ACTIVE:
      buttonStyleProps.variant = "success";
      break;

    case CostAlertStatus.INVESTIGATING:
      buttonStyleProps.variant = "warn";
      break;

    case CostAlertStatus.RESOLVED:
      buttonStyleProps.variant = "success";
      break;

    case CostAlertStatus.UNRESOLVED:
      buttonStyleProps.variant = "danger";
      break;
  }

  return buttonStyleProps;
}

CostAlertStatusButton.INTERACTION_UPDATE_STATUS_CLICKED =
  `CostAlertStatusButton.INTERACTION_UPDATE_STATUS_CLICKED` as const;

interface InteractionUpdateStatusClicked {
  type: typeof CostAlertStatusButton.INTERACTION_UPDATE_STATUS_CLICKED;
  costAlertID: string;
  status: CostAlertStatus;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CostAlertStatusButton {
  export type Interaction = InteractionUpdateStatusClicked;
}

export default CostAlertStatusButton;
