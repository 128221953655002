import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../../api/core/CoreAPIClient";
import CoreAPIClientError from "../../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../../api/core/CoreAPIClientProvider";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import queryKeys from "./queryKeys";

type GcpIntegrationEntity = Awaited<
  ReturnType<CoreAPIClient["getGcpIntegrationByID"]>
>;

type Options = UseQueryOptions<GcpIntegrationEntity, CoreAPIClientError>;
type Result = UseQueryResult<GcpIntegrationEntity, CoreAPIClientError>;

export function useGetGcpIntegrationByID(
  integrationID: string,
  options?: Options
): Result {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: queryKeys.gcpIntegration(integrationID),
    queryFn: () => client.getGcpIntegrationByID(integrationID),
    ...options,
  });
}
