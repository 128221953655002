import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../../api/core/CoreAPIClient";
import CoreAPIClientError from "../../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../../api/core/CoreAPIClientProvider";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import queryKeys from "./queryKeys";

type PublicMongoDbIntegrationEntity = Awaited<
  ReturnType<CoreAPIClient["getMongoDbIntegrationByID"]>
>;

type Options = UseQueryOptions<
  PublicMongoDbIntegrationEntity,
  CoreAPIClientError
>;
type Result = UseQueryResult<
  PublicMongoDbIntegrationEntity,
  CoreAPIClientError
>;

export function useGetMongoDbIntegrationByID(
  integrationID: string,
  options?: Options
): Result {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: queryKeys.mongoDbIntegration(integrationID),
    queryFn: () => client.getMongoDbIntegrationByID(integrationID),
    ...options,
  });
}
