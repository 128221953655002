import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import CoreAPIClientError from "../../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../../api/core/CoreAPIClientProvider";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { integrationID: string }
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { integrationID: string }
>;

export default function useDeleteMongoDbIntegration(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ integrationID }) =>
      client.deleteMongoDbIntegration(integrationID),
    ...options,
  });
}
