import common from "../../common.copyText";

/* prettier-ignore */
export default {
  ...common,
  actionMenuItemAddReport: "Add Report",
  actionMenuItemDeleteDashboard: "Delete Dashboard",
  actionMenuItemDeleteReport: "Delete Report",
  actionMenuItemDeleteSavingOpp: "Delete Savings Opportunity",
  actionMenuItemEditDashboard: "Edit Details",
  actionMenuItemeditDashboardLayout: "Edit Layout",
  actionMenuItemDistributeSystemDashboard: "Distribute Dashboard",
  actionMenuItemDistributeSystemReport: "Distribute Report",
  actionMenuItemEditReport: "Edit Details",
  actionMenuItemExportReportPDF: "Export PDF",
  actionMenuItemMakeHomePageDashboard: "Make Homepage Dashboard",
  actionMenuItemRemoveReport: "Remove Report",
  actionMenuItemRemoveSystemDashboard: "Remove Dashboard",
  actionMenuItemRemoveSystemReport: "Remove Report",
  actionMenuItemSaveAsReport: "Save Report",
  actionMenuItemSaveToDashboard: "Save to Dashboard",
  actionMenuItemSubscribeToDashboard: "Subscribe to Dashboard",
  actionMenuItemSubscribeToReport: "Subscribe to Report",
  actionMenuItemUpdateSavingOpp: "Update Savings Opportunity",
  actionMenuItemViewDashboard: "View Dashboard",
  actionMenuItemViewReport: "View Report",
  addBudget: "Add Budget",
  addButtonLabel: "Add",
  addFilterButtonLabel: "Add Filter",
  addFilterValueDialogue: 'Add "%VALUE%"',
  addLimitValueDialogue: 'Add Top "%VALUE%"',
  addSavingOpportunity: "Add Saving Opportunity",
  addSavingsRealized: "Add Realized Savings",
  addWidgetButtonLabel: "Add Widget",
  applyFilterButtonLabel: "Apply Filter",
  backButtonLabel: 'Back to %TITLE',
  budgetLabelCurrentMonth: "Budget: Current Month",
  budgetLabelDailyTrends: "Budget: Daily Trends",
  chartEmptyPlaceholderText: "No Usage Data to Display",
  chartOptions: "Chart Options",
  chartTypeOptionArea: "Area",
  chartTypeOptionClusteredBar: "Clustered Bar",
  chartTypeOptionKPI: "KPI",
  chartTypeOptionLine: "Line",
  chartTypeOptionPie: "Pie",
  chartTypeOptionStackedArea: "Stacked Area",
  chartTypeOptionStackedBar: "Stacked Bar",
  chartTypeOptionTable: "Table",
  checkboxCommitedUsageDiscountDollarBaseLabel: "Committed Usage Discount Dollar Base",
  checkboxCommitedUsageDiscountLabel: "Committed Usage Discount",
  checkboxDiscountLabel: "Discount",
  checkboxFreeTierLabel: "Free Tier",
  checkboxPromotionLabel: "Promotion",
  checkboxSubscriptionBenefitLabel: "Subscription Benefit",
  checkboxSustainedUsageDiscountLabel: "Sustained Usage Discount",
  clickRunReportPlaceholder: "Click Run Report button to view results.",
  createCustomMetricButtonLabel: "Create Unit Reporting Metric",
  createDashboardButtonLabel: "Create Dashboard",
  createReportButtonLabel: "Create Report",
  createSavingOpportunity: "Create New",
  creditsHeader: "Credits",
  csvTotalsDeltasHeader: "Totals Deltas",
  csvTotalsHeader: "Totals",
  cumulative: "Cumulative",
  customMetricFormBigQueryDatasetIDLabel: "DatasetID",
  customMetricFormBigQueryInputTypeLabel: "BigQuery",
  customMetricFormBigQueryLocationLabel: "Location",
  customMetricFormBigQueryProjectIDLabel: "ProjectID",
  customMetricFormBigQueryTableIDLabel: "TableID",
  customMetricFormBigQueryTableLimitExceededMessage: "The chosen table exceeds the current size limit of 50k. Please try again with asmaller data set.",
  customMetricFormCreateCustomMetricTitle: "Create Custom Metric",
  customMetricFormCsvDocumentationLabel: "Please refer to our documentation for more info on CSV requirements.",
  customMetricFormCsvInputTypeLabel: "CSV",
  customMetricFormCsvUploadLabel: "Upload CSV Here",
  customMetricFormDataTypeInputLabel: "Input Column Data Type",
  customMetricFormInvalidDimensionInputTooltipLabel: "A dimension key already exists with this value, please rename this column and try again.",
  customMetricFormInvalidNameInputTooltipLabel: "A custom measure currently exists with that name. Please delete the current custom measure or try another name.",
  customMetricFormNameInputTooltipLabel: "Selectable measure label that will be made available in TRE after creation",
  customMetricFormSchemaDimensionLabel: "Dimension",
  customMetricFormSchemaMeasureLabel: "Measure",
  customMetricFormSchemaSelectPlaceholder: "Please select a data type",
  customMetricFormSchemaTimestampLabel: "Timestamp",
  customMetricFormTitle: "Metric",
  customMetricFormTypeInputLabel: "Type",
  customMetricFormUpdateCustomMetricTitle: "Update Custom Metric",
  customMetricFormValidateLabel: "Validate",
  customMetricTableFailure: "Failure",
  customMetricTableInProgress: "In Progress",
  customMetricTableInProgressTooltip: "Your custom metric data is being ingested, please check back later",
  customMetricTableSuccess: "Success",
  customMetricTableSuccessTooltip: "Your custom metric is ready for consumption",
  dashboardAutosaveChangesSaved: "Changes Saved",
  dashboardAutosaveSaving: "Saving...",
  dashboardEditAddTagsTooltipMessage: "Add selected tags to all selected dashboards",
  dashboardEditRemoveTagsTooltipMessage: "Remove selected tags from all selected dashboards",
  dashboardEditSelectDisabledPlaceholder: "Select add or remove to enable",
  dashboardEditSelectPlaceholder: "Select tags",
  dashboardFilterButtonLabel: "Dashboard Filter",
  dashboardFormCreateTitle: "Create Dashboard",
  dashboardFormModalPlaceholder: "Untitled Dashboard",
  dashboardFormModalTagsLabel: "Add Tags",
  dashboardFormUpdateTitle: "Update Dashboard",
  dashboardListCancelEditToolTipMessage: "Cancel Edit",
  dashboardListEditNTagsToolTipMessage: "Edit %NUMBER% Dashboard Tags",
  dashboardNameCopyLabel: "Copy",
  dashboardSubscriptionDayOfWeek: "Day of week",
  dashboardSubscriptionDayPlaceholder: "Select Day",
  dashboardSubscriptionEmailPlaceholder: "Select Email",
  dashboardSubscriptionFormDailyLabel: "Daily",
  dashboardSubscriptionFormFirstWeekDefaultLabel: "First",
  dashboardSubscriptionFormFrequencyLabel: "Frequency",
  dashboardSubscriptionFormHourOfDayLabel: "Hour of Day",
  dashboardSubscriptionFormMondayDefaultLabel: "Monday",
  dashboardSubscriptionFormMonthlyLabel: "Monthly",
  dashboardSubscriptionFormSubscribersLabel: "Subscribers",
  dashboardSubscriptionFormTimezoneCaption: "Time selected would be %TIME%",
  dashboardSubscriptionFormWeeklyLabel: "Weekly",
  dashboardSubscriptionFrequencyPlaceholder: "Select Frequency",
  dashboardSubscriptionListCreatedAtTableHeader: "Created At",
  dashboardSubscriptionListCreatedByTableHeader: "Created By",
  dashboardSubscriptionListFrequencyTableHeader: "Frequency",
  dashboardSubscriptionListSubscriberCountHeader: "# of Subscribed",
  dashboardSubscriptionModalCreateButtonLabel: "Create Subscription",
  dashboardSubscriptionSelectedTime: "Selected Time:",
  dashboardSubscriptionSendTime: "Send Time:",
  dashboardSubscriptionSubscribeButtonLabel: "Subscribe",
  dashboardSubscriptionTimezoneMessage: "All emailed dashboards are sent using UTC timezone",
  dashboardSubscriptionWeekOfMonth: "Week of month",
  dashboardSubscriptionWeekPlaceholder: "Select Week",
  dashboardTimeLastUpdatedCaption: "Updated %distance% ago",
  dataAttributeDate: "Date",
  dataAttributeMeasure: "Measure",
  deleteDashboardConfirmationMessage: "Are you sure you want to delete this dashboard? This action is permenant and can't be undone.",
  deleteDashboardConfirmationTitle: "Delete Dashboard",
  deleteDashboardSubscriptionConfirmationMessage: "Are you sure you want to delete this subscription? There are currently %NUMBER% emails subscribed to this dashboard that will no longer be notified.\n\nThis action is permenant and can't be undone.",
  deleteDashboardSubscriptionConfirmationTitle: "Delete Dashboard Subscription",
  deleteReportConfirmationMessage: "Are you sure you want to do this? This action is permenant and can't be undone.",
  deleteReportConfirmationTitle: "Delete Report",
  deleteReportSpecConfirmationMessage: "Are you sure you want to delete this report from the dashboard?",
  deleteReportSubscriptionConfirmationMessage: "Are you sure you want to delete this subscription? There are currently %NUMBER% emails subscribed to this report that will no longer be notified.\n\nThis action is permenant and can't be undone.",
  deleteReportSubscriptionConfirmationTitle: "Delete Report Subscription",
  dimensionsHeader: "Groupings",
  disableAddGroupingTooltipCaption: "Resource data only available for 2 additional groupings",
  disableDateControlTooltipCaption: "Resource only supported for 31 days or less",
  disableFiscalMode: "Please disable fiscal calendar mode in your personal settings before querying Carbon data.",
  disableFiscalModeTitle: "Disable Fiscal Calendar Mode",
  disabledSavingsOppsPlaceholderText: "Your MSP admin has disabled viewing savings opportunities.",
  downloadCSVTooltip: "Download CSV",
  durationTypeCustomCaption: "Custom",
  durationTypeFiscalLastNinetyDaysCaption: "Prev. Quarter",
  durationTypeFiscalLastSevenDaysCaption: "Prev. Week",
  durationTypeFiscalLastThirtyDaysCaption: "Prev. Month",
  durationTypeFiscalMonthToDateDaysCaption: "Fiscal MTD",
  durationTypeFiscalQuarterToDateCaption: "Fiscal QTD",
  durationTypeFiscalYearToDateCaption: "Fiscal YTD",
  durationTypeInvoiceCaption: "Invoice",
  durationTypeLastMonthCaption: "Last Month",
  durationTypeLastNinetyDaysCaption: "Last 90 Days",
  durationTypeLastSevenDaysCaption: "Last 7 Days",
  durationTypeLastThirtyDaysCaption: "Last 30 Days",
  durationTypeMonthToDateDaysCaption: "Month to Date",
  durationTypeQuarterToDateCaption: "Quarter to Date",
  durationTypeTodayCaption: "Today",
  durationTypeYearToDateCaption: "Year to Date",
  durationTypeYesterdayCaption: "Yesterday",
  emptyDashboard: "No Dashboard to Display",
  emptyReports: "No Reports to Display",
  errorCreatingCustomMetricMessage: "An error occurred while attempting to create the custom metric. Please try again.",
  errorCreatingReportMessage: "An error occurred while attempting to create the report. Please try again.",
  errorCreatingResourceSubscriptionMessage: "An error occurred while attempting to create the subscription. Please try again.",
  errorCreatingSavingOpportunityMessage: "An error occurred while attempting to create the saving opportunity. Please try again.",
  errorDeletingCustomMetricMessage: "An error occurred while attempting to delete the custom metric. Please try again.",
  errorDeletingDashboardMessage: "An error occurred while attempting to delete the dashboard. Please try again.",
  errorDeletingReportMessage: "An error occurred while attempting to delete the report. Please try again.",
  errorDeletingResourceSubscriptionMessage: "An error occurred while attempting to delete the subscription. Please try again.",
  errorDeletingSavingOpportunityMessage: "An error occurred while attempting to delete the saving opportunity. Please try again.",
  errorDistributingSystemDashboardMessage: "An error occurred while attempting to distribute the dashboard to all Tenants. Please try again.",
  errorDistributingSystemReportMessage: "An error occurred while attempting to distribute the report to all Tenants. Please try again.",
  errorLoadingBigQueryMetadataMessage: "There was an error loading the requested data. Please refresh the page and try again.",
  errorLoadingBillingDataMessage: "There was an error loading the requested data. Please refresh the page and try again.",
  errorLoadingCSVMessage: "There was an error loading the CSV data. Please refresh the page and try again.",
  errorLoadingCustomMetricsMessage: "There was an error loading the Custom Metrics data. Please refresh the page and try again.",
  errorLoadingDashboardMessage: "There was an error loading the dashboard. Please refresh the page and try again.",
  errorLoadingDashboardsMessage: "There was an error loading dashboards. Please refresh the page and try again.",
  errorLoadingDatadogIntegrationMessage: "There was an error loading the datadog integration. Please refresh the page and try again.",
  errorLoadingReportMessage: "There was an error loading the selected report. Please try again.",
  errorLoadingReportPDFMessage: "There was an error loading the selected PDF. Please try again.",
  errorLoadingReportsMessage: "There was an error loading reports. Please try again.",
  errorLoadingSavingOpportuinitiesMessage: "There was an error loading saving opportuinities. Please try again.",
  errorLoadingSavingOpportunitiesMessage: "There was an error loading saving opportunities. Please try again.",
  errorRemovingSystemDashboardMessage: "An error occurred while attempting to remove the dashboard from all Tenants. Please try again.",
  errorRemovingSystemReportMessage: "An error occurred while attempting to remove the report from all Tenants. Please try again.",
  errorUpdateSavingOpportunityMessage: "An error occurred while attempting to update the saving opportunity. Please try again.",
  errorUpdatingCustomMetricMessage: "An error occurred while attempting to update the custom metric. Please try again.",
  errorUpdatingDashboardMessage: "An error occurred while attempting to update the dashboard. Please try again.",
  errorUpdatingDashboardsMessage: "An error occurred while attempting to update the dashboards. Please try again.",
  errorUpdatingPreferredDashboardMessage: "An error occurred while attempting to update your preferred dashboard. Please try again.",
  errorUpdatingReportMessage: "An error occurred while attempting to update the report. Please try again.",
  errorUpdatingResourceSubscriptionMessage: "An error occurred while attempting to update the subscription. Please try again.",
  excludeNegativesLabel: "Exclude Negatives",
  excludeNegativesToolTipCaption: "Toggle to exclude negative values in report",
  expandReportViewContainer: "Click to expand grid view",
  exportReportPDFComparisonCaption: "Comparison charts are not supported, please try another report.",
  exportReportPDFCumulativeCaption: "Cumulative charts are not supported, please try another report.",
  exportReportPDFExcludedCreditTypesTooltipCaption: "All credits must be included when netCost is selected, please try another report.",
  exportReportPDFImpactModeTooltipCaption: "Carbon Impact is not supported, please try another report.",
  exportReportPDFInvalidConfigTooltipCaption: "Invalid configuration, please try another supported report.",
  exportReportPDFKPI: "KPI charts are not supported, please try another report.",
  exportReportPDFLargeDatasetTooltipCaption: "We have detected that this configuration resulted in a dataset that is too large to display here, please try another report.",
  exportReportPDFReverseCaption: "Reversed charts are not supported, please try another report.",
  exportReportPDFSaveReportTooltipCaption: "Please save this report in order to export.",
  exportReportPDFUnitEconomicsTooltipCaption: "Unit Economics is not supported, please try another report.",
  favoriteReportButtonLabel: "Favorite",
  filterDashboardsByTag: "Filter by Tag",
  filterReportsByOwnerPlaceholder: "Filter by Created By",
  filterReportsByTag: "Filter by Tag",
  filterReportsDashboardsPlaceholder: "Filter by Created By",
  filtersHeader: "Filters",
  formulaHeader: "Formula",
  homepageDashboardTooltip: "Set as Home Dashboard",
  largeCSVMessage: "We have detected that your CSV export is too large to be fully exported.",
  limitHeader: "Limit",
  limitOptionCreateDialogue: "Limit to top %VALUE%",
  limitOptionLabelBy: "by",
  limitOptionLabelShowAll: "Show All",
  limitOptionLabelTextInput: "Custom Limit...",
  limitOptionLabelTop: "Top %LIMIT%",
  maximumNumberOfReports: "(max)",
  measuresHeader: "Measures",
  modalMessageDistributeDashboard: "This action will add a copy of this dashboard to all Tenants. Are you sure you want to do this?",
  modalMessageDistributeReport: "This action will add a copy of this report to all Tenants. Are you sure you want to do this?",
  modalMessageRemoveSystemDashboard: "This action will remove the copy of this dashboard from all Tenants. Are you sure you want to do this?",
  modalMessageRemoveSystemReport: "This action will remove the copy of this report from all Tenants. Are you sure you want to do this?",
  modalMessageUpdateSystemDashboard: "This is a system dashboard. Updating this dashboard will update all of it's copies in each tenant. Are you sure you want to do this?",
  modalMessageUpdateSystemReport: "This is a system report. Updating this report will update all of it's copies in each tenant. Are you sure you want to do this?",
  modalMessageUpdateSystemDashboards: "These are system dashboards. Updating these dashboards will update all of their copies in each tenant. Are you sure you want to do this?",
  modalMessageUpdateSystemReports: "These are system reports. Updating these reports will update all of their copies in each tenant. Are you sure you want to do this?",
  modalTitleAddDailyBudgetWidget: "Add Daily Trends Budget",
  modalTitleAddMonthlyBudgetWidget: "Add Monthly Budget",
  modalTitleConfirm: "Confirmation",
  modalTitleConfirmSaveExistingReportToDashboard: "Save %report% to %dashboard%?",
  modalTitleCreateAndSaveToDashboard: "and save to %dashboard%",
  modalTitleCreateReport: "Create New Report",
  modalTitleCreateSavingsOpportunity: "Create Savings Opportunities",
  modalTitleCustomMetrics: "Custom Metrics",
  modalTitleDistributeDashboard: "Distribute Dashboard",
  modalTitleDistributeReport: "Distribute Report",
  modalTitleEditDashboard: "Edit Dashboard",
  modalTitleEditReport: "Edit Report",
  modalTitleEditSubscriptionToDashboard: "Edit Subscriptions For This Dashboard",
  modalTitleEditSubscriptionToReport: "Edit Subscriptions For This Report",
  modalTitleEditSystemDashboard: "Edit System Dashboard",
  modalTitleEditSystemReport: "Edit System Report",
  modalTitleEditSystemDashboards: "Edit System Dashboards",
  modalTitleEditSystemReports: "Edit System Reports",
  modalTitleEditTagsForm: "Edit Tags",
  modalTitleRemoveDashboard: "Remove Dashboard",
  modalTitleRemoveReport: "Remove Report",
  modalTitleReports: "Reports",
  modalTitleSaveToDashboard: "Save to Dashboard",
  modalTitleSavingsOpportunity: "Savings Opportunities",
  modalTitleSubscribeToDashboard: "Subscribe to Dashboard",
  modalTitleSubscribeToReport: "Subscribe to Report",
  modalTitleUpdateSavingsOpportunity: "Update Savings Opportunities",
  moreOptionsLabel: "more",
  newReportButtonLabel: "Create New Report",
  noMeasureSelectedPlaceholder: "Select a Measure to get started.",
  notConfiguredPlaceholderText: "It appears this feature is not yet configured. Please consult the Admin Page for more information.",
  openReportButtonLabel: "Open",
  removeBudgetConfirmationTitle: "Remove Budget",
  removeFilterButtonLabel: "Remove filter",
  removeRealizedSavingsConfirmationTitle: "Delete Realized Savings Widget",
  removeReportConfirmationMessage: "Are you sure you want to do this?",
  removeReportConfirmationTitle: "Remove Report",
  removeSavingOppConfirmationTitle: "Remove Saving Opportunity",
  removeSavingOppFilterConfirmationTitle: "Delete Saving Opportunity",
  reportBulkEditAddTagsTooltipMessage: "Add selected tags to all selected reports",
  reportBulkEditRemoveTagsTooltipMessage: "Remove selected tags from all selected reports",
  reportBulkEditSelectDisabledPlaceholder: "Select add or remove to enable",
  reportBulkEditSelectPlaceholder: "Select tags",
  reportDescriptionPlaceholder: "Description",
  reportFormModalTagsTitle: "Add Tags",
  reportListCancelEditToolTipMessage: "Cancel Edit",
  reportListEditNTagsToolTipMessage: "Edit %NUMBER% Report Tags",
  reportNameCopyLabel: "copy",
  reportNameMaxCharactersCaption: "Max %LIMIT% characters. %USED%/%LIMIT%",
  reportNamePlaceholder: "Untitled Report",
  reportSubscriptionDayOfWeek: "Day of week",
  reportSubscriptionDayPlaceholder: "Select Day",
  reportSubscriptionEmailPlaceholder: "Select Email",
  reportSubscriptionFormDailyLabel: "Daily",
  reportSubscriptionFormFirstWeekDefaultLabel: "First",
  reportSubscriptionFormFrequencyLabel: "Frequency",
  reportSubscriptionFormHourOfDayLabel: "Hour of Day",
  reportSubscriptionFormMondayDefaultLabel: "Monday",
  reportSubscriptionFormMonthlyLabel: "Monthly",
  reportSubscriptionFormSubscribersLabel: "Subscribers",
  reportSubscriptionFormTimezoneCaption: "Time selected would be %TIME%",
  reportSubscriptionFormWeeklyLabel: "Weekly",
  reportSubscriptionFrequencyPlaceholder: "Select Frequency",
  reportSubscriptionListCreatedAtTableHeader: "Created At",
  reportSubscriptionListCreatedByTableHeader: "Created By",
  reportSubscriptionListFrequencyTableHeader: "Frequency",
  reportSubscriptionListSubscriberCountHeader: "# of Subscribed",
  reportSubscriptionModalCreateButtonLabel: "Create Subscription",
  reportSubscriptionSelectedTime: "Selected Time:",
  reportSubscriptionSendTime: "Send Time:",
  reportSubscriptionSubscribeButtonLabel: "Subscribe",
  reportSubscriptionTimezoneMessage: "All emailed reports are sent using UTC timezone",
  reportSubscriptionWeekOfMonth: "Week of month",
  reportSubscriptionWeekPlaceholder: "Select Week",
  reportTimeLastUpdatedCaption: "Updated %distance% ago",
  reverse: "Reverse",
  runReportButtonDisabledMessage: "You must select a measure to run a report",
  runReportButtonLabel: "Run Report",
  runReportLoadingButtonLabel: "Running...",
  saveAsCopyButtonLabel: "Save As Copy",
  saveAsSystemButtonLabel: "Save as System Report",
  saveAsReportButtonLabel: "Save As Copy",
  saveSystemDashboardButtonLabel: "Save System Dashboard",
  savingOpportunityDefaultCategoryInputLable: "Select Category...",
  savingOpportunityDefaultProviderInputLable: "Select Cloud Provider...",
  savingOpportunityDefaultServiceTypeInputLable: "Select Service Type...",
  savingOpportunityFormCategoryInputLable: "Category (optional)",
  savingOpportunityFormGlobalInputLable: "Service Type",
  savingOpportunityFormNameInputLable: "Name",
  savingOpportunityFormProviderInputLable: "Cloud Provider (optional) ",
  savingOpportunityFormSavingsTypeInputLable: "Type",
  savingOpportunityFormSavingsTypeRateInputLable: "Rate",
  savingOpportunityFormSavingsTypeRateUsageInputLable: "Rate & Usage",
  savingOpportunityFormSavingsTypeUsageInputLable: "Usage",
  savingOpportunityFormSelectInputAll: "All",
  savingOpportunityFormServiceTypeInputLable: "Service Type (optional)",
  savingOpportunityWidgetCUDTotalSavingsTitle: "Est. Rate Savings",
  savingOpportunityWidgetTotalSavingsTitle: "Est. Usage Savings",
  savingOpportunityWidgetTotalSavingsWithCudsTitle: "Est. Rate & Usage Savings",
  searchBudgetsDashboardsPlaceholder: "Search",
  searchReportsDashboardsPlaceholder: "Search",
  selectMetricAggregateMaxLabel: "MAX",
  selectMetricAggregateMeanLabel: "MEAN",
  selectMetricAggregateMinLabel: "MIN",
  selectMetricAggregatePlaceholder: "Select Aggregate",
  selectMetricAggregateSumLabel: "SUM",
  selectMetricPlaceholder: "Select Measure",
  selectOptionsBigQueryCapacity: "BigQuery Capacity",
  selectOptionsBigQueryTimeline: "BigQuery Timeline",
  selectOptionsBigQueryUsage: "BigQuery Usage",
  selectOptionsBilling: "Billing",
  SelectOptionsCarbonEmissions: "Carbon Impact",
  selectOptionsCloudSQLCost: "Cloud SQL Cost",
  selectOptionsCloudSQLUsage: "Cloud SQL Usage",
  selectOptionsCloudSQLUsageDaily: "Cloud SQL Usage Daily",
  selectOptionsDetailedBilling: "Resource Level Billing",
  selectOptionsEC2: "Elastic Compute Cloud",
  selectOptionsEKSCost: "EKS Cost",
  selectOptionsEKSUsage: "EKS Usage",
  selectOptionsFocusBilling: "FOCUS Billing (Beta)",
  selectOptionsGCE: "Google Compute Engine",
  selectOptionsGKEContainer: "GKE Container Cost and Usage",
  selectOptionsGKENode: "GKE Node Cost and Usage",
  selectOptionsKubernetesContainer: "Kubernetes Container",
  selectOptionsKubernetesNode: "Kubernetes Node",
  selectOptionsSnowflakeWarehouseUsage: "Snowflake Warehouses",
  selectSourceTitle: "Data Source",
  selectTooltipText: "Please create a new report to select a source",
  selectValueLabel: "Select Value...",
  showCustomMetricsButtonLabel: "Show Custom Metrics",
  showOther: 'Show "Other"',
  sidePanelTabLabelParams: "Parameters",
  sidePanelTabLabelUnitEcon: "Unit Economics",
  SUCCESS_CUSTOM_METRIC_CREATED_message: "The custom metric has been successfully created.",
  SUCCESS_CUSTOM_METRIC_DELETED_message: "The custom metric has been successfully deleted.",
  SUCCESS_CUSTOM_METRIC_UPDATED_message: "The custom metric has been successfully updated.",
  SUCCESS_DASHBOARD_DELETED_message: "The dashboard has been successfully deleted.",
  SUCCESS_DASHBOARD_SUBSCRIPTION_CREATED_message: "The dashboard subscription has been successfully created.",
  SUCCESS_DASHBOARD_SUBSCRIPTION_DELETED_message: "The dashboard subscription has been successfully deleted.",
  SUCCESS_DASHBOARD_SUBSCRIPTION_UPDATED_message: "The dashboard subscription has been successfully updated.",
  SUCCESS_REPORT_CREATED_message: "The report has been successfully created.",
  SUCCESS_REPORT_DELETED_message: "The report has been successfully deleted.",
  SUCCESS_REPORT_SUBSCRIPTION_CREATED_message: "The report subscription has been successfully created.",
  SUCCESS_REPORT_SUBSCRIPTION_DELETED_message: "The report subscription has been successfully deleted.",
  SUCCESS_REPORT_SUBSCRIPTION_UPDATED_message: "The report subscription has been successfully updated.",
  SUCCESS_REPORT_UPDATED_message: "The report has been successfully updated.",
  SUCCESS_SAVING_OPP_CREATED_message: "The saving opportunity has been successfully created.",
  SUCCESS_SAVING_OPP_DELETED_message: "The saving opportunity has been successfully deleted.",
  SUCCESS_SAVING_OPP_UPDATED_message: "The saving opportunity has been successfully updated.",
  successDistributingSystemDashboardMessage: "The dashboard has successfully been distributed to all Tenants.",
  successDistributingSystemReportMessage: "The report has successfully been distributed to all Tenants.",
  successRemovingSystemDashboardMessage: "The dashboard has successfully been removed from all Tenants.",
  successRemovingSystemReportMessage: "The report has successfully been removed from all Tenants.",
  successUpdatingPreferredDashboardMessage: "Your preferred dashboard was successfully updated.",
  successUpdatingSystemDashboardMessage: "The dashboard and all it's copies were successfully updated.",
  systemDashboardSaveLabel: "Save as System Dashboard",
  systemDashboardPrompt: "This will create a new system level dashboard. Are you sure you want to continue?",
  systemReportConfirm: "Confirm",
  systemReportPrompt: "This will create a new system level report. Are you sure you want to continue?",
  systemSavingOppAction: "Save to System Saving Opportunity",
  systemSavingOpportunityPrompt: "System saving opportunity are shared with all tenants. Are you sure you want to continue?",
  tableHeaderCreatedAt: "Created On",
  tableHeaderCreatedBy: "Created By",
  tableHeaderEditTags: "Edit Multiple Tags",
  tableHeaderIsDistributed: "Is Distributed",
  tableHeaderName: "Name",
  tableHeaderNumberOfReports: "Number of Reports",
  tableHeaderOwner: "Owner",
  tableHeaderStatus: "Status",
  tableHeaderTags: "Tags",
  tableHeaderTimeLastModified: "Time Last Modified",
  tableHeaderVisibility: "Visibility",
  tableScopeGlobal: "Ternary",
  tableScopePrivate: "Private",
  tableScopeShared: "Shared",
  tagsEditSectionTitle: "Tags",
  textInputLabel: "Search",
  unitEconomicsAliasAsCaption: "as",
  unitEconomicsAliasPlaceholder: "Enter custom name (optional)",
  unitEconomicsFormulaPlaceHolder: "Formula",
  unitEconomicsHeader: "Unit Economics",
  unitEconomicsHeaderComparisonDisabled: "Unit Econ is disabled beause you have comparison selected.",
  unitEconomicsHeaderDimensionsDisabled: "Unit Econ is disabled because you have groupings selected.",
  updateGlobalDashboardConfirmationMessage: "Are you sure you want to update this dashboard? Updates will be applied on ALL tenants.",
  widgetTitleRealizedSavingsLast30Days: "30-Day Realized Savings",
  widgetTitleRealizedSavings: "Realized Savings",
  widgetTitleSavingsOpportunities: "Savings Opportunities",
  xAxisHeader: "X Axis",
  xAxisKeyOptionDefaultTime: "Time Series",
};
