import copyText from "@/features/admin/copyText";
import { AzureIntegrationType } from "@ternary/api-lib/constants/enums";
import { FormData } from "./types";

export const AZURE_INTEGRATION_TYPES = [
  {
    label: copyText.azureCloudFormTypeLabel_APNA,
    value: AzureIntegrationType.APNA,
  },
  {
    label: copyText.azureCloudFormTypeLabel_AUTO,
    value: AzureIntegrationType.AUTO,
  },
  {
    label: copyText.azureCloudFormTypeLabel_EA,
    value: AzureIntegrationType.EA,
  },
  {
    label: copyText.azureCloudFormTypeLabel_EA_LEGACY,
    value: AzureIntegrationType.EA_LEGACY,
  },
  {
    label: copyText.azureCloudFormTypeLabel_MCA,
    value: AzureIntegrationType.MCA,
  },
  {
    label: copyText.azureCloudFormTypeLabel_MPA,
    value: AzureIntegrationType.MPA,
  },
  {
    label: copyText.azureCloudFormTypeLabel_MPAv2,
    value: AzureIntegrationType.MPAv2,
  },
];

export const AZURE_INTEGRATION_BILLING_TYPES = [
  {
    label: copyText.azureExportTypeActualsLabel,
    value: "ACTUALS",
  },
  {
    label: copyText.azureExportTypeAmortizedLabel,
    value: "AMORTIZED",
  },
];

export const defaultValues: FormData = {
  appID: "",
  billingExports: [],
  billingConfig: {
    dateColumn: "",
    endDate: "",
    exportType: "",
    serviceURL: "",
    startDate: "",
    storageContainer: "",
    storagePrefix: "",
    strictStoragePrefix: false,
  },
  directoryID: "",
  name: "",
  type: AzureIntegrationType.AUTO,
};
