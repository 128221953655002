import { useTheme } from "@emotion/react";
import { faCloud, faFolder, faTimes } from "@fortawesome/free-solid-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React, { MouseEvent } from "react";
import { FormField } from "../../../../../ui-lib/components/Form";
import Select from "../../../../../ui-lib/components/Select";
import TextInput from "../../../../../ui-lib/components/TextInput";
import copyText from "../../copyText";
import {
  bigQueryLocations,
  BigQueryScope,
  validateProjectID,
} from "./constants";
import { FormProps } from "./types";

const validators = {
  projectID: ({ value }: { value: string }) => {
    if (value.length === 0) return;

    return validateProjectID(value);
  },
};

// These get formatted slightly differently for BQ config.
const regionOptions = bigQueryLocations.map((location) => ({
  label: `region-${location.toLowerCase()}`,
  value: `region-${location.toLowerCase()}`,
}));

export function GcpIntegrationFormBigQuery(props: FormProps) {
  const theme = useTheme();

  const { form } = props;

  return (
    <form.Field
      name="configsBigQuery"
      mode="array"
      children={(configsField) => (
        <Flex padding={theme.space_xxs}>
          <Box width="55%">
            <form.Field
              name="projectIDBigQuery"
              validators={{ onChange: validators.projectID }}
              children={(field) => {
                const shouldShowError =
                  field.state.value.length > 0 &&
                  field.state.meta.isTouched &&
                  field.state.meta.errors.length > 0;

                return (
                  <FormField
                    input={TextInput}
                    errorCaption={
                      shouldShowError
                        ? field.state.meta.errors.join(", ")
                        : undefined
                    }
                    label={copyText.gcpFormBigQueryProjectIDInputLabel}
                    value={field.state.value}
                    variant={shouldShowError ? "danger" : undefined}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                  />
                );
              }}
            />
            <form.Field
              name="regionsBigQuery"
              children={(field) => {
                return (
                  <FormField label={copyText.gcpFormBigQueryUsageRegions}>
                    <Select
                      isMulti
                      isSearchable
                      options={regionOptions}
                      value={field.state.value.map((value) => ({
                        label: value,
                        value,
                      }))}
                      onChange={(options) =>
                        field.handleChange(
                          options.map((option) => option.value)
                        )
                      }
                    />
                  </FormField>
                );
              }}
            />
            <form.Field
              name="scopeBigQuery"
              children={(field) => {
                return (
                  <FormField label={copyText.gcpFormBigQueryScope}>
                    <Flex>
                      <Flex alignItems="center" marginRight={"1rem"}>
                        <input
                          checked={
                            field.state.value === BigQueryScope.JOBS_BY_PROJECT
                          }
                          type="radio"
                          value={BigQueryScope.JOBS_BY_PROJECT}
                          onChange={(e) => field.handleChange(e.target.value)}
                        />
                        <Text>{copyText.gcpFormBigQueryScopeProjectLabel}</Text>
                      </Flex>
                      <Flex alignItems="center" marginRight={"1rem"}>
                        <input
                          checked={
                            field.state.value === BigQueryScope.JOBS_BY_FOLDER
                          }
                          type="radio"
                          value={BigQueryScope.JOBS_BY_FOLDER}
                          onChange={(e) => field.handleChange(e.target.value)}
                        />
                        <Text>{copyText.gcpFormBigQueryScopeFolderLabel}</Text>
                      </Flex>
                    </Flex>
                  </FormField>
                );
              }}
            />
            <form.Subscribe
              children={(state) => {
                function handleAddConfig(event: MouseEvent) {
                  event.preventDefault();
                  event.stopPropagation();

                  // Add completed config to array.
                  configsField.pushValue({
                    projectID: state.values.projectIDBigQuery,
                    regions: state.values.regionsBigQuery,
                    scope: state.values.scopeBigQuery,
                  });

                  // Reset form.
                  form.setFieldValue("projectIDBigQuery", "");
                  form.setFieldValue("regionsBigQuery", []);
                  form.setFieldValue(
                    "scopeBigQuery",
                    BigQueryScope.JOBS_BY_PROJECT
                  );
                }

                const disabled =
                  state.values.projectIDBigQuery.length === 0 ||
                  state.values.regionsBigQuery.length === 0;

                return (
                  <Button
                    disabled={disabled}
                    size="small"
                    type="button"
                    onClick={handleAddConfig}
                  >
                    {copyText.addBigQueryConfigurationButtonLabel}
                  </Button>
                );
              }}
            />
          </Box>
          <Flex
            direction="column"
            paddingHorizontal={theme.space_xl}
            width="45%"
          >
            <Text>{copyText.gcpFormBigQueryConfigurationsListLabel}</Text>
            {configsField.state.value.map((config, index) => (
              <Flex
                key={index}
                alignItems="center"
                justifyContent="space-between"
                marginBottom={theme.space_xxs}
                width="100%"
              >
                <Flex alignItems="center">
                  <Flex justifyContent="center" minWidth={20}>
                    <Icon
                      color={theme.text_color}
                      icon={
                        config.scope === BigQueryScope.JOBS_BY_PROJECT
                          ? faCloud
                          : faFolder
                      }
                    />
                  </Flex>
                  <Text marginLeft={theme.space_xs}>
                    {config.projectID} ({config.regions.join(", ")})
                  </Text>
                </Flex>
                <Button
                  iconEnd={<Icon icon={faTimes} />}
                  secondary
                  size="tiny"
                  onClick={() => configsField.removeValue(index)}
                />
              </Flex>
            ))}
          </Flex>
        </Flex>
      )}
    />
  );
}
