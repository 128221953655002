import { FormData } from "./types";

export const defaultValues: FormData = {
  name: "",
  orgName: "",
  accounts: [],
  accountConfig: {
    id: null,
    name: "",
    role: "",
    warehouse: "",
    username: "",
    password: "",
  },
};
