import ConfirmationModal from "@/ui-lib/components/ConfirmationModal";
import { FormField } from "@/ui-lib/components/Form";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import { faEdit, faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { repeat, some } from "lodash";
import React, { MouseEvent, useEffect, useState } from "react";
import copyText from "../../copyText";
import { defaultValues as _defaultValues } from "./constants";
import { FormProps, SnowflakeIntegrationAccount } from "./types";

const validators = {
  name: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
  role: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
  warehouse: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
  username: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
  password: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
};

function AccountRow(props: {
  data: SnowflakeIntegrationAccount;
  onEdit: () => void;
  onRemove: () => void;
  disableActionButtons?: boolean;
}) {
  const { data, onEdit, onRemove, disableActionButtons } = props;
  const passwordPreview = data?.passwordPreview;
  const password =
    passwordPreview === data.password
      ? showPasswordPreview(passwordPreview)
      : data.password;

  const theme = useTheme();
  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex alignItems="center">
        <Flex justifyContent="center" minWidth={20}>
          <Icon color={theme.text_color} icon={faUser} />
        </Flex>
        <Flex marginLeft={theme.space_xs} style={{ maxWidth: "40vw" }}>
          <Text truncate fontSize={theme.fontSize_base}>
            {data.name}
          </Text>
          ,
          <Text truncate fontSize={theme.fontSize_base}>
            {data.role}
          </Text>
          ,
          <Text truncate fontSize={theme.fontSize_base}>
            {data.warehouse}
          </Text>
          ,
          <Text truncate fontSize={theme.fontSize_base}>
            {data.username}
          </Text>
          ,
          <Text truncate fontSize={theme.fontSize_base}>
            {password}
          </Text>
        </Flex>
      </Flex>
      <Box>
        <Button
          disabled={disableActionButtons}
          iconEnd={<Icon icon={faEdit} height={"24px"} />}
          marginRight={theme.space_xxs}
          secondary
          size="tiny"
          onClick={onEdit}
        />
        <Button
          disabled={disableActionButtons}
          iconEnd={<Icon icon={faTimes} height={"24px"} />}
          secondary
          size="tiny"
          onClick={onRemove}
        />
      </Box>
    </Flex>
  );
}

function AccountForm(props: FormProps) {
  const { form } = props;

  return (
    <Flex direction="column" width={"100%"}>
      <form.Field
        name={`accountConfig.name`}
        validators={{
          onChange: validators.name,
          onMount: validators.name,
          onChangeListenTo: ["accountConfig", "accounts"],
        }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              input={TextInput}
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              label={copyText.snowflakeFormAccountsNameInputLabel}
              required
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <form.Field
        name={`accountConfig.role`}
        validators={{
          onChange: validators.role,
          onMount: validators.role,
        }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              input={TextInput}
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              label={copyText.snowflakeFormAccountsRoleInputLabel}
              required
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <form.Field
        name={`accountConfig.warehouse`}
        validators={{
          onChange: validators.warehouse,
          onMount: validators.warehouse,
        }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              input={TextInput}
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              label={copyText.snowflakeFormAccountsWarehouseInputLabel}
              required
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <form.Field
        name={`accountConfig.username`}
        validators={{
          onChange: validators.username,
          onMount: validators.username,
        }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              input={TextInput}
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              label={copyText.snowflakeFormAccountsUsernameInputLabel}
              required
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <form.Field
        name={`accountConfig.password`}
        validators={{
          onChange: validators.password,
          onMount: validators.password,
        }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              input={TextInput}
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              label={copyText.snowflakeFormAccountsPasswordInputLabel}
              required
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
              marginBottom={0}
              onKeyDown={(event) => {
                // Prevent jumping from the password field to the account row edit.
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              }}
            />
          );
        }}
      />
    </Flex>
  );
}

export function SnowflakeIntegrationFormAccount(props: FormProps) {
  const theme = useTheme();
  const { form } = props;

  const accountKeys = Object.keys(_defaultValues.accountConfig).filter(
    (e) => e !== "id"
  ) as Array<keyof SnowflakeIntegrationAccount>;

  //
  // State
  //

  const [editRowIdx, setEditRowIdx] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  //
  // Side Effects
  //

  useEffect(() => {
    return () => {
      // Reset account form on unmount for the submit button.
      setFieldErrors(false);
    };
  }, []);

  //
  // Interaction Handlers
  //

  function handleEditRow() {
    if (editRowIdx === null) {
      return;
    }

    const value = form.getFieldValue("accounts")[editRowIdx];

    form.setFieldValue("accountConfig", value);
    setFieldErrors(false);
    if (value.passwordPreview) {
      form.setFieldValue("accountConfig.password", "");
      // User has to re-enter the password if they want to save the changes.
      setPasswordFieldError();
    }
  }

  function setFieldErrors(error: boolean) {
    for (const key of accountKeys) {
      form.setFieldMeta(`accountConfig.${key}`, {
        errors: error ? [copyText.errorInputFieldRequired] : [],
        errorMap: error ? { onMount: copyText.errorInputFieldRequired } : {},
        isBlurred: false,
        isDirty: false,
        isPristine: true,
        isTouched: false,
        isValidating: false,
      });
    }
  }

  function setPasswordFieldError() {
    form.setFieldMeta(`accountConfig.password`, {
      errors: [copyText.errorInputFieldRequired],
      errorMap: { onMount: copyText.errorInputFieldRequired },
      isBlurred: false,
      isDirty: false,
      isPristine: true,
      isTouched: false,
      isValidating: false,
    });
  }

  return (
    <Box minWidth={"100%"} padding={theme.space_xxs}>
      <form.Field mode="array" name="accounts">
        {(configsField) => (
          <Flex direction="column" gap={theme.space_md} width="100%">
            {isOpen && (
              <ConfirmationModal
                message={copyText.snowflakeFormEditModalMessage}
                title={copyText.snowflakeFormEditConfirmationTitle}
                variant="danger"
                onCancel={() => {
                  setEditRowIdx(null);
                  setIsOpen(false);
                }}
                onConfirm={() => {
                  handleEditRow();
                  setIsOpen(false);
                }}
              />
            )}
            <AccountForm form={form} />
            <form.Subscribe
              children={(state) => {
                function handleAddConfig(event: MouseEvent) {
                  event.preventDefault();
                  event.stopPropagation();

                  // Add completed config to array.
                  configsField.pushValue({
                    ...state.values.accountConfig,
                  });

                  setEditRowIdx(null);
                  setFieldErrors(true);
                  form.setFieldValue(
                    "accountConfig",
                    _defaultValues.accountConfig
                  );
                }

                function handleEditConfig(event: MouseEvent) {
                  event.preventDefault();
                  event.stopPropagation();

                  if (editRowIdx !== null) {
                    // Add completed config to array.
                    configsField.replaceValue(editRowIdx, {
                      ...state.values.accountConfig,
                    });

                    setFieldErrors(true);
                    form.setFieldValue(
                      "accountConfig",
                      _defaultValues.accountConfig
                    );

                    setEditRowIdx(null);
                  }
                }

                function handleCancelConfig(event: MouseEvent) {
                  event.preventDefault();
                  event.stopPropagation();

                  setFieldErrors(true);
                  form.setFieldValue(
                    "accountConfig",
                    _defaultValues.accountConfig
                  );

                  setEditRowIdx(null);
                }

                const disabled = accountKeys.some((key) => {
                  const fieldMeta = form.getFieldMeta(`accountConfig.${key}`);
                  return fieldMeta?.errors && fieldMeta?.errors?.length > 0;
                });

                if (editRowIdx !== null) {
                  return (
                    <Flex>
                      <Button
                        secondary
                        size="small"
                        type="button"
                        onClick={handleCancelConfig}
                      >
                        {copyText.actionCancel}
                      </Button>
                      <Button
                        disabled={disabled}
                        primary
                        size="small"
                        type="button"
                        onClick={handleEditConfig}
                      >
                        {copyText.actionSave}
                      </Button>
                    </Flex>
                  );
                }

                return (
                  <div>
                    <Button
                      disabled={disabled}
                      primary
                      size="small"
                      type="button"
                      onClick={handleAddConfig}
                    >
                      {copyText.snowflakeFormAccountsAddButtonLabel}
                    </Button>
                  </div>
                );
              }}
            />
            <Text fontSize={theme.h4_fontSize} fontWeight={theme.h4_fontWeight}>
              {copyText.snowflakeFormAccountsListLabel.replace(
                "%VALUE%",
                `${configsField.state.value.length}`
              )}
            </Text>
            <Flex
              direction="column"
              gap={theme.space_xs}
              paddingVertical={theme.space_xxs}
            >
              {configsField.state.value.map((vl, index) => {
                return (
                  <AccountRow
                    key={`account-row-${index}`}
                    data={vl}
                    disableActionButtons={index === editRowIdx}
                    onEdit={() => {
                      setEditRowIdx(index);

                      const currTypedAccount =
                        form.getFieldValue("accountConfig");
                      const showModal = some(
                        currTypedAccount,
                        (value) => !!value
                      );

                      if (showModal) {
                        setIsOpen(true);
                      } else {
                        form.setFieldValue("accountConfig", vl);
                        setFieldErrors(false);
                        if (vl.passwordPreview) {
                          form.setFieldValue("accountConfig.password", "");
                          setPasswordFieldError();
                        }
                      }
                    }}
                    onRemove={() => configsField.removeValue(index)}
                  />
                );
              })}
            </Flex>
          </Flex>
        )}
      </form.Field>
    </Box>
  );
}

function showPasswordPreview(secret: string): string {
  const splitSecret = secret.split(":");

  const count = Number(splitSecret[1]);
  const asterisks = repeat("*", count > 16 ? 16 : count);

  return splitSecret[0].concat(asterisks, splitSecret[2]);
}
