import { useTheme } from "@emotion/react";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React from "react";
import { FormField } from "../../../../../ui-lib/components/Form";
import Switch from "../../../../../ui-lib/components/Switch";
import TextInput from "../../../../../ui-lib/components/TextInput";
import copyText from "../../copyText";
import { BILLING_ACCOUNT_ID_REGEX, RESOURCE_ID_REGEX } from "./constants";
import { FormProps } from "./types";

const validators = {
  billingAccountID: ({ value }: { value: string }) => {
    return !BILLING_ACCOUNT_ID_REGEX.test(value)
      ? copyText.gcpFormGeneralBillingInputError
      : undefined;
  },
  name: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
  rootResourceID: ({ value }: { value: string }) => {
    return !RESOURCE_ID_REGEX.test(value)
      ? copyText.gcpFormGeneralRootResourceIDInputError
      : undefined;
  },
};

export function GcpIntegrationFormGeneral(props: FormProps) {
  const theme = useTheme();

  const { form } = props;

  return (
    <Box padding={theme.space_xxs}>
      <form.Field
        name="name"
        validators={{ onChange: validators.name, onMount: validators.name }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              input={TextInput}
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              label={copyText.gcpFormGeneralNameInputLabel}
              required
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <form.Field
        name="rootResourceID"
        validators={{
          onChange: validators.rootResourceID,
          onMount: validators.rootResourceID,
        }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              input={TextInput}
              caption={copyText.gcpFormGeneralRootResourceIDInputCaption}
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              label={copyText.gcpFormGeneralRootResourceIDInputLabel}
              placeholder={
                copyText.gcpFormGeneralRootResourceIDInputPlaceholder
              }
              required
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <form.Field
        name="billingAccountID"
        validators={{
          onChange: validators.billingAccountID,
          onMount: validators.billingAccountID,
        }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              input={TextInput}
              caption={copyText.gcpFormGeneralBillingAccountIDInputCaption}
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              label={copyText.gcpFormGeneralBillingAccountIDInputLabel}
              required
              placeholder={
                copyText.gcpFormGeneralBillingAccountIDInputPlaceholder
              }
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <form.Field
        name="enableCommitmentSharing"
        defaultValue={false}
        children={(field) => {
          return (
            <Flex alignItems="center" justifyContent="space-between">
              <Text>{copyText.gcpFormGeneralCudSharingEnabledLabel}</Text>
              <Switch
                checked={field.state.value}
                onChange={(checked) => field.handleChange(checked)}
              />
            </Flex>
          );
        }}
      />
    </Box>
  );
}
