import { useTheme } from "@emotion/react";
import {
  faArrowUpRightFromSquare,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import { ExpandableCell } from "@ternary/api-lib/ui-lib/charts/Table/ExpandableCell";
import { MeasureCell } from "@ternary/api-lib/ui-lib/charts/Table/MeasureCell";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Link from "@ternary/api-lib/ui-lib/components/Link";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { formatDate } from "@ternary/api-lib/ui-lib/utils/dates";
import Text from "@ternary/web-ui-lib/components/Text";
import { flatten, groupBy, map, uniqBy } from "lodash";
import React, { useMemo } from "react";
import GroupingTag from "../../../components/GroupingTag";
import GroupingTooltipContent from "../../../components/GroupingTooltip";
import paths from "../../../constants/paths";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import { CostAlertDimension } from "../../alert-tracking/types";
import { getStringifiedDelta } from "../../alert-tracking/utils";
import copyText from "../copyText";

type CostAlertData = {
  id: string;
  tenantDocID: string;
  createdAt: string;
  actualValue: number;
  alertRuleID: string;
  eventTime: string;
  expectedValue: { lowerBound: number; upperBound: number };
  tenantName: string;
  dimensions: CostAlertDimension[];
};

type CostAlert = CostAlertData & {
  delta: number;
};

type AggregatedCostAlert = CostAlertData & {
  delta: {
    minLowerBound: number;
    maxUpperBound: number;
  };
};

type RowData = (CostAlert | AggregatedCostAlert) & {
  hasTenantAccess: boolean;
};

type TableData = RowData & {
  subRows?: RowData[];
};

const columnHelper = createColumnHelper<TableData>();

interface Props {
  costAlerts: CostAlert[];
  isLoading: boolean;
  onInteraction: (interaction: MspCostAlertTable.Interaction) => void;
}

export function MspCostAlertTable(props: Props) {
  const authenticatedUser = useAuthenticatedUser();
  const theme = useTheme();

  const columns = useMemo(
    () => [
      columnHelper.accessor("tenantName", {
        cell: (cell) => {
          return (
            <ExpandableCell row={cell.row}>{cell.getValue()}</ExpandableCell>
          );
        },
        header: copyText.tableHeaderTenantName,
        size: 200,
      }),
      columnHelper.accessor("createdAt", {
        cell: (cell) => {
          return formatDate(new Date(cell.getValue()), "yyyy-MM-dd");
        },
        header: () => (
          <Tooltip content={copyText.tableHeaderAlertedAtTooltip} width="300px">
            {copyText.tableHeaderAlertedAt} <Icon icon={faInfoCircle} />
          </Tooltip>
        ),
        size: 110,
      }),
      columnHelper.accessor("delta", {
        cell: (cell) => {
          const delta = cell.row.original.delta;
          if (
            typeof delta !== "number" &&
            delta?.maxUpperBound &&
            delta?.minLowerBound
          ) {
            return (
              <Flex gap={theme.space_xxs} alignItems="center">
                <Text>
                  {delta.minLowerBound < 0 ? "- " : "+ "}
                  <MeasureCell
                    applyMaxCharacters
                    columnID={cell.column.id}
                    currencyCode="USD"
                    unit={UnitType.CURRENCY}
                    value={Math.abs(delta.minLowerBound)}
                    currentSize={50}
                  />
                </Text>
                {copyText.anomaliesDeltaColumnSeparator}
                <Text>
                  {delta.maxUpperBound < 0 ? "- " : "+ "}
                  <MeasureCell
                    applyMaxCharacters
                    columnID={cell.column.id}
                    currencyCode="USD"
                    unit={UnitType.CURRENCY}
                    value={Math.abs(delta.maxUpperBound)}
                    currentSize={50}
                  />
                </Text>
              </Flex>
            );
          }

          return getStringifiedDelta(cell.row.original.actualValue, {
            lowerBound: cell.row.original.expectedValue.lowerBound,
            upperBound: cell.row.original.expectedValue.upperBound,
          });
        },
        sortingFn: (a, b) => {
          const deltaA =
            typeof a.original.delta === "number"
              ? a.original.delta
              : Math.max(
                  Math.abs(a.original.delta.maxUpperBound),
                  Math.abs(a.original.delta.minLowerBound)
                );
          const deltaB =
            typeof b.original.delta === "number"
              ? b.original.delta
              : Math.max(
                  Math.abs(b.original.delta.maxUpperBound),
                  Math.abs(b.original.delta.minLowerBound)
                );

          return deltaA - deltaB;
        },
        header: copyText.tableHeaderDelta,
        size: 140,
      }),
      columnHelper.accessor("dimensions", {
        cell: ({ row }) => {
          const dimensionGrouping = row.original?.dimensions ?? [];
          if (dimensionGrouping.length > 0) {
            return (
              <Box minWidth={0}>
                <Tooltip
                  content={
                    <GroupingTooltipContent
                      dimensionGrouping={dimensionGrouping}
                    />
                  }
                  delayHide={250}
                  placement="top"
                >
                  <GroupingTag dimensionGrouping={dimensionGrouping} />
                </Tooltip>
              </Box>
            );
          }
          return "--";
        },
        header: copyText.tableHeaderGroupings,
        size: 145,
      }),
      columnHelper.display({
        id: "goToAnomaly",
        cell: (cell) => {
          if (cell.row.depth === 0 && cell.row.subRows.length > 0) {
            return null;
          }

          const tenantDocID = cell.row.original.tenantDocID;
          const alertID = cell.row.original.id;
          const hasAccess = cell.row.original.hasTenantAccess;

          const buttonElement = (
            <Button
              secondary
              size="tiny"
              iconStart={<Icon icon={faArrowUpRightFromSquare} />}
            >
              {copyText.goToAnomalyDetailButtonLabel}
            </Button>
          );

          return hasAccess ? (
            <Link
              target="_blank"
              to={{
                pathname: paths._alertTracking,
                search: `?tenant_id=${tenantDocID}&alertID=${alertID}`,
              }}
            >
              {buttonElement}
            </Link>
          ) : (
            <Box
              onClick={() =>
                props.onInteraction({
                  type: MspCostAlertTable.INTERACTION_LINK_CLICKED,
                  tenantDocID,
                })
              }
            >
              {buttonElement}
            </Box>
          );
        },
        size: 195,
        meta: { align: "right" },
      }),
      columnHelper.display({
        id: "investigate",
        cell: ({ cell }) => {
          if (cell.row.depth === 0 && cell.row.subRows.length > 0) {
            return null;
          }

          const tenantDocID = cell.row.original.tenantDocID;
          const alertID = cell.row.original.alertRuleID;
          const hasAccess = cell.row.original.hasTenantAccess;

          return (
            <Button
              secondary
              size="tiny"
              iconStart={<Icon icon={faArrowUpRightFromSquare} />}
              onClick={() => {
                props.onInteraction({
                  type: MspCostAlertTable.INTERACTION_INVESTIGATE_CLICKED,
                  alertID,
                  eventTime: cell.row.original.eventTime,
                  dimensions: cell.row.original.dimensions,
                  tenantDocID,
                  hasAccess,
                });
              }}
            >
              {copyText.goToReportButtonLabel}
            </Button>
          );
        },
        size: 190,
        meta: { align: "right" },
      }),
    ],
    [props.costAlerts]
  );

  const data = useMemo(
    () =>
      props.costAlerts.map((summary) => {
        const grant = authenticatedUser.grants.find(
          (grant) => grant.tenantDocID === summary.tenantDocID
        );

        return { ...summary, hasTenantAccess: !!grant };
      }),
    [authenticatedUser, props.costAlerts]
  );

  const aggregatedByTenant: TableData[] = useMemo(() => {
    const groupedByTenant = groupBy(data, "tenantDocID");
    return map(groupedByTenant, (alerts, tenantDocID) => {
      const subRows: RowData[] = [];
      let maxUpperBound = -Infinity;
      let minLowerBound = Infinity;
      for (const alert of alerts) {
        const actualValue = alert.actualValue;
        const delta =
          actualValue > alert.expectedValue.upperBound
            ? actualValue - alert.expectedValue.upperBound
            : (alert.expectedValue.lowerBound - actualValue) * -1;

        maxUpperBound = Math.max(maxUpperBound, delta);
        minLowerBound = Math.min(minLowerBound, delta);

        subRows.push({
          ...alert,
          delta,
        });
      }

      if (subRows.length === 1) {
        return subRows[0];
      }

      const alertCount = alerts.length;
      const alertIDs = alerts.map((alert) => alert.id);
      const createdAt = alerts
        .map((alert) => alert.createdAt)
        .sort((a, b) => {
          return new Date(b).getTime() - new Date(a).getTime();
        })[0];
      const dimensions = uniqBy(
        flatten(alerts.map((alert) => alert.dimensions)),
        (item) => item.key + item.value
      ).sort((a, b) => a.key.localeCompare(b.key));

      return {
        ...alerts[0],
        alertCount,
        alertIDs,
        createdAt,
        delta: {
          minLowerBound,
          maxUpperBound,
        },
        dimensions,
        subRows,
        tenantDocID,
      };
    });
  }, [data]);

  return (
    <Table
      columns={columns}
      data={aggregatedByTenant}
      expandable
      getSubRows={(row) => row?.subRows ?? []}
      initialState={{ sorting: [{ id: "delta", desc: true }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

MspCostAlertTable.INTERACTION_LINK_CLICKED =
  "MspChildCostAlertTable.INTERACTION_LINK_CLICKED" as const;
MspCostAlertTable.INTERACTION_INVESTIGATE_CLICKED =
  "MspChildCostAlertTable.INTERACTION_INVESTIGATE_CLICKED" as const;

interface InteractionLinkClicked {
  type: typeof MspCostAlertTable.INTERACTION_LINK_CLICKED;
  tenantDocID: string;
}
interface InteractionInvestigateClicked {
  type: typeof MspCostAlertTable.INTERACTION_INVESTIGATE_CLICKED;
  alertID: string;
  dimensions: CostAlertDimension[];
  eventTime: string;
  hasAccess: boolean;
  tenantDocID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MspCostAlertTable {
  export type Interaction =
    | InteractionLinkClicked
    | InteractionInvestigateClicked;
}

export default MspCostAlertTable;
