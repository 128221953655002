import { useTheme } from "@emotion/react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  FormOptions,
  ReactFormExtendedApi,
  useForm,
} from "@tanstack/react-form";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { Theme } from "@ternary/api-lib/ui-lib/theme/default";
import palette from "@ternary/api-lib/ui-lib/theme/palette";
import { isEqual } from "lodash";
import React, { FormEvent, ReactNode, useState } from "react";
import copyText from "../copyText";
import Form from "./Form";
import LoadingSpinner from "./LoadingSpinner";

// TODO: Put palette directly on theme object.
const stepperTheme = (baseTheme: Theme) => ({
  Step_background_color: palette.grey[60],
  Step_border_color: palette.grey[60],
  ...baseTheme,
});

export type Step<TFormData> = {
  component: (form: ReactFormExtendedApi<TFormData>) => ReactNode;
  isOptional?: boolean;
  label: string;
  requiredInputs?: string[];
};

interface Props<TFormData> extends FormOptions<TFormData> {
  isProcessing?: boolean;
  mode?: "create" | "update";
  steps: Step<TFormData>[];
}

export function VerticalStepperForm<TFormData>(props: Props<TFormData>) {
  const theme = stepperTheme(useTheme());

  const { steps, ...formOptions } = props;

  const form = useForm<TFormData>(formOptions);

  const [currentStep, setCurrentStep] = useState(0);

  function goToNextStep() {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  }

  function goToPreviousStep() {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.stopPropagation();
    form.handleSubmit();
  }

  function renderNextButton(step: Step<TFormData>) {
    return (
      <form.Subscribe
        children={(state) => {
          const disabled =
            step.requiredInputs &&
            step.requiredInputs.some((input) => {
              const inputValue = state.values[input];
              if (Array.isArray(inputValue)) {
                return isArrayFieldInvalid(inputValue, input, state);
              }
              return isFieldInvalid(inputValue, input, state);
            });

          return (
            <Button
              disabled={disabled}
              primary
              type="button"
              width={100}
              onClick={goToNextStep}
            >
              {copyText.nextButtonLabel}
            </Button>
          );
        }}
      />
    );
  }

  function renderSubmitButton() {
    return (
      <form.Subscribe
        children={(state) => {
          let disabled =
            !state.canSubmit || !state.isTouched || props.isProcessing;

          if (props.mode === "update") {
            const hasChanged = getHasChanged(
              state.values,
              form.options.defaultValues
            );

            disabled = disabled || !hasChanged;
          }

          return (
            <Button disabled={disabled} primary type="submit" width={100}>
              {props.isProcessing ? (
                <LoadingSpinner />
              ) : (
                copyText.submitButtonLabel
              )}
            </Button>
          );
        }}
      />
    );
  }

  return (
    <Form flex width="55%" onSubmit={handleSubmit}>
      <Box flex="1 0 0" overflowY="auto">
        {steps.map((step, index) => {
          return (
            <Box key={index} marginBottom={theme.space_sm}>
              <Flex alignItems="center" marginBottom={theme.space_sm}>
                <Flex
                  alignItems="center"
                  backgroundColor={
                    index <= currentStep
                      ? theme.primary_color_background
                      : theme.Step_background_color
                  }
                  borderRadius="50%"
                  height={32}
                  justifyContent="center"
                  marginRight={theme.space_md}
                  width={32}
                >
                  <Text color="white">
                    {currentStep > index ? (
                      <Icon icon={faCheck}></Icon>
                    ) : (
                      index + 1
                    )}
                  </Text>
                </Flex>
                <Box>
                  <Text fontSize={theme.fontSize_base}>{step.label}</Text>
                  {step.isOptional ? (
                    <Text
                      color={theme.text_color_secondary}
                      fontSize={theme.h6_fontSize}
                    >
                      {copyText.optionalStepCaption}
                    </Text>
                  ) : null}
                </Box>
              </Flex>
              <Flex>
                <Box
                  backgroundColor={theme.Step_border_color}
                  marginHorizontal={theme.space_md}
                  minHeight={index === steps.length - 1 ? 0 : 16}
                  width={1}
                />
                {index === currentStep ? (
                  <Box paddingHorizontal={theme.space_sm} width={"100%"}>
                    <Box>{step.component(form)}</Box>
                    <Flex
                      gap="16px"
                      marginBottom={theme.space_lg}
                      marginTop={theme.space_md}
                    >
                      {currentStep > 0 && (
                        <Button
                          secondary
                          type="button"
                          width={100}
                          onClick={goToPreviousStep}
                        >
                          {copyText.previousButtonLabel}
                        </Button>
                      )}
                      {currentStep < steps.length - 1
                        ? renderNextButton(step)
                        : renderSubmitButton()}
                    </Flex>
                  </Box>
                ) : null}
              </Flex>
            </Box>
          );
        })}
      </Box>
    </Form>
  );
}

function getHasChanged(values, defaultValues) {
  return Object.keys(values).some(
    (key) => !isEqual(values[key], defaultValues[key])
  );
}

function hasErrors(fieldMeta) {
  return fieldMeta && fieldMeta.errors && fieldMeta.errors.length > 0;
}

function isArrayFieldInvalid(inputValue, input, state) {
  return (
    inputValue.length === 0 ||
    inputValue.some((vl, index) => {
      return Object.keys(vl).some((key) => {
        const fieldMeta = state.fieldMeta[`${input}[${index}].${key}`];
        return hasErrors(fieldMeta);
      });
    })
  );
}

function isFieldInvalid(inputValue, input, state) {
  return (
    (inputValue && inputValue.length === 0) || hasErrors(state.fieldMeta[input])
  );
}
