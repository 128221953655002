import { IntegrationStatusTag } from "@/features/admin/components/IntegrationStatusTag";
import { IntegrationStatusVariant } from "@/features/admin/constants";
import { getIntegrationStatusColor } from "@/features/admin/utils";
import Checkbox from "@/ui-lib/components/Checkbox";
import Switch from "@/ui-lib/components/Switch";
import { useTheme } from "@emotion/react";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  faArrowUpRightFromSquare,
  faCloud,
  faInfoCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { createColumnHelper, ExpandedState } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { formatDistance } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import copyText from "../copyText";
import { MspSharedIntegrations } from "../types";

type TableData = {
  fsDocID?: string;
  includeBigQuery?: boolean;
  integrationId: string;
  isDisabled?: boolean;
  isSelected?: boolean;
  latestRefresh?: string | null;
  latestUpstream?: string | null;
  name: string;
  projectID: string;
  provider: string;
  refreshDifference?: number;
  statusVariant?: IntegrationStatusVariant;
  subAccountName: string;
  subRows?: TableData[];
};

const columnHelper = createColumnHelper<TableData>();

interface Props {
  displayActions?: boolean;
  isExpanded?: boolean;
  isProcessing: boolean;
  sharedIntegrations: MspSharedIntegrations[];
  showSwitch?: boolean;
  showStatus?: boolean;
  onInteraction?: (interaction: MspSharedIntegrationsTable.Interaction) => void;
}

function GroupedStatus(props: { status: IntegrationStatusVariant }) {
  const theme = useTheme();
  const statusTooltip =
    copyText[`mspSharedIntegrationsRefreshStatus_${props.status}`];
  const color = getIntegrationStatusColor(props.status, theme);

  return (
    <Flex
      justifyContent="center"
      style={{
        gap: theme.space_xs,
      }}
      alignItems="center"
    >
      <Tooltip content={statusTooltip} width="200px">
        <IntegrationStatusTag variant={props.status}>
          <Icon icon={faCloud} color={color} size="1x" />
        </IntegrationStatusTag>
      </Tooltip>
    </Flex>
  );
}

function MspSharedIntegrationsTable(props: Props): JSX.Element {
  const theme = useTheme();
  const [expandState, setExpandState] = useState<ExpandedState>({});

  useEffect(() => {
    if (props.isExpanded === undefined) {
      return;
    }
    setExpandState(props.isExpanded ? props.isExpanded : {});
  }, [props.isExpanded]);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "extendRow",
        cell: ({ row, getValue }) => {
          if (row.depth === 1 && props.displayActions) {
            return (
              <Checkbox
                disabled={row.original.isDisabled}
                checked={row.original.isSelected}
                onChange={(event) =>
                  props.onInteraction && !row.original.isDisabled
                    ? props.onInteraction({
                        type: MspSharedIntegrationsTable.INTERACTION_CHECKBOX_CLICKED,
                        integrationID: row.original.integrationId,
                        subAccountID: row.original.projectID,
                        isSelected: event.target.checked,
                      })
                    : {}
                }
              />
            );
          } else {
            return (
              <div>
                {row.getCanExpand() ? (
                  <Button
                    size="small"
                    iconStart={
                      row.getIsExpanded() ? (
                        <Icon color={theme.text_color} icon={faChevronDown} />
                      ) : (
                        <Icon color={theme.text_color} icon={faChevronRight} />
                      )
                    }
                    onClick={() => {
                      setExpandState((prevState) => {
                        if (prevState === true) {
                          return {
                            [row.id]: false,
                          };
                        }
                        return {
                          ...prevState,
                          [row.id]: !prevState[row.id],
                        };
                      });
                    }}
                  />
                ) : (
                  <Text>{getValue<boolean>()}</Text>
                )}
              </div>
            );
          }
        },
        meta: { align: "center" },
        size: 35,
      }),
      columnHelper.accessor("name", {
        header: copyText.mspSharedIntegrationsTableHeaderName,
      }),
      columnHelper.accessor("provider", {
        header: copyText.mspSharedIntegrationsTableHeaderProvider,
        size: 50,
      }),
      ...(props.showStatus
        ? [
            columnHelper.accessor("refreshDifference", {
              cell: (cell) => {
                const latestRefresh = cell.row.original.latestRefresh;
                const isExpandableRow =
                  cell.row.depth === 0 && cell.row.subRows.length > 0;

                if (!latestRefresh || latestRefresh.startsWith("00")) {
                  return "";
                }

                const now = new Date();
                const absoluteRefresh = new Date(latestRefresh);

                const refreshDifference = formatDistance(now, absoluteRefresh);
                const content = copyText.lastRefresh.replace(
                  "%VALUE%",
                  refreshDifference
                );

                if (isExpandableRow) {
                  return (
                    <Tooltip
                      content={copyText.groupedLastRefreshTooltip}
                      width="200px"
                    >
                      {content}
                    </Tooltip>
                  );
                }

                return content;
              },
              header: () => (
                <Tooltip
                  content={
                    copyText.mspSharedIntegrationsTableHeaderLastRefreshTooltip
                  }
                  width="300px"
                >
                  {copyText.mspSharedIntegrationsTableHeaderLastRefresh}{" "}
                  <Icon icon={faInfoCircle} />
                </Tooltip>
              ),
              size: 100,
            }),
          ]
        : []),
      ...(props.showStatus
        ? [
            columnHelper.accessor("statusVariant", {
              cell: (cell) => {
                const isFirstDepth = cell.row.depth === 0;
                if (isFirstDepth && cell.row.original.statusVariant) {
                  return (
                    <GroupedStatus status={cell.row.original.statusVariant} />
                  );
                }
              },
              header: () => (
                <Tooltip
                  content={
                    <Flex direction="column" style={{ gap: theme.space_xxs }}>
                      <Text align="left" color={theme.text_color_inverse}>
                        {copyText.tableHeaderStatusTooltip}
                      </Text>
                      {Object.values(IntegrationStatusVariant).map((status) => {
                        const color = getIntegrationStatusColor(status, theme);
                        const content =
                          copyText[`tableHeaderStatusTooltip_${status}`];
                        return (
                          <Text
                            align="left"
                            color={theme.text_color_inverse}
                            fontSize={theme.fontSize_small}
                            key={`status-${status}`}
                          >
                            <Icon icon={faCloud} color={color} /> {content}
                          </Text>
                        );
                      })}
                    </Flex>
                  }
                  width="300px"
                >
                  {copyText.tableHeaderStatus} <Icon icon={faInfoCircle} />
                </Tooltip>
              ),
            }),
          ]
        : []),
      columnHelper.accessor("subAccountName", {
        header: copyText.mspSharedIntegrationsTableHeaderSubAccountName,
        cell: ({ getValue }) => {
          return (
            <Text truncate>
              <Tooltip content={getValue()}>{getValue()}</Tooltip>
            </Text>
          );
        },
      }),
      ...(props.displayActions
        ? [
            columnHelper.accessor("isDisabled", {
              id: "Assignment",
              header: copyText.mspSharedIntegrationsTableHeaderAssignment,
              cell: ({ row }) => {
                const isAssigned = row.original.isDisabled;
                const buttonElement = (
                  <Button
                    secondary
                    size="tiny"
                    iconStart={<Icon icon={faArrowUpRightFromSquare} />}
                  >
                    {copyText.viewTenantSubaccountsButtonLabel}
                  </Button>
                );
                if (row.depth === 1) {
                  return isAssigned ? (
                    <Box
                      onClick={() =>
                        props.onInteraction
                          ? props.onInteraction({
                              type: MspSharedIntegrationsTable.INTERACTION_LINK_CLICKED,
                              integrationID: row.original.integrationId,
                              subAccountID: row.original.projectID ?? "",
                            })
                          : {}
                      }
                    >
                      {buttonElement}
                    </Box>
                  ) : (
                    <Flex
                      width={206}
                      height={theme.size_tiny}
                      padding={`0 ${theme.size_tiny}`}
                      borderRadius={theme.borderRadius_1}
                      backgroundColor={
                        row.original.isSelected
                          ? theme.primary_color_background
                          : theme.secondary_color_background
                      }
                      justifyContent="center"
                    >
                      {row.original.isSelected ? (
                        <Text bold color={theme.text_color_inverse}>
                          {copyText.integrationsSelectedLabel}
                        </Text>
                      ) : (
                        <Text bold>{copyText.unnassignedActivityCaption}</Text>
                      )}
                    </Flex>
                  );
                }
                return null;
              },

              meta: { align: "center" },
            }),
          ]
        : []),
      ...(props.showSwitch
        ? [
            columnHelper.display({
              id: "bigQuery",
              cell: ({ row }) => {
                if (row.original.provider === "GCP") {
                  return (
                    <Box>
                      <Flex
                        justifyContent="space-between"
                        marginBottom={theme.space_md}
                      >
                        <Flex>
                          <Text marginRight={theme.space_sm}>
                            {copyText.integrationsIncludeBigQueryLabel}
                          </Text>
                        </Flex>
                        <Switch
                          name="tenant-status"
                          checked={row.original.includeBigQuery ?? false}
                          onChange={(checked) =>
                            props.onInteraction
                              ? props.onInteraction({
                                  type: MspSharedIntegrationsTable.INTERACTION_SWIITCH_ON_CHANGE,
                                  integrationID: row.original.integrationId,
                                  includeBigQuery: checked,
                                })
                              : {}
                          }
                        />
                      </Flex>
                    </Box>
                  );
                }
              },
            }),
          ]
        : []),
    ],
    [props.sharedIntegrations, props.isProcessing]
  );

  const data = useMemo(
    () =>
      props.sharedIntegrations.map((integration) => {
        let subRows: TableData[] = [];

        if (integration.subAccounts) {
          subRows = integration.subAccounts
            .map((account): TableData => {
              return {
                fsDocID: account.fsDocID,
                integrationId: integration.id,
                isDisabled: account.isDisabled,
                isSelected: account.isSelected,
                name: "",
                projectID: account.projectId,
                provider: "",
                subAccountName: account.projectName ?? account.projectId,
              };
            })
            .sort((a) => (a.isDisabled ? 1 : -1));
        }
        return {
          includeBigQuery: integration.includeBigQuery,
          integrationId: integration.id,
          latestRefresh: integration.latestRefresh,
          latestUpstream: integration.latestRefresh,
          name: integration.name,
          projectID: "",
          provider: integration.provider,
          refreshDifference: integration.refreshDifference,
          statusVariant: integration.statusVariant,
          subAccountName: "",
          subRows,
        };
      }),
    [props.sharedIntegrations]
  );

  return (
    <Table
      columns={columns}
      data={data}
      expandable
      getSubRows={(row) => (row.subRows ? row.subRows : undefined)}
      initialState={{ sorting: [{ id: "provider", desc: false }] }}
      isLoading={props.isProcessing}
      sortable
      state={{ expanded: expandState }}
    />
  );
}

MspSharedIntegrationsTable.INTERACTION_CHECKBOX_CLICKED =
  "MspSharedIntegrationsTable.INTERACTION_CHECKBOX_CLICKED" as const;
MspSharedIntegrationsTable.INTERACTION_LINK_CLICKED =
  "MspChildCostAlertTable.INTERACTION_LINK_CLICKED" as const;
MspSharedIntegrationsTable.INTERACTION_SWIITCH_ON_CHANGE =
  "MspChildCostAlertTable.INTERACTION_SWIITCH_ON_CHANGE" as const;

type InteractionCheckboxClicked = {
  type: typeof MspSharedIntegrationsTable.INTERACTION_CHECKBOX_CLICKED;
  integrationID: string;
  subAccountID: string;
  isSelected: boolean;
};

interface InteractionLinkClicked {
  type: typeof MspSharedIntegrationsTable.INTERACTION_LINK_CLICKED;
  integrationID: string;
  subAccountID: string;
}

interface InteractionSwitchOnChnage {
  type: typeof MspSharedIntegrationsTable.INTERACTION_SWIITCH_ON_CHANGE;
  integrationID: string;
  includeBigQuery: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace MspSharedIntegrationsTable {
  export type Interaction =
    | InteractionCheckboxClicked
    | InteractionLinkClicked
    | InteractionSwitchOnChnage;
}

export default MspSharedIntegrationsTable;
