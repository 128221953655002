import paths from "@/constants/paths";
import queryKeys from "@/features/admin/hooks/queryKeys";
import useCreateSnowflakeIntegration from "@/features/admin/hooks/useCreateSnowflakeIntegration";
import useUpdateSnowflakeIntegration from "@/features/admin/hooks/useUpdateSnowflakeIntegration";
import useValidateDataIntegration from "@/features/admin/hooks/useValidateDataIntegration";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import { VerticalStepperForm } from "@/ui-lib/components/Stepper";
import { AlertType, postAlert } from "@/utils/alerts";
import { useTheme } from "@emotion/react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FormApi } from "@tanstack/react-form";
import { useQueryClient } from "@tanstack/react-query";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import { SnowflakeIntegrationEntity } from "@ternary/api-lib/core/types/SnowflakeIntegration";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { get, isEmpty, isEqual } from "lodash";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useActivityTracker } from "../../../../../context/ActivityTrackerProvider";
import { useNavigateWithSearchParams } from "../../../../../lib/react-router";
import copyText from "../../copyText";
import { useGetSnowflakeIntegrationByID } from "../../hooks/useGetSnowflakeIntegrationByID";
import { defaultValues as _defaultValues } from "./constants";
import { SnowflakeIntegrationFormAccount } from "./SnowflakeIntegrationFormAccount";
import { SnowflakeIntegrationFormGeneral } from "./SnowflakeIntegrationFormGeneral";
import { FormData, SnowflakeIntegrationAccount } from "./types";

export function SnowflakeIntegrationFormContainer() {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const location = useLocation();
  const navigate = useNavigateWithSearchParams();
  const queryClient = useQueryClient();
  const theme = useTheme();

  const { integrationID } = useParams();

  //
  // State
  //

  const snowflakeIntegrationLocation: SnowflakeIntegrationEntity | undefined =
    get(location.state, "integration");

  //
  // Queries
  //

  // If coming from a fresh session we need to fetch the integration from the server,
  // because it won't exist on location state.
  const {
    data: snowflakeIntegrationServer,
    isLoading: isLoadingSnowflakeIntegration,
  } = useGetSnowflakeIntegrationByID(integrationID ?? "", {
    enabled: !!integrationID && !snowflakeIntegrationLocation,
  });

  //
  // Mutations
  //

  const {
    isPending: isCreatingSnowflakeIntegration,
    mutate: createSnowflakeIntegration,
  } = useCreateSnowflakeIntegration({
    onError: () => {
      postAlert({
        message: copyText.errorCreatingSnowflakeIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (integrationID) => {
      validateDataIntegration({ integrationID });

      postAlert({
        message: copyText.successCreatingSnowflakeIntegrationMessage,
        type: AlertType.SUCCESS,
      });

      navigate(paths._admin, { searchParams: { tab: "clouds" } });
    },
  });

  const {
    isPending: isUpdatingSnowflakeIntegration,
    mutate: updateSnowflakeIntegration,
  } = useUpdateSnowflakeIntegration({
    onError: () => {
      postAlert({
        message: copyText.errorUpdatingSnowflakeIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (integrationID) => {
      validateDataIntegration({ integrationID });

      postAlert({
        message: copyText.successUpdatingSnowflakeIntegrationMessage,
        type: AlertType.SUCCESS,
      });

      navigate(paths._admin, { searchParams: { tab: "clouds" } });
    },
  });

  const { mutate: validateDataIntegration } = useValidateDataIntegration({
    onError: () => {
      postAlert({
        message: copyText.errorValidatingDataIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSettled: () => {
      return queryClient.invalidateQueries({
        queryKey: [queryKeys.dataIntegrations, authenticatedUser.tenant.id],
      });
    },
  });

  //
  // Computed Values
  //

  // If use integration on location state first, if we have it.
  const snowflakeIntegration =
    snowflakeIntegrationLocation ?? snowflakeIntegrationServer;

  //
  // Interaction Handlers
  //

  const defaultValues = snowflakeIntegration
    ? getValuesFromIntegration(snowflakeIntegration)
    : _defaultValues;

  function handleUpdateIntegration(
    integrationID: string,
    values: FormData,
    defaultValues: FormData
  ) {
    const changes = getChanges(values, defaultValues);

    activityTracker.captureAction(actions.CLICK_ADMIN_UPDATE_INTEGRATION, {
      type: CloudProviderType.SNOWFLAKE,
    });

    updateSnowflakeIntegration({
      integrationID,
      ...changes,
    });
  }

  function handleCreateIntegration(tenantID: string, value: FormData) {
    activityTracker.captureAction(actions.CLICK_ADMIN_CREATE_INTEGRATION, {
      type: CloudProviderType.SNOWFLAKE,
    });

    createSnowflakeIntegration({
      ...value,
      tenantID,
    });
  }

  async function handleSubmit({
    formApi,
    value,
  }: {
    formApi: FormApi<FormData>;
    value: FormData;
  }) {
    if (snowflakeIntegration) {
      const defaultValues = formApi.options.defaultValues;

      if (!defaultValues) return;

      handleUpdateIntegration(snowflakeIntegration.id, value, defaultValues);
    } else {
      handleCreateIntegration(authenticatedUser.tenant.id, value);
    }
  }

  const steps = [
    {
      component: (form) => <SnowflakeIntegrationFormGeneral form={form} />,
      label: "Basics and Billing data",
      requiredInputs: ["name", "orgName"],
    },
    {
      component: (form) => <SnowflakeIntegrationFormAccount form={form} />,
      label: "Accounts",
      requiredInputs: ["accounts"],
    },
    {
      component: () => (
        <Box width="50%">
          <Text appearance="h4">{copyText.createIntegrationHeader}</Text>
          <Text marginBottom={theme.space_lg}>
            {copyText.integrationLastInstructions}
          </Text>
        </Box>
      ),
      label: copyText.submitButtonLabel,
    },
  ];

  return (
    <Flex direction="column" height="80vh">
      <Button
        iconStart={<Icon icon={faArrowLeft} />}
        secondary
        width={200}
        onClick={() =>
          navigate(paths._admin, { searchParams: { tab: "clouds" } })
        }
      >
        {copyText.backToIntegrationsButtonLabel}
      </Button>
      <Text appearance="h3" marginVertical={theme.space_md}>
        {snowflakeIntegration
          ? snowflakeIntegration.name
          : copyText.snowflakeFormNewIntegrationHeader}
      </Text>
      <Flex height="100%" width="100%">
        {isLoadingSnowflakeIntegration ? (
          <EmptyPlaceholder loading width="50%" />
        ) : (
          <VerticalStepperForm
            defaultValues={defaultValues}
            isProcessing={
              isCreatingSnowflakeIntegration || isUpdatingSnowflakeIntegration
            }
            steps={steps}
            onSubmit={handleSubmit}
          />
        )}
      </Flex>
    </Flex>
  );
}

function getValuesFromIntegration(integration: SnowflakeIntegrationEntity) {
  return {
    // General
    name: integration.name ?? "",
    orgName: integration.orgName ?? "",
    accounts:
      integration?.accounts?.map((account) => ({
        id: account?.id ?? null,
        name: account.name,
        password: account.passwordPreview,
        passwordPreview: account.passwordPreview,
        role: account.role,
        username: account.username,
        warehouse: account.warehouse,
      })) ?? [],
    accountConfig: {
      id: null,
      name: "",
      password: "",
      passwordPreview: "",
      role: "",
      username: "",
      warehouse: "",
    },
  };
}

function getAccountChanges<ChangeObj extends Record<string, unknown>>(
  entity: Record<string, unknown> | null,
  object: ChangeObj
) {
  const changes: Partial<ChangeObj> = Object.entries(object).reduce(
    (accum, [key, value]) => ({
      ...accum,
      ...(value === undefined || isEqual(value, get(entity, key))
        ? {}
        : { [key]: value }),
    }),
    {}
  );
  if (isEmpty(changes)) return null;
  return changes;
}

type SnowflakeIntegrationAccountUpdate =
  Partial<SnowflakeIntegrationAccount> & { id: string | null };

function getChanges(value: FormData, defaultValues: FormData) {
  const removedAccounts = defaultValues.accounts.filter((account) => {
    if (
      account.id !== null &&
      !value.accounts.some((act) => act.id === account.id)
    ) {
      return account;
    }
  });

  const updatedAccounts = value.accounts.reduce(
    (accum: Array<SnowflakeIntegrationAccountUpdate>, account) => {
      if (!removedAccounts.some((removed) => removed.id === account.id)) {
        const currentAccount: SnowflakeIntegrationAccount | null =
          defaultValues.accounts
            ? (defaultValues.accounts.find((act) => act.id === account.id) ??
              null)
            : null;

        const changes = getAccountChanges(currentAccount, account);
        if (changes) {
          accum.push({
            id: account.id ?? null,
            ...changes,
          });
        }
      }

      return accum;
    },
    []
  );

  const allAccounts = [
    ...updatedAccounts,
    ...removedAccounts.map((removed) => ({ id: removed.id })),
  ];

  return {
    orgName: value.orgName,
    name: value.name,
    ...(allAccounts.length > 0
      ? {
          accounts: allAccounts,
        }
      : undefined),
  };
}
