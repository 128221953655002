import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { DataSource, TimeGranularity } from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import useBuildDataQuery from "../../../api/analytics/utils/useDataQuery";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

export type Params = {
  dateRange: [string, string];
  filters?: QueryFilter[];
};

export type SparklineDatum = {
  cost: number;
  timestamp: string;
};

export default function useGetSparklineData(
  params: Params,
  options?: UseQueryOptions<SparklineDatum[], UError>
): UseQueryResult<SparklineDatum[], UError> {
  const client = useAnalyticsApiClient();

  const [tenantID, query] = useBuildDataQuery({
    preAggFilters: params.filters,
    dataSource: DataSource.BILLING,
    dateRange: params.dateRange,
    measures: ["cost"],
    granularity: TimeGranularity.HOUR,
  });

  return useQuery({
    queryKey: ["sparklineData", params],
    queryFn: async () => {
      const result = await client.loadData<SparklineDatum>(tenantID, query);

      return result.response;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
