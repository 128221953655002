import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import useGatekeeper from "@/hooks/useGatekeeper";
import ConfirmationModal from "@/ui-lib/components/ConfirmationModal";
import Dropdown from "@/ui-lib/components/Dropdown";
import IconExclamationDiamond from "@/ui-lib/icons/IconExclamationDiamond";
import { AlertType, postAlert } from "@/utils/alerts";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import {
  faChevronDown,
  faCloud,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import {
  AlibabaDataIntegrationEntity,
  AWSDataIntegrationEntity,
  AzureDataIntegrationEntity,
  GCPDataIntegrationEntity,
  OracleDataIntegrationEntity,
  PublicMongoDbDataIntegrationEntity,
  SnowflakeDataIntegrationEntity,
} from "@ternary/api-lib/core/types";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { isNil, keyBy } from "lodash";
import React, { useState } from "react";
import paths from "../../../constants/paths";
import { useNavigateWithSearchParams } from "../../../lib/react-router";
import copyText from "../copyText";
import useDeleteDataIntegration from "../hooks/useDeleteDataIntegration";
import useGetDataIntegrationsByTenantID from "../hooks/useGetDataIntegrationsByTenantID";
import useValidateDataIntegration from "../hooks/useValidateDataIntegration";
import useDeleteMongoDbIntegration from "../integrations/hooks/useDeleteMongoDbIntegration";
import AWSIntegrationCard from "./AWSIntegrationCard";
import AlibabaIntegrationCard from "./AlibabaIntegrationCard";
import AzureIntegrationCard from "./AzureIntegrationCard";
import GCPIntegrationCard from "./GCPIntegrationCard";
import MongoDbIntegrationCard from "./MongoDbIntegrationCard";
import OracleIntegrationCard from "./OracleIntegrationCard";
import { SnowflakeIntegrationCard } from "./SnowflakeIntegrationCard";
import ValidationErrorModal from "./ValidationErrorModal";

const MODAL_DELETE = "DELETE";
const MODAL_VALIDATIONS = "VALIDATIONS";

type Interaction =
  | AlibabaIntegrationCard.Interaction
  | AWSIntegrationCard.Interaction
  | AzureIntegrationCard.Interaction
  | GCPIntegrationCard.Interaction
  | MongoDbIntegrationCard.Interaction
  | OracleIntegrationCard.Interaction
  | SnowflakeIntegrationCard.Interaction
  | ValidationErrorModal.Interaction;

interface State {
  modalKey: string;
  selectedIntegrationID: string | null;
}

const initialState: State = {
  modalKey: "",
  selectedIntegrationID: null,
};

export default function DataIntegrationManagementContainer(): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const navigate = useNavigateWithSearchParams();
  const theme = useTheme();

  //
  // State
  //

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const {
    data: _integrations = [],
    isLoading: isLoadingIntegrations,
    refetch: refetchIntegrations,
  } = useGetDataIntegrationsByTenantID(authenticatedUser.tenant.id, {
    enabled: gatekeeper.canListDataIntegrations,
    meta: { errorMessage: copyText.errorLoadingDataIntegrationsMessage },
  });

  //
  // Mutations
  //

  const {
    isPending: isDeletingDataIntegration,
    mutate: deleteDataIntegration,
  } = useDeleteDataIntegration({
    onError: () => {
      mergeState({ modalKey: "" });
      postAlert({
        message: copyText.errorDeletingDataIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      mergeState({ modalKey: "", selectedIntegrationID: null });

      refetchIntegrations();

      postAlert({
        message: copyText.successDeletingDataIntegrationMessage,
        type: AlertType.SUCCESS,
      });
    },
  });
  const {
    isPending: isDeletingMongoDbIntegration,
    mutate: deleteMongoDbIntegration,
  } = useDeleteMongoDbIntegration({
    onError: () => {
      mergeState({ modalKey: "" });
      postAlert({
        message: copyText.errorDeletingDataIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      mergeState({ modalKey: "", selectedIntegrationID: null });

      refetchIntegrations();

      postAlert({
        message: copyText.successDeletingDataIntegrationMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  const {
    isPending: isValidatingIntegration,
    mutate: validateDataIntegration,
  } = useValidateDataIntegration({
    onError: () => {
      postAlert({
        message: copyText.errorValidatingDataIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSettled: () => {
      refetchIntegrations();
    },
  });

  //
  // Computed Values
  //

  const integrationsKeyedByID = keyBy(_integrations, "id");

  const selectedIntegration = state.selectedIntegrationID
    ? integrationsKeyedByID[state.selectedIntegrationID]
    : null;

  //
  // Interaction Handlers
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case AlibabaIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          selectedIntegrationID: interaction.integrationID,
          modalKey: MODAL_DELETE,
        });
        return;
      }
      case AlibabaIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED: {
        const integration = integrationsKeyedByID[interaction.integrationID];

        if (!integration) return;

        const path = paths._adminIntegrationsAlibabaUpdate.replace(
          ":integrationID",
          integration.id
        );

        // Flatten the shape of integration.
        const { config, ...restIntegration } = integration;

        navigate(path, {
          state: { integration: { ...restIntegration, ...config } },
        });
        return;
      }
      case AlibabaIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED: {
        validateDataIntegration({ integrationID: interaction.integrationID });
        return;
      }
      case AWSIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED: {
        validateDataIntegration({ integrationID: interaction.integrationID });
        return;
      }
      case AWSIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED: {
        const integration = integrationsKeyedByID[interaction.integrationID];

        if (!integration) return;

        const path = paths._adminIntegrationsAwsUpdate.replace(
          ":integrationID",
          integration.id
        );

        // Flatten the shape of integration.
        const { config, ...restIntegration } = integration;

        navigate(path, {
          state: { integration: { ...restIntegration, ...config } },
        });
        return;
      }
      case AWSIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          selectedIntegrationID: interaction.integrationID,
          modalKey: MODAL_DELETE,
        });
        return;
      }
      case AzureIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED: {
        const integration = integrationsKeyedByID[interaction.integrationID];

        if (!integration) return;

        const path = paths._adminIntegrationsAzureUpdate.replace(
          ":integrationID",
          integration.id
        );

        // Flatten the shape of integration.
        const { config, ...restIntegration } = integration;

        navigate(path, {
          state: { integration: { ...restIntegration, ...config } },
        });
        return;
      }
      case AzureIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED: {
        validateDataIntegration({ integrationID: interaction.integrationID });
        return;
      }
      case AzureIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          selectedIntegrationID: interaction.integrationID,
          modalKey: MODAL_DELETE,
        });
        return;
      }
      case GCPIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          selectedIntegrationID: interaction.integrationID,
          modalKey: MODAL_DELETE,
        });
        return;
      }
      case GCPIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED: {
        const integration = integrationsKeyedByID[interaction.integrationID];

        if (!integration) return;

        const path = paths._adminIntegrationsGcpUpdate.replace(
          ":integrationID",
          integration.id
        );

        // Flatten the shape of integration.
        const { config, ...restIntegration } = integration;

        navigate(path, {
          state: { integration: { ...restIntegration, ...config } },
        });
        return;
      }
      case GCPIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED: {
        validateDataIntegration({ integrationID: interaction.integrationID });
        return;
      }
      case MongoDbIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          selectedIntegrationID: interaction.integrationID,
          modalKey: MODAL_DELETE,
        });
        return;
      }
      case MongoDbIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED: {
        const integration = integrationsKeyedByID[interaction.integrationID];

        if (!integration) return;

        const path = paths._adminIntegrationsMongoDbUpdate.replace(
          ":integrationID",
          integration.id
        );

        const { config, ...restIntegration } = integration;

        navigate(path, {
          state: { integration: { ...restIntegration, ...config } },
        });
        return;
      }
      case MongoDbIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED: {
        validateDataIntegration({ integrationID: interaction.integrationID });
        return;
      }
      case OracleIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          selectedIntegrationID: interaction.integrationID,
          modalKey: MODAL_DELETE,
        });
        return;
      }
      case OracleIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED: {
        const integration = integrationsKeyedByID[interaction.integrationID];

        if (!integration) return;

        const path = paths._adminIntegrationsOracleUpdate.replace(
          ":integrationID",
          integration.id
        );

        // Flatten the shape of integration.
        const { config, ...restIntegration } = integration;

        navigate(path, {
          state: { integration: { ...restIntegration, ...config } },
        });
        return;
      }
      case OracleIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED: {
        validateDataIntegration({ integrationID: interaction.integrationID });
        return;
      }
      case SnowflakeIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED: {
        const integration = integrationsKeyedByID[interaction.integrationID];

        if (!integration) return;

        const path = paths._adminIntegrationsSnowflakeUpdate.replace(
          ":integrationID",
          integration.id
        );

        // Flatten the shape of integration.
        const { config, ...restIntegration } = integration;

        navigate(path, {
          state: { integration: { ...restIntegration, ...config } },
        });
        return;
      }
      case SnowflakeIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED: {
        validateDataIntegration({ integrationID: interaction.integrationID });
        return;
      }
      case SnowflakeIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          selectedIntegrationID: interaction.integrationID,
          modalKey: MODAL_DELETE,
        });
        return;
      }
      case ValidationErrorModal.INTERACTION_CONFIGURE_BUTTON_CLICKED: {
        const integration = integrationsKeyedByID[interaction.integrationID];
        const path = getPathFromProviderType(interaction.providerType).replace(
          ":integrationID",
          integration.id
        );

        if (!integration || !path) return;

        // Flatten the shape of integration.
        const { config, ...restIntegration } = integration;

        navigate(path, {
          state: { integration: { ...restIntegration, ...config } },
        });
        return;
      }
    }
  }

  function getPathFromProviderType(providerType: CloudProviderType) {
    switch (providerType) {
      case CloudProviderType.GCP:
        return paths._adminIntegrationsGcpUpdate;
      case CloudProviderType.AWS:
        return paths._adminIntegrationsAwsUpdate;
      case CloudProviderType.AZURE:
        return paths._adminIntegrationsAzureUpdate;
      case CloudProviderType.MONGO_DB:
        return paths._adminIntegrationsMongoDbUpdate;
      case CloudProviderType.OCI:
        return paths._adminIntegrationsOracleUpdate;
      case CloudProviderType.SNOWFLAKE:
        return paths._adminIntegrationsSnowflakeUpdate;
      default:
        return "";
    }
  }

  //
  // Render
  //

  if (!gatekeeper.canListDataIntegrations) {
    return (
      <Flex alignItems="center" justifyContent="center" minHeight="50vh">
        <EmptyPlaceholder
          icon={faLock}
          loading={false}
          text={copyText.emptyPlaceholderInsufficientPermission}
        />
      </Flex>
    );
  }

  const integrations = _integrations.sort((a, b) =>
    a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
  );

  const integrationsWithErrors = integrations.filter((integration) =>
    integration.validations.find((validation) => !validation.success)
  );

  const createIntegrationOptions = [
    {
      label: copyText.createAlibabaIntegrationButtonLabel,
      onClick: () => navigate(paths._adminIntegrationsAlibabaCreate),
    },
    {
      label: copyText.createAWSIntegrationButtonLabel,
      onClick: () => navigate(paths._adminIntegrationsAwsCreate),
    },
    {
      label: copyText.createAzureIntegrationButtonLabel,
      onClick: () => navigate(paths._adminIntegrationsAzureCreate),
    },
    {
      label: copyText.createGCPIntegrationButtonLabel,
      onClick: () => navigate(paths._adminIntegrationsGcpCreate),
    },
    {
      label: copyText.createMongoDbIntegrationButtonLabel,
      onClick: () => navigate(paths._adminIntegrationsMongoDbCreate),
    },
    {
      label: copyText.createOracleIntegrationButtonLabel,
      onClick: () => navigate(paths._adminIntegrationsOracleCreate),
    },
    {
      label: copyText.createSnowflakeButtonlabel,
      onClick: () => navigate(paths._adminIntegrationsSnowflakeCreate),
    },
  ];

  function renderModal(): JSX.Element | null {
    switch (state.modalKey) {
      case MODAL_DELETE: {
        if (!selectedIntegration) return null;

        return (
          <ConfirmationModal
            isLoading={
              isDeletingDataIntegration || isDeletingMongoDbIntegration
            }
            title={copyText.deleteDataIntegrationConfirmationTitle}
            message={copyText.deleteDataIntegrationConfirmationMessage}
            variant="danger"
            onConfirm={() =>
              selectedIntegration.providerType === CloudProviderType.MONGO_DB &&
              isNil(selectedIntegration.mspChildSharedPayerConfiguration)
                ? deleteMongoDbIntegration({
                    integrationID: selectedIntegration.id,
                  })
                : deleteDataIntegration({
                    integrationID: selectedIntegration.id,
                    providerType: selectedIntegration.providerType,
                  })
            }
            onCancel={() =>
              mergeState({ selectedIntegrationID: null, modalKey: "" })
            }
          />
        );
      }
      case MODAL_VALIDATIONS: {
        return (
          <ValidationErrorModal
            integrations={integrationsWithErrors}
            onClose={() => mergeState({ modalKey: "" })}
            onInteraction={handleInteraction}
          />
        );
      }
    }

    return null;
  }

  return (
    <Flex direction="column">
      {renderModal()}
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginBottom={theme.space_md}
      >
        <Text
          fontSize={theme.h4_fontSize}
        >{`${copyText.cloudsLabel} (${integrations.length})`}</Text>
        <Flex justifyContent="space-between" marginRight={theme.space_lg}>
          {integrationsWithErrors.length > 0 && (
            <Button
              iconStart={<IconExclamationDiamond />}
              marginRight={theme.space_sm}
              secondary
              onClick={() => mergeState({ modalKey: MODAL_VALIDATIONS })}
            >
              {copyText.cloudValidationErrorButton}
            </Button>
          )}
          <Dropdown options={createIntegrationOptions} placement="bottom-end">
            <Button
              iconEnd={<Icon icon={faChevronDown} />}
              locked={!gatekeeper.canCreateDataIntegration}
              primary
            >
              {copyText.cloudDropDownLabel}
            </Button>
          </Dropdown>
        </Flex>
      </Flex>
      <Box marginTop={theme.space_md}>
        {integrations.length > 0 ? (
          <Flex flexWrap="wrap">
            {integrations.map((integration) => {
              if (integration.providerType === CloudProviderType.GCP) {
                return (
                  <GCPIntegrationCard
                    key={integration.id}
                    integration={integration as GCPDataIntegrationEntity}
                    isProcessing={isValidatingIntegration}
                    onInteraction={handleInteraction}
                  />
                );
              }

              if (integration.providerType === CloudProviderType.ALIBABA) {
                return (
                  <AlibabaIntegrationCard
                    key={integration.id}
                    integration={integration as AlibabaDataIntegrationEntity}
                    isProcessing={isValidatingIntegration}
                    onInteraction={handleInteraction}
                  />
                );
              }

              if (integration.providerType === CloudProviderType.AZURE) {
                return (
                  <AzureIntegrationCard
                    key={integration.id}
                    integration={integration as AzureDataIntegrationEntity}
                    isProcessing={isValidatingIntegration}
                    onInteraction={handleInteraction}
                  />
                );
              }

              if (integration.providerType === CloudProviderType.AWS) {
                return (
                  <AWSIntegrationCard
                    key={integration.id}
                    integration={integration as AWSDataIntegrationEntity}
                    isProcessing={isValidatingIntegration}
                    onInteraction={handleInteraction}
                  />
                );
              }

              if (integration.providerType === CloudProviderType.MONGO_DB) {
                return (
                  <MongoDbIntegrationCard
                    key={integration.id}
                    integration={
                      integration as PublicMongoDbDataIntegrationEntity
                    }
                    isProcessing={isValidatingIntegration}
                    onInteraction={handleInteraction}
                  />
                );
              }

              if (integration.providerType === CloudProviderType.OCI) {
                return (
                  <OracleIntegrationCard
                    key={integration.id}
                    integration={integration as OracleDataIntegrationEntity}
                    isProcessing={isValidatingIntegration}
                    onInteraction={handleInteraction}
                  />
                );
              }
              if (integration.providerType === CloudProviderType.SNOWFLAKE) {
                return (
                  <SnowflakeIntegrationCard
                    key={integration.id}
                    integration={integration as SnowflakeDataIntegrationEntity}
                    isProcessing={isValidatingIntegration}
                    onInteraction={handleInteraction}
                  />
                );
              }
            })}
          </Flex>
        ) : (
          <EmptyPlaceholder
            icon={faCloud}
            loading={isLoadingIntegrations}
            text={copyText.noDataIntegrationsMessage}
            skeletonVariant="cards"
          />
        )}
      </Box>
    </Flex>
  );
}
