import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../../api/core/CoreAPIClient";
import CoreAPIClientError from "../../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../../api/core/CoreAPIClientProvider";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import queryKeys from "./queryKeys";

type AwsIntegrationEntity = Awaited<
  ReturnType<CoreAPIClient["getAwsIntegrationByID"]>
>;

type Options = UseQueryOptions<AwsIntegrationEntity, CoreAPIClientError>;
type Result = UseQueryResult<AwsIntegrationEntity, CoreAPIClientError>;

export function useGetAwsIntegrationByID(
  integrationID: string,
  options?: Options
): Result {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: queryKeys.awsIntegration(integrationID),
    queryFn: () => client.getAwsIntegrationByID(integrationID),
    ...options,
  });
}
