import { Infer } from "@/api/analytics/utils/Cubestruct";
import { RawValue } from "@ternary/api-lib/analytics/types";
import { AlertRuleEntity } from "@ternary/api-lib/core/types";
import { CostAlertEntity } from "@ternary/api-lib/core/types/CostAlert";
import { CostAlertStruct, CostStruct } from "./hooks/schema";

export type CostAlertDatum = NotNullable<Infer<typeof CostAlertStruct>>;

export type CostAlert = CostAlertEntity & { alertRule?: AlertRuleEntity };

export type CostAlertDimension = { key: string; value: string | null };

export type CostAlertFilters = {
  eventType: string | null;
  sourceAlertRule: string | null;
  alertedAt: string | null;
};

export interface CostAlertSummary {
  negativeDelta: number;
  percentageAnomalous: number;
  positiveDelta: number;
  totalAlerts: number;
  totalDelta: number;
}

export const CostAlertLegacySourceRule = {
  LEGACY_BIGQUERY_ANOMALY_ML: "LEGACY_BIGQUERY_ANOMALY_ML",
  LEGACY_BILLING_ANOMALY_ML: "LEGACY_BILLING_ANOMALY_ML",
} as const;

export type CostAlertLegacySourceRule =
  (typeof CostAlertLegacySourceRule)[keyof typeof CostAlertLegacySourceRule];

export type CostDatum = NotNullable<Infer<typeof CostStruct>>;

type Unwrap<T extends Record<string, unknown>> = T extends () => void
  ? never
  : {
      [key in keyof T]: T[key];
    };

type NotNullable<T extends Record<string, RawValue>> = Unwrap<{
  [key in keyof T]: Exclude<T[key], null>;
}>;
