import { ServiceType } from "@ternary/api-lib/constants/enums";

export function getSortedUniqueString(
  data: Array<Record<string, unknown>>,
  key: string
) {
  return Array.from(
    data.reduce((acc, curr) => {
      const value = curr[key];

      if (typeof value === "string") {
        acc.add(value);
      }

      return acc;
    }, new Set<string>())
  ).sort();
}

export function createRange(data: Array<Record<string, unknown>>, key: string) {
  return data.reduce(
    (acc: { min: number; max: number }, rec) => {
      const value = rec[key];
      if (typeof value !== "number") {
        return acc;
      }

      if (!acc.min) {
        acc.min = value;
      } else if (value < acc.min) {
        acc.min = value;
      }

      if (!acc.max) {
        acc.max = value;
      } else if (value > acc.max) {
        acc.max = value;
      }

      return acc;
    },
    { min: 0, max: 0 }
  );
}

export function isServiceType(value: unknown): value is ServiceType {
  return typeof value === "string" && ServiceType[value] !== undefined;
}
