import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from "@tanstack/react-query";
import { UpdateCostAlertParams } from "../../../api/core/types";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  UpdateCostAlertParams
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  UpdateCostAlertParams
>;

export default function useUpdateCostAlert(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ costAlertID, status }) =>
      client.updateCostAlert({ costAlertID, status }),
    ...options,
  });
}
