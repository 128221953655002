import Button from "@ternary/api-lib/ui-lib/components/Button";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { MouseEvent, ReactNode } from "react";
import copyText from "../copyText";
import LoadingSpinner from "./LoadingSpinner";
import Modal from "./Modal";

type Variant = "danger" | "success";

interface Props {
  closeOnClickOutside?: boolean;
  isLoading?: boolean;
  message: ReactNode | string;
  title: string;
  variant?: Variant;
  onCancel: () => void;
  onConfirm: (e?: MouseEvent) => void;
}

export default function ConfirmationModal(props: Props): JSX.Element {
  return (
    <Modal
      closeOnClickOutside={props.closeOnClickOutside}
      isOpen={true}
      showCloseButton
      width={400}
      onClose={props.onCancel}
    >
      <Modal.Header>
        <Text appearance="h4">{props.title}</Text>
      </Modal.Header>
      <Modal.Body>
        {typeof props.message === "string" ? (
          <Text whiteSpace="pre-wrap">{props.message}</Text>
        ) : (
          props.message
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={props.isLoading}
          secondary
          width={70}
          onClick={props.onCancel}
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={props.isLoading}
          primary={!props.variant}
          variant={props.variant}
          width={70}
          onClick={props.onConfirm}
        >
          {props.isLoading ? <LoadingSpinner /> : copyText.confirmButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
