import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import { formatCurrency } from "@ternary/api-lib/ui-lib/utils/formatNumber";
import React, { useMemo } from "react";
import copyText from "../copyText";

type TableData = {
  billingAccountId?: string;
  billedCost: number;
  listCost: number;
  provider?: string;
  region?: string;
  serviceName?: string;
  tenantId: string;
  tenantName: string;
  userCost: number;
};

const columnHelper = createColumnHelper<TableData>();

interface Props {
  data: TableData[];
  dimensions: string[];
  isLoading: boolean;
}

export function MspTenantTable(props: Props) {
  const columns = useMemo(() => {
    const dimensionColumns = props.dimensions.map((dimension) =>
      columnHelper.accessor(dimension as keyof TableData, {
        header: copyText[`dimensionLabel_${dimension}`],
        cell: (cell) => cell.getValue() ?? "null",
        size: 200,
      })
    );

    return [
      columnHelper.accessor("tenantName", {
        header: copyText.tableHeaderName,
        size: 150,
      }),
      ...dimensionColumns,
      columnHelper.accessor("userCost", {
        header: copyText.tableHeaderUserCost,
        cell: (cell) => formatCurrency({ number: cell.getValue() }),
        size: 100,
      }),
      columnHelper.accessor("billedCost", {
        header: copyText.tableHeaderBilledCost,
        cell: (cell) => formatCurrency({ number: cell.getValue() }),
        size: 100,
      }),
    ];
  }, [props.dimensions]);

  return (
    <Table
      columns={columns}
      data={props.data}
      initialState={{ sorting: [{ id: "billedCost", desc: true }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}
