import { FormData } from "./types";

export const defaultValues: FormData = {
  name: "",
  region: "",
  storageBucket: "",
  storageNamespace: "",
  tenancyOCID: "",
  userOCID: "",
};

export const OCID_REGEX = /([0-9a-zA-Z-_]+[.:])([0-9a-zA-Z-_])/;
export const OCID_BUCKET_REGEX = /[a-zA-Z0-9-_.]+/;
export const OCID_NAMESPACE_REGEX = /[a-z0-9]+/;

export const ociRegions = [
  "ap-sydney-1",
  "ap-melbourne-1",
  "sa-saopaulo-1",
  "sa-vinhedo-1",
  "ca-montreal-1",
  "ca-toronto-1",
  "sa-santiago-1",
  "eu-paris-1",
  "eu-marseille-1",
  "eu-frankfurt-1",
  "ap-hyderabad-1",
  "ap-mumbai-1",
  "il-jerusalem-1",
  "eu-milan-1",
  "ap-osaka-1",
  "ap-tokyo-1",
  "mx-queretaro-1",
  "eu-amsterdam-1",
  "me-jeddah-1",
  "eu-jovanovac-1",
  "ap-singapore-1",
  "af-johannesburg-1",
  "ap-seoul-1",
  "ap-chuncheon-1",
  "eu-madrid-1",
  "eu-stockholm-1",
  "eu-zurich-1",
  "me-abudhabi-1",
  "me-dubai-1",
  "uk-london-1",
  "uk-cardiff-1",
  "us-ashburn-1",
  "us-chicago-1",
  "us-phoenix-1",
  "us-sanjose-1",
];
