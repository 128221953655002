import { DownloadAzureFormField } from "@/features/admin/components/DownloadAzureFormField";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import Select from "@/ui-lib/components/Select";
import { useTheme } from "@emotion/react";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import React from "react";
import { isUUID } from "validator";
import { FormField } from "../../../../../ui-lib/components/Form";
import TextInput from "../../../../../ui-lib/components/TextInput";
import copyText from "../../copyText";
import { AZURE_INTEGRATION_TYPES } from "./constants";
import { FormProps } from "./types";

const validators = {
  name: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
  appID: ({ value }: { value: string }) => {
    if (value.length === 0) return copyText.errorInputFieldRequired;

    return !isUUID(value) ? copyText.errorInputFieldInvalidID : undefined;
  },
  directoryID: ({ value }: { value: string }) => {
    if (value.length === 0) return copyText.errorInputFieldRequired;

    return !isUUID(value) ? copyText.errorInputFieldInvalidID : undefined;
  },
  type: ({ value }: { value: string }) => {
    if (value.length === 0) return copyText.errorInputFieldRequired;

    return !AZURE_INTEGRATION_TYPES.find((e) => e.value === value)
      ? copyText.errorInputFieldInvalidID
      : undefined;
  },
};

export function AzureIntegrationFormGeneral(
  props: FormProps & { showCertificate: boolean }
) {
  const theme = useTheme();
  const user = useAuthenticatedUser();

  const { form } = props;

  return (
    <Box padding={theme.space_xxs}>
      <form.Field
        name="name"
        validators={{ onChange: validators.name, onMount: validators.name }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              input={TextInput}
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              label={copyText.azureFormGeneralNameInputLabel}
              required
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <form.Field
        name="appID"
        validators={{
          onChange: validators.appID,
          onMount: validators.appID,
        }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              input={TextInput}
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              label={copyText.azureFormGeneralAppIDInputLabel}
              required
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <form.Field
        name="directoryID"
        validators={{
          onChange: validators.directoryID,
          onMount: validators.directoryID,
        }}
        children={(field) => {
          const shouldShowError =
            field.state.value.length > 0 &&
            field.state.meta.isTouched &&
            field.state.meta.errors.length > 0;

          return (
            <FormField
              input={TextInput}
              label={copyText.azureFormGeneralDirectoryIDInputLabel}
              required
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <form.Field
        name="type"
        validators={{
          onChange: validators.type,
          onMount: validators.type,
        }}
        children={(field) => {
          return (
            <FormField label={copyText.azureFormGeneralTypeInputLabel}>
              <Select
                isSearchable
                options={AZURE_INTEGRATION_TYPES}
                value={AZURE_INTEGRATION_TYPES.find(
                  (option) => option.value === field.state.value
                )}
                onChange={(option) =>
                  option && field.handleChange(option.value)
                }
              />
            </FormField>
          );
        }}
      />
      {props.showCertificate && (
        <FormField label={copyText.azureFormGeneralCertificateLabel}>
          <Box marginBottom={theme.space_lg}>
            <DownloadAzureFormField
              text={copyText.azureFormGeneralCertificateAction}
              user={user}
            />
          </Box>
        </FormField>
      )}
    </Box>
  );
}
