import { useTheme } from "@emotion/react";
import {
  CostAlertEventType,
  DataSource,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import { LabelMapsEntity } from "@ternary/api-lib/core/types";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { formatCurrency } from "@ternary/api-lib/ui-lib/utils/formatNumber";
import React from "react";
import {
  AnomalyDetection,
  Forecasting,
  Threshold,
} from "../../../api/core/types";
import copyText from "../copyText";
import { CostAlertDimension } from "../types";
import { getStringifiedDelta, getStringifiedRange } from "../utils";

type CostAlert = {
  createdAt: string;
  dimensions: CostAlertDimension[];
  eventTime: string;
  eventType: CostAlertEventType;
  eventValue: number;
  expectedValue: { lowerBound: number; upperBound: number } | null;
  sourceAlertRule: {
    condition: AnomalyDetection | Forecasting | Threshold;
    dataSource: DataSource;
    timeGranularity: TimeGranularity;
  };
};

interface Props {
  alert: CostAlert;
  labelMaps?: LabelMapsEntity;
}

export function AlertDetailTable(props: Props) {
  const theme = useTheme();

  const alertTime = `${new Date(props.alert.createdAt).toLocaleDateString(
    undefined,
    {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }
  )}`;

  const eventTime = `${new Date(props.alert.eventTime).toLocaleDateString(
    undefined,
    {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }
  )} (${new Date(props.alert.eventTime).toLocaleDateString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  })}  ${copyText.utcTimeLabel})`;

  const labelMap = props.labelMaps?.BILLING ?? {};

  const reversedLabelMap = Object.entries(labelMap).reduce(
    (accum: { [key: string]: string }, [key, value]) => ({
      ...accum,
      [String(value)]: key,
    }),
    {}
  );

  return (
    <Box marginTop={theme.space_lg}>
      <table>
        <tbody>
          <tr>
            <td>
              <Text bold marginRight={theme.space_xl}>
                {copyText.alertDetailModalFirstDetected}
              </Text>
            </td>
            <td>
              <Text>{alertTime}</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text bold marginRight={theme.space_xl}>
                {copyText.alertDetailModalEventTime}
              </Text>
            </td>
            <td>
              <Text>{eventTime}</Text>
            </td>
          </tr>
          {props.alert.dimensions.length > 0
            ? props.alert.dimensions.map((dimension, index) => {
                return (
                  <tr key={dimension.key + dimension.value + index + "f"}>
                    <td>
                      <Text marginRight={theme.space_xl} bold>
                        {reversedLabelMap[dimension.key] ?? dimension.key}
                      </Text>
                    </td>
                    <td>
                      <Text>{dimension.value}</Text>
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      <Flex justifyContent="space-between" marginTop={theme.space_lg}>
        <Box>
          <Text bold marginTop={theme.space_sm}>
            {copyText.eventStreamStringifiedAlertActualValue}
          </Text>
          <Text>
            {formatCurrency({
              number: props.alert.eventValue,
            })}
          </Text>
        </Box>
        <Box>
          <Text bold marginTop={theme.space_sm}>
            {props.alert.eventType === CostAlertEventType.ANOMALY_DETECTED
              ? copyText.eventStreamStringifiedAlertExpectedValue
              : copyText.eventStreamStringifiedAlertThreshold}
          </Text>
          <Text>
            {getStringifiedRange(
              props.alert.expectedValue
                ? props.alert.expectedValue.lowerBound
                : 0,
              props.alert.expectedValue
                ? props.alert.expectedValue.upperBound
                : 0
            )}
          </Text>
        </Box>
        <Box>
          <Text bold marginTop={theme.space_sm}>
            {copyText.eventStreamAlertDelta}
          </Text>
          <Text color={theme.feedback_negative}>
            {getStringifiedDelta(
              props.alert.eventValue,
              props.alert.expectedValue
                ? props.alert.expectedValue
                : { upperBound: 0, lowerBound: 0 }
            )}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
}

export default AlertDetailTable;
