import Modal from "@/ui-lib/components/Modal";
import { useTheme } from "@emotion/react";
import {
  faArrowUpRightFromSquare,
  faList,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  CostAlertEventType,
  DataSource,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import { LabelMapsEntity } from "@ternary/api-lib/core/types";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import {
  AnomalyDetection,
  Forecasting,
  QueryFilter,
  Threshold,
} from "../../../api/core/types";
import copyText from "../copyText";
import { CostAlertDimension } from "../types";
import { alertQueryFilter, getAnomalyDateRange } from "../utils";
import AlertDetailTable from "./AlertDetailTable";
import AnomalyChart, { AnomalyChartData } from "./AnomalyChart";

export const SIDE_DRAWER_WIDTH = "55rem";

type CostAlert = {
  createdAt: string;
  dimensions: CostAlertDimension[];
  eventTime: string;
  eventType: CostAlertEventType;
  eventValue: number;
  expectedValue: { lowerBound: number; upperBound: number } | null;
  id: string;
  sourceAlertRule: {
    condition: AnomalyDetection | Forecasting | Threshold;
    dataSource: DataSource;
    timeGranularity: TimeGranularity;
    dimensions: string[];
    filters: {
      name: string;
      operator: Operator;
      values: string[] | null;
    }[];
    measure: string;
  };
};

interface Props {
  costData: AnomalyChartData[];
  alert: CostAlert;
  isLoading: boolean;
  isOpen: boolean;
  labelMaps?: LabelMapsEntity;
  onClose: () => void;
  onInteraction: (interaction: LegacyAlertDetailModal.Interaction) => void;
}

export function LegacyAlertDetailModal(props: Props) {
  const theme = useTheme();

  return (
    <Modal isOpen={props.isOpen} showCloseButton onClose={props.onClose}>
      <Modal.Header>
        <Flex justifyContent="space-between" width="100%">
          <Text appearance="h4">
            {props.alert.eventType === CostAlertEventType.ANOMALY_DETECTED
              ? copyText.alertDetailModalTitleAnomalousSpend
              : copyText.alertDetailModalTitleThresholdBreached}
          </Text>
          <Flex justifyContent="flex-end">
            <Button
              iconStart={<Icon icon={faArrowUpRightFromSquare} />}
              marginRight={theme.space_xs}
              secondary
              size="small"
              onClick={() => {
                const dateRange = getAnomalyDateRange(
                  props.alert.eventTime,
                  props.alert.sourceAlertRule.timeGranularity
                );

                props.onInteraction({
                  type: LegacyAlertDetailModal.INTERACTION_INVESTIGATE_CLICKED,
                  dimensions: props.alert.sourceAlertRule.dimensions,
                  endDate: dateRange[1].toISOString(),
                  filters: alertQueryFilter(
                    props.alert.dimensions,
                    props.labelMaps
                  ),
                  measures: [props.alert.sourceAlertRule.measure],
                  startDate: dateRange[0].toISOString(),
                });
              }}
            >
              {copyText.goToReportButtonLabel}
            </Button>
            <Button
              iconStart={<Icon icon={faPlus} />}
              marginRight={theme.space_xs}
              secondary
              size="small"
              onClick={() => {
                props.onInteraction({
                  type: LegacyAlertDetailModal.INTERACTION_CREATE_CASE_CLICKED,
                  costAlertID: props.alert.id,
                  costAlertEventType: props.alert.eventType,
                });
              }}
            >
              {copyText.createCaseButtonLabel}
            </Button>
          </Flex>
        </Flex>
      </Modal.Header>
      <Box minWidth={`calc(${SIDE_DRAWER_WIDTH} - ${theme.space_jumbo})`}>
        <Box height={200}>
          {props.isLoading ? (
            <EmptyPlaceholder
              height="100%"
              icon={faList}
              loading={props.isLoading}
              skeletonVariant="cartesian"
            />
          ) : (
            <AnomalyChart
              data={props.costData}
              eventTime={props.alert.eventTime}
              granularity={props.alert.sourceAlertRule.timeGranularity}
              lowerBound={
                props.alert.expectedValue
                  ? props.alert.expectedValue.lowerBound
                  : null
              }
              upperBound={
                props.alert.expectedValue
                  ? props.alert.expectedValue.upperBound
                  : null
              }
              value={props.alert.eventValue}
            />
          )}
        </Box>
        <AlertDetailTable alert={props.alert} labelMaps={props.labelMaps} />
      </Box>
    </Modal>
  );
}

LegacyAlertDetailModal.INTERACTION_CREATE_CASE_CLICKED =
  `AlertDetailModal.INTERACTION_CREATE_CASE_CLICKED` as const;
LegacyAlertDetailModal.INTERACTION_INVESTIGATE_CLICKED =
  `AlertDetailModal.INTERACTION_INVESTIGATE_CLICKED` as const;

interface InteractionCreateCaseClicked {
  type: typeof LegacyAlertDetailModal.INTERACTION_CREATE_CASE_CLICKED;
  costAlertID: string;
  costAlertEventType: CostAlertEventType;
}
interface InteractionInvestigateClicked {
  type: typeof LegacyAlertDetailModal.INTERACTION_INVESTIGATE_CLICKED;
  dimensions: string[];
  endDate: string;
  filters: QueryFilter[];
  measures: string[];
  startDate: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace LegacyAlertDetailModal {
  export type Interaction =
    | InteractionCreateCaseClicked
    | InteractionInvestigateClicked;
}

export default LegacyAlertDetailModal;
