import styled from "@emotion/styled";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  ButtonHTMLAttributes,
  ComponentPropsWithoutRef,
  DetailedHTMLProps,
  ElementType,
} from "react";
import { Theme } from "../theme/default";

/* prettier-ignore */
const buttonTheme = (baseTheme: Theme) => ({
  Button_backgroundColor_dangerPrimary_hover: baseTheme.feedback_negative_outline,
  Button_backgroundColor_dangerPrimary: baseTheme.feedback_negative,
  Button_backgroundColor_disabled: baseTheme.secondary_color_background,
  Button_backgroundColor_hover: baseTheme.secondary_color_background_hover,
  Button_backgroundColor_primary_hover: baseTheme.primary_color_hover,
  Button_backgroundColor_primary: baseTheme.primary_color_background,
  Button_backgroundColor_secondary_hover: baseTheme.secondary_color_background_hover,
  Button_backgroundColor_secondary: baseTheme.secondary_color_background,
  Button_backgroundColor_successPrimary_hover: baseTheme.feedback_positive_outline,
  Button_backgroundColor_successPrimary: baseTheme.feedback_positive,
  Button_backgroundColor_warnPrimary_hover: baseTheme.feedback_warn_outline,
  Button_backgroundColor_warnPrimary: baseTheme.feedback_warn,
  Button_backgroundColor: baseTheme.panel_backgroundColor,
  Button_boxShadow_dangerPrimary_focus: `0 0 0 1px ${baseTheme.panel_backgroundColor}, 0 0 0 2px ${baseTheme.feedback_negative_outline}`,
  Button_boxShadow_primary_focus: `0 0 0 1px ${baseTheme.panel_backgroundColor}, 0 0 0 2px ${baseTheme.primary_color_focus}`,
  Button_boxShadow_secondary_focus: `0 0 0 1px ${baseTheme.panel_backgroundColor}, 0 0 0 2px ${baseTheme.box_shadow}`,
  Button_boxShadow_successPrimary_focus: `0 0 0 1px ${baseTheme.panel_backgroundColor}, 0 0 0 2px ${baseTheme.feedback_positive_outline}`,
  Button_boxShadow_warnPrimary_focus: `0 0 0 1px ${baseTheme.panel_backgroundColor}, 0 0 0 2px ${baseTheme.feedback_warn_outline}`,
  Button_color_hover: baseTheme.text_color,
  Button_fontSize_jumbo: baseTheme.h3_fontSize,
  Button_fontSize_large: baseTheme.fontSize_base,
  Button_fontSize_medium: baseTheme.fontSize_ui,
  Button_fontSize_small: baseTheme.fontSize_ui,
  Button_fontSize_tiny: baseTheme.h6_fontSize,
  Button_iconColor_default: baseTheme.text_color_secondary,
  Button_iconSize_jumbo: "1.25rem",
  Button_iconSize_large: "1.25rem",
  Button_iconSize_medium: "1rem",
  Button_iconSize_small: "0.875rem",
  Button_iconSize_tiny: "0.875rem",
  Button_iconOnlySize_jumbo: "1.25rem",
  Button_iconOnlySize_large: "1.25rem",
  Button_iconOnlySize_medium: "1.25rem",
  Button_iconOnlySize_small: "1rem",
  Button_iconOnlySize_tiny: "0.75rem",
  Button_paddingIconOnly_jumbo: "1.25rem",
  Button_paddingIconOnly_large: "0.75rem",
  Button_paddingIconOnly_medium: "0.5rem",
  Button_paddingIconOnly_small: "0.375rem",
  Button_paddingIconOnly_tiny: "0.25rem",
  Button_letterSpacing_default: "0.21px",
  ...baseTheme,
});

enum SIZE {
  jumbo = "jumbo",
  large = "large",
  medium = "medium",
  small = "small",
  tiny = "tiny",
}

type Size = keyof typeof SIZE;

type Variant = "danger" | "success" | "warn";

interface StyleProps {
  circular?: boolean;
  fullWidth?: boolean;
  margin?: number | string;
  marginBottom?: number | string;
  marginHorizontal?: number | string;
  marginLeft?: number | string;
  marginRight?: number | string;
  marginTop?: number | string;
  marginVertical?: number | string;
  primary?: boolean;
  secondary?: boolean;
  size?: Size;
  text?: boolean;
  variant?: Variant;
  width?: number | string;
}

interface ContentProps {
  size: Size;
}

const Content = styled("span")<ContentProps>(({ size, theme: baseTheme }) => {
  const theme = buttonTheme(baseTheme);

  return {
    fontSize: theme[`Button_fontSize_${size}`],
    whiteSpace: "nowrap",
    width: "100%",
  };
});

const Inner = styled("span")<{ align?: "left" | "right" }>(({ align }) => ({
  alignItems: "center",
  cursor: "inherit",
  display: "flex",
  height: "100%",
  justifyContent:
    align === "left" ? "flex-start" : align === "right" ? "flex-end" : "center",
  width: "100%",
}));

const Root = styled("button")<StyleProps>(({
  circular,
  disabled,
  fullWidth,
  margin,
  marginBottom,
  marginHorizontal,
  marginLeft,
  marginRight,
  marginTop,
  marginVertical,
  primary,
  secondary,
  size = "small",
  text,
  theme: baseTheme,
  variant,
  width,
}) => {
  //TODO: fix
  let theme = buttonTheme(baseTheme);

  if (variant) {
    //prettier-ignore
    theme = {
        ...theme,
        Button_backgroundColor_primary: theme[`Button_backgroundColor_${variant}Primary`],
        Button_backgroundColor_primary_hover: theme[`Button_backgroundColor_${variant}Primary_hover`],
        Button_boxShadow_primary_focus: theme[`Button_boxShadow_${variant}Primary_focus`]
      };
  }

  return {
    backgroundColor: (() => {
      if (variant || primary) return theme.Button_backgroundColor_primary;
      if (secondary) return theme.Button_backgroundColor_secondary;
      return theme.Button_backgroundColor;
    })(),
    border: "none",
    borderRadius: (() => {
      if (circular) return `${parseFloat(theme[`size_${size}`]) / 2}rem`;
      switch (size) {
        case SIZE.jumbo:
          return theme.borderRadius_4;
        case SIZE.large:
          return theme.borderRadius_3;
        case SIZE.medium:
        case SIZE.small:
          return theme.borderRadius_2;
        case SIZE.tiny:
          return theme.borderRadius_1;
      }
    })(),
    color: (() => {
      if (variant) return theme.feedback_contrast_text_color;
      if (primary || variant) return theme.text_color_inverse;
      return theme.default_button_text_color;
    })(),
    letterSpacing: theme.Button_letterSpacing_default,
    ...(disabled ? { cursor: "default", opacity: 0.5 } : { cursor: "pointer" }),
    height: theme[`size_${size}`],
    ...(margin && { margin }),
    ...(marginBottom && { marginBottom }),
    ...(marginHorizontal && {
      marginLeft: marginHorizontal,
      marginRight: marginHorizontal,
    }),
    ...(marginLeft && { marginLeft }),
    ...(marginRight && { marginRight }),
    ...(marginTop && { marginTop }),
    ...(marginVertical && {
      marginBottom: marginVertical,
      marginTop: marginVertical,
    }),
    minWidth: theme[`size_${size}`],
    outline: 0,
    padding: text
      ? `0 ${theme.space_sm}`
      : theme[`Button_paddingIconOnly_${size}`],
    transition: "none",
    verticalAlign: "middle",
    width: fullWidth ? "100%" : width,

    "&:focus": {
      boxShadow: primary
        ? theme.Button_boxShadow_primary_focus
        : theme.Button_boxShadow_secondary_focus,
      outline: 0,
    },

    "&:hover": {
      backgroundColor: (() => {
        if (disabled) return;
        if (primary || variant) {
          return theme.Button_backgroundColor_primary_hover;
        }
        if (secondary) return theme.Button_backgroundColor_secondary_hover;
        return theme.Button_backgroundColor_hover;
      })(),
    },

    '& [role="img"]': {
      cursor: disabled ? "default" : "pointer",
      height: theme[`Button_iconSize_${size}`],
      width: theme[`Button_iconSize_${size}`],
    },

    '& [role="img"]:first-of-type': {
      marginRight: theme.space_xs,
    },

    '& [role="img"]:last-child': {
      marginLeft: theme.space_xs,
      marginRight: 0,
    },

    // NOTE: explicit height/width here is a temporary hack until we have our own Icon component.
    '& [role="img"]:only-child': {
      height: theme[`Button_iconOnlySize_${size}`],
      margin: 0,
      width: theme[`Button_iconOnlySize_${size}`],
    },
  };
});

export type Props<T extends ElementType = "button"> = {
  align?: "left" | "right";
  as?: T;
  iconEnd?: JSX.Element;
  iconStart?: JSX.Element;
  locked?: boolean;
} & StyleProps &
  DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > &
  ComponentPropsWithoutRef<T>;

export default function Button<T extends ElementType = "button">(
  props: Props<T>
): JSX.Element {
  const {
    align,
    children,
    disabled,
    iconEnd,
    size = "medium",
    ...restProps
  } = props;

  let { iconStart } = props;

  const styleProps = {
    size,
    text: !!children,
    disabled: disabled || props.locked,
    ...restProps,
  };

  if (props.locked) {
    iconStart = <FontAwesomeIcon icon={faLock} />;
  }

  return (
    <Root {...styleProps}>
      <Inner align={align}>
        {iconStart && iconStart}
        {children && <Content size={size}>{children}</Content>}
        {iconEnd && iconEnd}
      </Inner>
    </Root>
  );
}
