import { FormField } from "@/ui-lib/components/Form";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import React from "react";
import copyText from "../../copyText";
import { FormProps } from "./types";

const validators = {
  name: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
  orgName: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
};

export function SnowflakeIntegrationFormGeneral(props: FormProps) {
  const theme = useTheme();
  const { form } = props;

  return (
    <Box padding={theme.space_xxs}>
      <form.Field
        name="name"
        validators={{ onChange: validators.name, onMount: validators.name }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              input={TextInput}
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              label={copyText.snowflakeFormGeneralCloudNameInputLabel}
              required
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <form.Field
        name="orgName"
        validators={{
          onChange: validators.orgName,
          onMount: validators.orgName,
        }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              input={TextInput}
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              label={copyText.snowflakeFormGeneralOrganizationInputLabel}
              required
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
              caption={copyText.snowflakeFormGeneralOrganizationInputCaption}
            />
          );
        }}
      />
    </Box>
  );
}
