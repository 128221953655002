import { Theme } from "@emotion/react";
import { isAfter, isBefore } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { groupBy, keyBy, uniq } from "lodash";
import prettyBytes from "pretty-bytes";
import { getMeasureUnit } from "../../analytics/utils";
import { UnitType } from "../../constants/analytics";
import { ChartType } from "../../constants/enums";
import { DatadogIntegrationEntity, ReportEntity } from "../../core/types";
import copyText from "../copyText";
import { formatDate } from "../utils/dates";
import {
  formatCurrency,
  formatCurrencyRounded,
  formatKilograms,
  formatNumber,
  formatNumberRounded,
  formatPercentage,
} from "../utils/formatNumber";
import {
  ChartDatum,
  Dimension,
  GroupingLabelFormatterParams,
  Measure,
  RawData,
  RawValue,
} from "./types";

export const COMPARISON_KEY = "Previous";
export const DEFAULT_X_AXIS_KEY = "timestamp";
export const FORECASTED_KEY = "Forecasted";
export const NOT_SHOWN_KEY = copyText.dimensionOtherNotShown;
export const PERCENT_DIFFERENCE_KEY = "percentDifference";
export const PERCENTAGE_Y_AXIS_ID = "PERCENTAGE_Y_AXIS_ID";
export const PREVIOUS_TIMESTAMP_KEY = "prevTimestamp";
export const RAW_DIFFERENCE_KEY = "rawDifference";

export const REPORT_PDF_CHART_HEIGHT = 300;
export const REPORT_PDF_CHART_WIDTH = 750;

export const SLICE_LABEL_KEY = "SLICE_LABEL_KEY";
export const SLICE_VALUE_KEY = "SLICE_VALUE_KEY";

const DASHED_MEASURES: Record<string, boolean> = {
  maxOfAverageCPUUtilization: true,
  maxOfAverageDiskUtilization: true,
  maxOfAverageGPUUtilization: true,
  maxOfAverageMemoryUtilization: true,
  minOfAverageCPUUtilization: true,
  minOfAverageDiskUtilization: true,
  minOfAverageGPUUtilization: true,
  minOfAverageMemoryUtilization: true,
  p50OfMaxCpuUtilization: true,
  p50OfMaxDiskUtilization: true,
  p50OfMaxGpuUtilization: true,
  p50OfMaxMemoryUtilization: true,
  p95OfMaxCpuUtilization: true,
  p95OfMaxDiskUtilization: true,
  p95OfMaxGpuUtilization: true,
  p95OfMaxMemoryUtilization: true,
  p99OfMaxCpuUtilization: true,
  p99OfMaxDiskUtilization: true,
  p99OfMaxGpuUtilization: true,
  p99OfMaxMemoryUtilization: true,
};

interface TopRowObject {
  measure: string;
  dimensions: {
    key: string;
    value: string;
  }[];
}

export default function getMergeState<State>(
  ss: React.Dispatch<React.SetStateAction<State>>
) {
  return function mergeState(ps: Partial<State>) {
    return ss((s: State) => ({ ...s, ...ps }));
  };
}

export function fillTimeSeriesChartDataGaps(data: ChartDatum[]) {
  const allMeasures: string[] = [];

  for (const datum of data) {
    for (const key in datum) {
      if (typeof datum[key] === "number") allMeasures.push(key);
    }
  }

  const uniqMeasures = uniq(allMeasures);

  const result = data.map((datum) => {
    for (const key of uniqMeasures) {
      if (typeof datum[key] !== "number") {
        datum[key] = 0;
      }
    }
    return datum;
  });

  return result;
}

export function formatMeasureValueWithUnit(options: {
  accounting?: boolean;
  currencyCode?: string;
  currencyMaxCharacters?: number;
  unit?: string;
  value: number;
}): string {
  const absValue = Math.abs(options.value);

  switch (options.unit) {
    case UnitType.BINARY_BYTES: {
      return prettyBytes(options.value, { binary: true });
    }
    case UnitType.BYTES: {
      return prettyBytes(options.value);
    }
    case UnitType.CURRENCY: {
      if (absValue > 1) {
        return formatCurrencyRounded({
          accounting: options.accounting,
          currencyCode: options.currencyCode,
          maxCharacters: options.currencyMaxCharacters,
          number: options.value,
        });
      }

      return formatCurrency({
        currencyCode: options.currencyCode,
        number: options.value,
      });
    }
    case UnitType.KILOGRAMS: {
      return formatKilograms(options.value, true);
    }
    case UnitType.PERCENTAGE: {
      if (
        options.value === 0 ||
        options.value === -Infinity ||
        options.value === Infinity
      ) {
        return formatPercentage(0);
      }

      return formatPercentage(options.value / 100);
    }
    default: {
      if (absValue > 5 && absValue < 999_000_000) {
        return formatNumberRounded(options.value);
      }

      if (absValue > 0.00001 && absValue <= 5) {
        return formatNumber(options.value, 3);
      }
      break;
    }
  }

  return options.value.toExponential(2);
}

export function formatTimestamp(timestamp: string, dateFormat: string): string {
  if (isNaN(Date.parse(timestamp))) return "";

  return formatDate(toZonedTime(new Date(timestamp), "UTC"), dateFormat);
}

export function getColorByReverseIndex(
  length: number,
  i: number,
  colors: string[]
): string {
  const index = (i - (length - 1)) * -1;

  const adjusted = index % colors.length;

  return colors[adjusted];
}

export function getFillByIndex(i: number, colors: string[]) {
  const length = colors.length;
  const adjusted = i % length;
  return colors[adjusted];
}

export interface StrictCustomColors {
  [key: string]: string;
}

export function getFillByGrouping(key: string, colors: StrictCustomColors) {
  return colors[key];
}

export function getGreenColors(isEnvironmental: boolean, themes: Theme) {
  const axisGreenColor = isEnvironmental
    ? themes.feedback_positive_background
    : themes.chart_axis_text;
  return { axis: axisGreenColor };
}

export function getGroupingFromKeyName(
  keyName: string,
  allMeasures: Measure[],
  allDimensions: Dimension[]
): GroupingLabelFormatterParams {
  if (keyName === NOT_SHOWN_KEY) {
    return {
      dimensions: [],
      isOtherGrouping: true,
      measure: NOT_SHOWN_KEY,
    };
  }

  if (allDimensions.length === 0) {
    return {
      dimensions: [],
      isOtherGrouping: false,
      measure: keyName,
    };
  }

  if (allDimensions.length === 1 && allMeasures.length === 1) {
    return {
      dimensions: [{ key: allDimensions[0].name, value: keyName }],
      isOtherGrouping: false,
      measure: allMeasures[0].name,
    };
  }

  if (allDimensions.length === 1 && allMeasures.length > 1) {
    return {
      dimensions: [{ key: allDimensions[0].name, value: keyName }],
      isOtherGrouping: false,
      measure: keyName.split(": ").slice(0, 1).join(""),
    };
  }

  const measure = keyName.split(": ").slice(0, 1).join("");
  const dimensionsString = keyName.split(": ").slice(1).join("");
  const dimensionsStrings = dimensionsString.split(" / ");
  const resultDimensions = dimensionsStrings.map((keyValueDimensionsString) => {
    const [key, value] = keyValueDimensionsString.split(":");
    return { key, value };
  });

  return {
    dimensions: resultDimensions,
    isOtherGrouping: false,
    measure,
  };
}

export function getKeyForMeasureAndDimensions(
  dimensions: { key: string; value: RawValue }[],
  measure: Measure,
  measureCount: number
): string {
  if (dimensions.length === 0) return measure.name;

  // In single dimension cases, we hide the name of the dimension for easier user reading
  if (dimensions.length === 1) {
    if (dimensions[0] && typeof dimensions[0].value === "string") {
      if (measureCount > 1) {
        return `${measure.name}: ${dimensions[0].value}`;
        // If there is ALSO only one measure, we go ahead and hide that too, for user reading
      } else {
        return dimensions[0].value;
      }
    }
    return "";
  }

  return `${measure.name}: ${dimensions
    .map((d) => `${d.key}:${d.value}`)
    .join(" / ")}`;
}

export function getPieChartDataFromRawData(params: {
  data: RawData[];
  dimensions: Dimension[];
  measures: Measure[];
}): [ChartDatum[], number] {
  let overallTotal = 0;

  const data: ChartDatum[] = params.data.reduce(
    (accum: ChartDatum[], datum) => {
      const dimensionKeysAndValues = params.dimensions.map((d) => ({
        key: d.name,
        value: datum[d.name] ?? "null",
      }));

      const output: ChartDatum[] = [];

      params.measures.forEach((measure) => {
        const value = datum[measure.name];
        if (typeof value !== "number") return;

        const key = getKeyForMeasureAndDimensions(
          dimensionKeysAndValues,
          measure,
          params.measures.length
        );

        overallTotal += value;

        output.push({ [SLICE_LABEL_KEY]: key, [SLICE_VALUE_KEY]: value });
      });

      return [...accum, ...output];
    },
    []
  );

  const sortedData: ChartDatum[] = data.sort((a, b) => {
    const aTotal = a[SLICE_VALUE_KEY];
    const bTotal = b[SLICE_VALUE_KEY];

    if (typeof aTotal !== "number" || typeof bTotal !== "number") return 0;

    if (aTotal < bTotal) {
      return -1;
    }
    if (bTotal > aTotal) {
      return 1;
    }
    return 0;
  });

  return [sortedData, overallTotal];
}

export function getTicks(
  data: ChartDatum[],
  xAxisKey: string
): (string | number)[] | undefined {
  const intervalLength = Math.floor(data.length / 4);

  if (intervalLength < 1) return undefined;

  const ticks: (string | number)[] = [];

  for (let i = 0; i < data.length; i += intervalLength) {
    const currentValue = data[i][xAxisKey];

    if (currentValue !== null) {
      ticks.push(currentValue);
    }
  }

  return ticks;
}

export function getTimeSeriesChartDataFromRawData(params: {
  chartType?: ChartType;
  data: RawData[];
  dimensions: Dimension[];
  excludedKeys: string[];
  isFiscalMode?: boolean;
  maximumGroupings: number;
  measures: Measure[];
  reverse?: boolean;
  xAxisKey: string;
}): [ChartDatum[], string[], { [xAxisValue: string]: ChartDatum }, number] {
  const allKeysMap: { [key: string]: number } = {};
  let highestMeasureValue = 0;
  const dataGroupedByXAxisKey = groupBy(params.data, params.xAxisKey);

  const data: ChartDatum[] = Object.keys(dataGroupedByXAxisKey).map(
    (xAxisValue) => {
      const entriesForDate = dataGroupedByXAxisKey[xAxisValue];

      const valuesForDate = entriesForDate.reduce(
        (accum: ChartDatum, entry) => {
          const dimensionKeysAndValues = params.dimensions.map((d) => ({
            key: d.name,
            value: entry[d.name] ?? "null",
          }));

          params.measures.forEach((measure) => {
            const key = getKeyForMeasureAndDimensions(
              dimensionKeysAndValues,
              measure,
              params.measures.length
            );

            const valueForMeasure = entry[measure.name];

            if (valueForMeasure === undefined || valueForMeasure === null) {
              return accum;
            }

            if (
              typeof valueForMeasure === "number" &&
              valueForMeasure > highestMeasureValue
            ) {
              highestMeasureValue = valueForMeasure;
            }

            if (allKeysMap[key] && typeof valueForMeasure === "number") {
              allKeysMap[key] += valueForMeasure;
            } else if (typeof valueForMeasure === "number") {
              allKeysMap[key] = valueForMeasure;
            }

            // early exit
            if (params.excludedKeys.includes(key)) {
              accum[key] = null;
              return accum;
            }

            // not exited
            if (accum[key]) {
              accum[key] = Number(accum[key]) + Number(valueForMeasure);
            } else if (typeof valueForMeasure === "number") {
              accum[key] = valueForMeasure;
            }
          });
          return accum;
        },
        {}
      );

      return { [params.xAxisKey]: xAxisValue, ...valuesForDate };
    }
  );

  let sortedData: ChartDatum[] = [];

  if (params.xAxisKey === DEFAULT_X_AXIS_KEY) {
    if (params.isFiscalMode) {
      sortedData = data;
    } else {
      sortedData = data.sort((a, b) => {
        if (
          typeof a.timestamp !== "string" ||
          typeof b.timestamp !== "string"
        ) {
          return 0;
        }
        if (isBefore(new Date(a.timestamp), new Date(b.timestamp))) {
          return -1;
        }
        if (isAfter(new Date(a.timestamp), new Date(b.timestamp))) {
          return 1;
        }
        return 0;
      });
    }
  } else if (params.xAxisKey === "invoiceMonth") {
    sortedData = data.sort((a, b) => {
      if (a > b) {
        return -1;
      }
      if (b < a) {
        return 1;
      }
      return 0;
    });
  } else {
    sortedData = data.sort((a, b) => {
      const aTotal = Object.values(a).reduce((accum: number, val) => {
        if (typeof val === "number") {
          accum += val;
        }
        return accum;
      }, 0);
      const bTotal = Object.values(b).reduce((accum: number, val) => {
        if (typeof val === "number") {
          accum += val;
        }
        return accum;
      }, 0);

      if (aTotal > bTotal) {
        return -1;
      }
      if (bTotal < aTotal) {
        return 1;
      }
      return 0;
    });
  }

  // sorted smallest to largest to respect recharts putting first at the bottom when stacking
  let allKeysReverseSorted = Object.keys(allKeysMap).sort((a, b) => {
    const aValue = allKeysMap[a];
    const bValue = allKeysMap[b];

    if (
      params.chartType === ChartType.STACKED_AREA ||
      params.chartType === ChartType.STACKED_BAR
    ) {
      if (a.includes(copyText.dimensionOtherNotShown)) {
        return -1;
      }

      if (b.includes(copyText.dimensionOtherNotShown)) {
        return 1;
      }
    }

    if (aValue < bValue) {
      return -1;
    }
    if (bValue < aValue) {
      return 1;
    }
    return 0;
  });

  if (params.reverse) {
    allKeysReverseSorted = allKeysReverseSorted.reverse();
  }

  if (
    params.maximumGroupings < Infinity &&
    allKeysReverseSorted.length > params.maximumGroupings
  ) {
    const removedCategories = allKeysReverseSorted.splice(
      0,
      allKeysReverseSorted.length - params.maximumGroupings
    );

    const removedCategoriesDict = removedCategories.reduce(
      (accum: { [category: string]: boolean }, category) => ({
        ...accum,
        [category]: true,
      }),
      {}
    );

    if (removedCategories.length > 0) {
      allKeysReverseSorted.unshift(NOT_SHOWN_KEY);
      sortedData = sortedData.map((datum) => {
        let totalForDate = 0;
        Object.keys(datum).forEach((key) => {
          if (removedCategoriesDict[key]) {
            if (!params.excludedKeys.includes(NOT_SHOWN_KEY)) {
              totalForDate += Number(datum[key]);
            }
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete datum[key];
          }
        });

        if (totalForDate !== 0) {
          datum[NOT_SHOWN_KEY] = totalForDate;
        }

        return datum;
      });
    }
  }

  const dataKeyedByXAxisKey = keyBy(sortedData, params.xAxisKey);
  return [
    sortedData,
    allKeysReverseSorted,
    dataKeyedByXAxisKey,
    highestMeasureValue,
  ];
}

export function getUniformUnitType(measures: Measure[]) {
  const measureUnitTypes = uniq(measures.map((measure) => measure.unit));
  const unitType =
    measureUnitTypes.length > 1 ? undefined : measureUnitTypes[0];
  return unitType;
}

export function getIsDashedMeasure(measure?: string | null) {
  if (typeof measure !== "string") return false;
  return DASHED_MEASURES[measure] || measure.includes(COMPARISON_KEY);
}

export function parseRowStrings(
  str: string,
  allDimensions: Dimension[],
  allMeasures: Measure[]
): TopRowObject {
  // netCost: projectId:ternary-prod / skuId:2AE5-7980-8EDC
  const splitArr = allMeasures.length === 0 ? [str] : str.split(": ");

  // 1 measure & 1 dimension - minimal annotation
  if (allDimensions.length === 1 && allMeasures.length === 1) {
    return {
      dimensions: [{ key: allDimensions[0].name, value: splitArr[0] }],
      measure: allMeasures[0].name,
    };
  }

  // multiple measures w/ single dimension - capture the measure but not the dimension annotations
  const measure = splitArr[0] ?? "";
  const splitDimensions = splitArr[1] ? splitArr[1].split(" / ") : [];

  if (allDimensions.length === 1 && allMeasures.length > 1) {
    const dimensions = splitDimensions.map((dimensionValue) => {
      return { key: allDimensions[0].name, value: dimensionValue };
    });

    return { dimensions, measure };
  }

  const dimensions = splitDimensions.map((dimensionPairString) => {
    const splitDimension = dimensionPairString.split(":");
    return { key: splitDimension[0], value: splitDimension[1] };
  });

  return { dimensions, measure };
}

export function getKPIMeasureFromReport(
  report: ReportEntity,
  integration?: DatadogIntegrationEntity
) {
  const selectedMetricName =
    integration?.metrics && report.metric
      ? integration.metrics[report.metric].name
      : undefined;

  const showUnitEconomics = !!report.formula || !!report.metric;

  const measures = getMeasuresWithUnits(report);

  const filteredMeasures: Measure[] = [
    ...measures.filter(
      (measure) =>
        !report.hiddenMeasures.some(
          (hiddenMeasure) => hiddenMeasure === measure.name
        )
    ),
    ...((report.metric ?? "").length > 0 &&
    selectedMetricName &&
    !report.isMetricHidden &&
    showUnitEconomics &&
    report.dimensions.length === 0
      ? [{ name: selectedMetricName }]
      : []),
    ...(!report.isFormulaHidden && report.formula
      ? [
          {
            name:
              report.formulaAlias && report.formulaAlias.length > 0
                ? report.formulaAlias
                : "Formula",
          },
        ]
      : []),
  ];

  const formulaAlias = report.formulaAlias ?? "Formula";

  const formulaMeasure = filteredMeasures.find(
    (measure) => measure.name === formulaAlias
  );

  if (formulaMeasure) {
    return [formulaMeasure];
  }

  const reportMetricName =
    report.metric && (integration?.metrics ?? {})[report.metric]?.name;

  const metricMeasure = filteredMeasures.find(
    (measure) => reportMetricName && measure.name === reportMetricName
  );

  if (metricMeasure) {
    return [metricMeasure];
  }

  return filteredMeasures;
}

function getMeasuresWithUnits(report: ReportEntity): Measure[] {
  return report.measures.reduce((accum: Measure[], measure) => {
    const currentMeasure = {
      name: measure,
      unit: getMeasureUnit(measure, report.dataSource),
    };

    if (report.compareDurationType) {
      const previousMeasure = {
        name: `${measure}${COMPARISON_KEY}`,
        unit: getMeasureUnit(measure, report.dataSource),
      };

      const deltas = [
        ...(report.measures.length === 1
          ? [
              {
                name: RAW_DIFFERENCE_KEY,
                unit: getMeasureUnit(measure, report.dataSource),
              },
            ]
          : []),
        ...(report.measures.length === 1 && report.chartType === ChartType.TABLE
          ? [
              {
                name: PERCENT_DIFFERENCE_KEY,
                unit: UnitType.STANDARD,
              },
            ]
          : []),
      ];

      return [...accum, currentMeasure, previousMeasure, ...deltas];
    }

    return [...accum, currentMeasure];
  }, []);
}

export function removeInvalidCharacters(str: string) {
  return str.replace(/[.:]/g, "");
}
